import Immutable from 'immutable';
import axios from 'axios';

import { CONTENT_API_ENDPOINT } from '../constants/endpoints';

export const originSearchPromise = (query, extended = false) => {
  if (query.length < 2) {
    return false;
  }

  const endpoint = extended ? `${CONTENT_API_ENDPOINT}/airports/origin_extended` : `${CONTENT_API_ENDPOINT}/airports/origin`;
  
  return axios
    .get(`${endpoint}?id=${query}`, {
      query,
    })
    .then(
      (response) => {
        // console.log('PROMISE', response);
        const resultsMap = Immutable.fromJS(response.data);
        return resultsMap.toJS().map((r) => Object.assign(r, { label: r.name, value: r.name, type: 'airport' }));
      },
      (error) => {
        console.log(error);
      }
    );
};
