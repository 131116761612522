import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

import * as StyledCarousel from '../Carousel.styled';

export const ItemCarousel = styled(StyledCarousel.Carousel)`
  justify-content: space-around;

  h1,
  h3 {
    text-shadow: 0 0 5px ${toRgba(colors.black, 0.5)};
  }

  .search-control.search-control-bar {
    text-align: left;
    width: calc(100% - 40px);
    max-width: 1400px;
    border: 1px solid ${toRgba(colors.white, 0.35)};
    background: ${toRgba(colors.white, 0.25)};
    color: ${colors.black};
    border-radius: 3px;
    .search-body {
      padding: 0 10px;
    }

    .Select-placeholder {
      margin-left: 0px;
    }
    @media (max-width: ${variables.screen.xs}) {
      display: none;
    }
  }
`;

export const CarouselInfo = styled.div`
  height: 100%;
  max-width: ${variables.siteWidthMax};
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  color: ${colors.white};
  text-shadow: 2px 2px 5px ${toRgba(colors.black, 0.25)};
  font-weight: 300;
  em {
    font-weight: 500;
  }
  z-index: 99;

  @media (max-width: ${variables.screen.xs}) {
    margin-left: 1rem;
    margin-bottom: 4rem;
  }
`;

export const ContentOverlay = styled(StyledCarousel.ContentOverlay)``;

export const BannerHeader = styled.div`
  font-size: 0.9rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: ${toRgba(colors.white, 0.95)};
  background: ${toRgba(colors.black, 0.35)};

  border-bottom: 1px solid ${toRgba(colors.white, 0.5)};
  z-index: 2;
  & > div.hotel-sub-search {
    background: ${toRgba(colors.black, 0.25)};
    border-bottom: 1px solid ${toRgba(colors.white, 0.5)};
  }
  & > div.hotel-sub-navigation {
    padding: 15px 0 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    @media (max-width: ${variables.screen.xs}) {
      display: block;
      padding: 10px;
    }
    a {
      color: inherit;
      font-weight: 500;
      text-shadow: 0 0 5px ${toRgba(colors.black, 0.25)};
      @media (max-width: ${variables.screen.xs}) {
        font-size: 16px;
      }
    }
    .hotel-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        font-weight: 700;
        margin: auto 20px;
      }
      @media (max-width: ${variables.screen.xs}) {
        display: none;
      }
    }
  }
`;

export const CarouselGradient = styled(StyledCarousel.CarouselGradient)``;
