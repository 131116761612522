import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <g id="icon-plane" >
        <path d="M23.619,17.115 L15,9.918 L15,2.939 C15,1.487 14,0 12.034,0 C10.068,0 9,1.487 9,2.939 L9,9.918 L0.381,17.115 C0.171,17.293 0,17.664 0,17.938 L0,19.647 C0,19.922 0.199,20.043 0.442,19.915 L10,14.938 L10,20.938 L7.394,23.048 C7.178,23.217 7,23.58 7,23.854 L7,24.609 C7,24.883 7.216,25.045 7.48,24.968 L11,23.938 C11.265,23.861 12.735,23.861 13,23.938 L16.519,24.968 C16.784,25.046 17,24.884 17,24.609 L17,23.854 C17,23.58 16.822,23.216 16.605,23.047 L14,20.938 L14,14.938 L23.557,19.905 C23.801,20.032 24,19.912 24,19.637 L24,17.939 C24,17.664 23.828,17.293 23.619,17.115 Z"></path>
      </g>
  </svg>

export default Svg;
