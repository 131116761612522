import { useSelector, useDispatch } from 'react-redux';
// import { whoIsMyDaddy } from 'lib/devTools';
import {
  identify,
  signIn,
  signUp,
  clearAuthErrors,
  requestPassword,
  signOut,
  authenticateFromResetToken,
} from 'modules/authentication/authentication';

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication);

  const isIdentified = !!auth?.get('uuid');
  const isLoggedIn = !!auth?.get('loggedIn');
  // TODO: This is a Hack to keep old, undetermined accounts from being mis-identified as requireing activation
  // in the future it should check against status == 'unconfired' or just auth.activated
  const isConfirmed = auth && auth.get('status') !== 'unconfirmed';
  const isActivated = !!(auth && auth.get('status') && isConfirmed);
  const canSetPassword = !!(auth && !isLoggedIn && !!auth.get('canSetPassword'));
  const loginRequired = !!(auth && auth.get('uuid') && !auth.get('loggedIn'));
  const passwordRequested = auth?.get('passwordRequested');
  const authErrors = auth?.get('authErrors');
  const isLoading = !!auth?.get('loading');
  const userId = auth?.get('id');
  const anonymousId = auth?.get('skylarkAnonymousId');
  const emailAddress = auth?.get('email');

  // const daddy = whoIsMyDaddy();
  // console.log('useAuth', daddy, { auth: auth.toJS() });

  return {
    auth,
    userId,
    anonymousId,
    emailAddress,
    identify: (formData, method) => dispatch(identify(formData, method)),
    signIn: formData => dispatch(signIn(formData)),
    signUp: formData => dispatch(signUp(formData)),
    clearAuthErrors: () => dispatch(clearAuthErrors()),
    requestPassword: formData => dispatch(requestPassword(formData)),
    signOut: () => dispatch(signOut()),
    authenticateFromResetToken: token => dispatch(authenticateFromResetToken(token)),
    isIdentified,
    isLoggedIn,
    loginRequired,
    isConfirmed,
    isActivated,
    canSetPassword,
    passwordRequested,
    authErrors,
    isLoading,
  };
};

export default useAuth;
