import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useIsMobile from 'lib/UserAgent';
import { DateRangePicker } from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import * as Styled from './DatePicker.styled';

const DatePicker = ({
  startDate,
  endDate,
  onDatesChange,
  focusedInput,
  onFocusChange,
  minDate,
  maxDate,
  displayFormat,
  startDatePlaceholderText,
  endDatePlaceholderText,
}) => {
  const isMobile = useIsMobile();

  const formatDate = date => (date ? moment(date) : null);

  const [_startDate, setStartDate] = useState(formatDate(startDate));
  const [_endDate, setEndDate] = useState(formatDate(endDate));
  const [_focusedInput, setFocusedInput] = useState(focusedInput);

  useEffect(() => {
    setStartDate(formatDate(startDate));
  }, [startDate]);

  useEffect(() => {
    setEndDate(formatDate(endDate));
  }, [endDate]);

  const _onDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    onDatesChange(start, end);
  };

  const _onFocusChange = value => {
    setFocusedInput(value);
    onFocusChange(value);
    document.activeElement.blur();
  };

  const isOpen = !!_focusedInput;

  return (
    <Styled.DatePickerWrapper isOpen={isOpen}>
      <DateRangePicker
        startDateId="departDate"
        startDate={_startDate}
        endDateId="endDate"
        endDate={_endDate}
        onDatesChange={({ startDate, endDate }) => _onDatesChange(startDate, endDate)}
        focusedInput={_focusedInput}
        // Below code ensures that clicking on input always assumes startDate - avoiding UX confusion
        // onFocusChange={input => _onFocusChange(_focusedInput ? input : 'startDate')}
        onFocusChange={input => _onFocusChange(input)}
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        displayFormat={displayFormat}
        showDefaultInputIcon={false}
        numberOfMonths={2}
        enableOutsideDays
        daySize={40}
        withFullScreenPortal={isMobile.phone}
        // disableScroll={isMobile.phone}
        orientation={isMobile.phone ? 'vertical' : undefined}
        isOutsideRange={date => date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day')}
        hideKeyboardShortcutsPanel
      />
    </Styled.DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  onDatesChange: PropTypes.func,
  focusedInput: PropTypes.string,
  onFocusChange: PropTypes.func,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  displayFormat: PropTypes.string,
};

DatePicker.defaultProps = {
  startDatePlaceholderText: 'Start',
  endDatePlaceholderText: 'End',
  displayFormat: 'ddd MMM D',
  minDate: moment(),
  maxDate: moment().add(330, 'days'),
  onFocusChange: () => {},
  onDatesChange: (s, e) => console.log('onDatesChange', s, e),
};

export default DatePicker;
