import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrency } from 'modules/currency';

import CurrencyComponent from './CurrencyComponent';

const Currency = ({ code, ...props }) => {
  const dispatch = useDispatch();

  const settings = useSelector(state => Immutable.fromJS(state.settings));
  const objCurrency = useSelector(state => Immutable.fromJS(state.currency));

  const currencyCode = code || settings.get('currencyCode');
  const currency = objCurrency.getIn(['currencies', currencyCode]);
  const isLoaded = currency && currency.get('isLoaded');
  const conversionRate = objCurrency.getIn(['currencies', currencyCode, 'conversion_rate']);
  const currencySymbol = objCurrency.getIn(['currencies', currencyCode, 'symbol']);

  useEffect(() => {
    if (currencyCode && currencyCode.length === 3 && !currency) {
      dispatch(getCurrency(currencyCode));
    }
  }, [currencyCode]);

  return (
    <CurrencyComponent
      {...props}
      currencyCode={currencyCode}
      conversionRate={conversionRate}
      symbol={currencySymbol}
      loading={!isLoaded}
    />
  );
};

Currency.propTypes = {
  code: PropTypes.string,
};

export default Currency;
