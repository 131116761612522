import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 21 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon-moon" >
      <path d="M20.6884229,19.857848 C18.5944229,22.184848 15.6934229,23.598848 12.3114229,23.707848 C5.72942287,23.921848 0.221422871,18.786848 0.00642287059,12.240848 C-0.207577129,5.69684804 4.95242287,0.21884804 11.5344229,0.00484803973 C11.9474229,-0.00915196027 12.3574229,0.00984803973 12.7624229,0.0278480397 C13.1564229,0.0458480397 13.2184229,0.24884804 12.9594229,0.41484804 C9.95142287,2.34484804 8.00142287,5.74084804 8.12642287,9.55584804 C8.31542287,15.327848 13.1724229,19.853848 18.9764229,19.665848 C19.4784229,19.648848 19.9744229,19.609848 20.4544229,19.512848 C20.9064229,19.420848 20.9694229,19.545848 20.6884229,19.857848 Z" id="Shape" />
    </g>
  </svg>
);

export default Svg;
