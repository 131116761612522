import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Styled from './FormFieldWrapper.styled';

const FormFieldLabel = ({ label, showError, errorMessage, children, htmlFor }) => {
  const firstChild = children[0] || children;
  const firstChildName = firstChild.props.name;
  return (
    <Styled.FormFieldLabel htmlFor={htmlFor || firstChildName}>
      {(label || errorMessage) && (
        <header>
          {!!label && <span>{label}</span>}
          {showError && <span className="form-field-error-message">{errorMessage}&nbsp;</span>}
        </header>
      )}
      <div className="form-field-content">{children}</div>
    </Styled.FormFieldLabel>
  );
};

FormFieldLabel.propTypes = {
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  showError: PropTypes.bool,
  children: PropTypes.node,
  htmlFor: PropTypes.string,
};

const FormFieldWrapper = ({ label, hint, error, errors, children, flex, errorPosition, labelPosition, htmlFor }) => {
  const fieldErrors = (errors || [error]).filter(x => x !== undefined);
  const errorMessages = fieldErrors.map(e => e.message).filter((v, i, a) => a.indexOf(v) === i);
  const errorMessage = label || errorPosition !== 'top' ? errorMessages.join(', ') : null;

  const baseClass = classNames('form-field', {
    'form-field-error': !!errorMessage,
    // 'form-field-padded': errorPosition === 'top',
  });
  const flexStyle = { flex };

  const labelError = (errorPosition === 'top' && errorMessage) || null;
  const footerError = errorPosition !== 'top' && errorMessage;
  return (
    <Styled.FormFieldWrapper className={baseClass} style={flexStyle} labelPosition={labelPosition}>
      <FormFieldLabel errorMessage={labelError} label={label} showError={errorPosition === 'top'} htmlFor={htmlFor}>
        {children}
      </FormFieldLabel>
      {footerError ? (
        <div className="form-field-error-message">{errorMessage}&nbsp;</div>
      ) : (
        <div className="form-field-hint-message">{hint}&nbsp;</div>
      )}
    </Styled.FormFieldWrapper>
  );
};

FormFieldWrapper.defaultProps = {
  flex: 1,
  labelPosition: 'top',
  errorPosition: 'top',
};

FormFieldWrapper.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.node,
  error: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Array),
  children: PropTypes.node,
  flex: PropTypes.number,
  labelPosition: PropTypes.string,
  errorPosition: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default FormFieldWrapper;
