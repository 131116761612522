import Immutable from 'immutable';
import axios from 'axios';

import { CONFIG_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const initialState = Immutable.fromJS({});

const SkylarkSherpaUrl = `${CONFIG_API_ENDPOINT}/sherpa/raw`;
// const SherpaCountryRestrictionsUrl = 'https://requirements-api.sandbox.joinsherpa.com/v2/countries';
// const SHERPA_API_KEY = 'AIzaSyD181LWkwSCLTB4NTqSGOq_UkkAso4_HEg';

// ACTION TYPES
const REQUEST = 'skylark/sherpaRestrictions/REQUEST';
const SUCCESS = 'skylark/sherpaRestrictions/SUCCESS';
const FAILURE = 'skylark/sherpaRestrictions/FAILURE';

export const fetchRestrictions = (originCode, destinationCode) => async dispatch => {
  const resourceUrl = SkylarkSherpaUrl;
  const key = `${originCode}|${destinationCode}`;

  const params = {
    origin: originCode,
    destination: destinationCode,
  };

  dispatch({
    type: REQUEST,
    meta: { key },
  });

  try {
    const results = await axios.get(resourceUrl, { params });
    dispatch({
      type: SUCCESS,
      meta: { key },
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      meta: { key },
      payload: respStatus,
    });
  }
};

// export const fetchRestrictions = (originCode, destinationCode) => async dispatch => {
//   const resourceUrl = `${SherpaCountryRestrictionsUrl}/${destinationCode}`;
//   const key = `${originCode}-${destinationCode}`;

//   const params = {
//     include: 'restriction,procedure',
//     'filter[restriction.originCountries]': originCode3,
//     'filter[procedure.originCountries]': originCode3,
//     language: 'en',
//     key: SHERPA_API_KEY,
//   };

//   dispatch({
//     type: REQUEST,
//     meta: { key },
//   });

//   try {
//     const results = await axios.get(resourceUrl, { params });
//     dispatch({
//       type: SUCCESS,
//       meta: { key },
//       payload: results.data,
//     });
//   } catch (e) {
//     const respStatus = e.response ? e.response.status : null;
//     dispatch({
//       type: FAILURE,
//       meta: { key },
//       payload: respStatus,
//     });
//   }
// };

// REDUCER HELPER METHODS
const requested = (state, action) =>
  state.setIn([action.meta.key, 'loading'], true).setIn([action.meta.key, 'loaded'], false);

const received = (state, action) =>
  state
    .setIn([action.meta.key, 'response'], Immutable.fromJS(action.payload))
    .setIn([action.meta.key, 'loading'], false)
    .setIn([action.meta.key, 'loaded'], true);

const failed = (state, action) =>
  state
    .setIn([action.meta.key, 'response'], Immutable.fromJS(action.payload))
    .setIn([action.meta.key, 'loading'], false)
    .setIn([action.meta.key, 'loaded'], false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    default:
      return state;
  }
}
