import Immutable from 'immutable';
import axios from 'axios';

import { AuthHeaders } from 'modules/authentication/authentication';
import { AUTH_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const initialState = Immutable.fromJS({});

// ACTION TYPES
const REQUEST = 'skylark/paymentMethods/REQUEST';
const SUCCESS = 'skylark/paymentMethods/SUCCESS';
const FAILURE = 'skylark/paymentMethods/FAILURE';

const UPSERT_REQUEST = 'skylark/paymentMethods/UPSERT_REQUEST';
const UPSERT_SUCCESS = 'skylark/paymentMethods/UPSERT_SUCCESS';
const UPSERT_FAILURE = 'skylark/paymentMethods/UPSERT_FAILURE';

const REMOVE_REQUEST = 'skylark/paymentMethods/REMOVE_REQUEST';
const REMOVE_SUCCESS = 'skylark/paymentMethods/REMOVE_SUCCESS';
const REMOVE_FAILURE = 'skylark/paymentMethods/REMOVE_FAILURE';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';

export const fetchPaymentMethods = (userId, fcnCallback) => async dispatch => {
  // console.log('requestHeaders', requestHeaders);
  const PaymentMethodsUrl = `${AUTH_API_ENDPOINT}/users/${userId}/payment_methods`;
  const resourceUrl = PaymentMethodsUrl;

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.get(resourceUrl, { headers: AuthHeaders() });
    if (fcnCallback) {
      fcnCallback(results);
    }
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      payload: respStatus,
    });
  }
};

export const createPaymentMethod = (paymentMethod, onSuccess, onFailure) => async dispatch => {
  // console.log('createPaymentMethod', paymentMethod && paymentMethod.toJS());
  const resourceUrl = `${AUTH_API_ENDPOINT}/payment_methods`;
  const cvc = paymentMethod.get('cvc');

  // console.log('createPaymentMethod > headers', { headers: AuthHeaders() });

  dispatch({
    type: UPSERT_REQUEST,
  });

  try {
    const results = await axios.post(resourceUrl, paymentMethod.toJS(), { headers: AuthHeaders() });
    // console.log('createPaymentMethod > results', results);
    const resultsWithCvc = Object.assign(results.data, { cvc });
    // console.log('createPaymentMethod > resultsWithCvc', resultsWithCvc);
    // TODO: maybe just allow the upsertion to return the collection
    dispatch(fetchPaymentMethods(results.data.user_id));
    if (onSuccess) {
      // console.log('createPaymentMethod > onSuccess', resultsWithCvc);
      onSuccess(resultsWithCvc);
    }
    dispatch({
      type: UPSERT_SUCCESS,
      payload: resultsWithCvc,
    });
    // results.then(value => console.log('results', value));
    return resultsWithCvc;
  } catch (e) {
    console.log('createPaymentMethod > onFailure', e);
    const respStatus = e.response ? e.response.status : null;
    if (onFailure) {
      onFailure(respStatus);
    }
    dispatch({
      type: UPSERT_FAILURE,
      payload: respStatus,
    });
  }
};

export const updatePaymentMethod = (paymentMethod, onSuccess, onFailure) => async dispatch => {
  const resourceUrl = `${AUTH_API_ENDPOINT}/payment_methods/${paymentMethod.get('id')}`;

  dispatch({
    type: UPSERT_REQUEST,
  });

  try {
    const results = await axios.put(resourceUrl, paymentMethod.toJS(), { headers: AuthHeaders() });
    // console.log('updatePaymentMethod > results', results);
    if (onSuccess) {
      onSuccess(results.data);
    }
    // TODO: maybe just allow the upsertion to return the collection
    dispatch(fetchPaymentMethods(results.data.user_id));
    dispatch({
      type: UPSERT_SUCCESS,
      payload: results.data,
    });
    return results.data;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    if (onFailure) {
      onFailure(respStatus);
    }
    dispatch({
      type: UPSERT_FAILURE,
      payload: respStatus,
    });
  }
};

export const removePaymentMethod = (paymentMethod, onSuccess, onFailure) => async dispatch => {
  // console.log('removePaymentMethod', paymentMethod && paymentMethod.toJS());
  const resourceUrl = `${AUTH_API_ENDPOINT}/payment_methods/${paymentMethod.get('id')}`;

  // console.log('removePaymentMethod > authToken', authToken);

  dispatch({
    type: REMOVE_REQUEST,
  });

  try {
    const results = await axios.delete(resourceUrl, { headers: AuthHeaders() });
    // console.log('removePaymentMethod > results', results);
    if (onSuccess) {
      onSuccess(results.data);
    }
    dispatch(fetchPaymentMethods(results.data.user_id));
    dispatch({
      type: REMOVE_SUCCESS,
      payload: results.data,
    });
    return results.data;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    if (onFailure) {
      onFailure(respStatus);
    }
    dispatch({
      type: REMOVE_FAILURE,
      payload: respStatus,
    });
  }
};

// REDUCER HELPER METHODS
const requested = (state, action) => state.set('loading', true).set('loaded', false);

const received = (state, action) =>
  state
    .set('items', Immutable.fromJS(action.payload.items))
    .set('loading', false)
    .set('loaded', true);

const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    case AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
