import Immutable from 'immutable';

import { currencyData } from 'constants/currency';

const SET_CURRENCY_CODE = 'skylark/settings/SET_CURRENCY_CODE';

const initialState = Immutable.fromJS({
  currencyCode: 'USD',
});

// ACTION CREATORS
export function setCurrencyCode(currencyCode) {
  return { type: SET_CURRENCY_CODE, currencyCode };
}

// REDUCER HELPER METHODS
const setCurrency = (state, currencyCode) => {
  if (currencyData.get(currencyCode)) {
    return state.set('currencyCode', currencyCode);
  }
  return state;
};

const reducer = (state = initialState, action = {}) => {
  // console.log('ACTION', action)
  // const currencyCode = action.meta ? action.meta.currencyCode : null;
  switch (action.type) {
    case SET_CURRENCY_CODE:
      return setCurrency(state, action.currencyCode);
    default:
      return state;
  }
};

export default reducer;
