import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyledButton from './styled';

const BaseButton = ({ className, type, children, onClick, disabled, ...props }) => {
  const baseClass = classNames('button-component', className);
  const isDisabled = disabled === true;
  return (
    // eslint-disable-next-line react/button-has-type
    <StyledButton {...props} type={type} disabled={isDisabled} onClick={onClick} className={baseClass}>
      {children}
    </StyledButton>
  );
};

BaseButton.defaultProps = {
  type: 'button',
  children: 'Button',
};

BaseButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export const LinkButton = ({ className, ...props }) => {
  const baseClass = classNames('link', className);
  return <BaseButton className={baseClass} {...props} />;
};

export const SubmitButton = ({ className, ...props }) => {
  const baseClass = classNames('button', className);
  return <BaseButton type="submit" className={baseClass} {...props} />;
};

// export default const MenuItem = ({ children }) => <li>{children}</li>;

export default class Button extends React.Component {
  static Link = LinkButton;

  static Submit = SubmitButton;

  render() {
    const { className, ...props } = this.props;
    const baseClass = classNames('button', className);
    return <BaseButton className={baseClass} {...props} />;
  }
}
