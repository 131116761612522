import moment from 'moment';

const decorateRatesWithCompareRate = (rates, compareRate) =>
  rates.map((rate) => {
    const comparePricing = compareRate && compareRate.get('pricing').set('productCode', compareRate.get('productCode'));
    const totalComparePrice = compareRate && compareRate.getIn(['pricing', 'totalPrice']);
    return rate.set('comparePricing', comparePricing).set('totalComparePrice', totalComparePrice);
  });

const decorateRatesWithComparativeValueIndex = (rates, isHotelOnly) => {
  const sortedRates = rates
    .sort((a, b) => a.getIn(['pricing', 'totalPrice']) - b.getIn(['pricing', 'totalPrice']))
    .sort((a, b) => a.get('amenitiesValue') - b.get('amenitiesValue'));
  let amenitiesValue = 0;
  let amenitiesCounter = 0;
  return sortedRates.map((rate) => {
    let rateValue = 0;
    const isRefundable = rate.get('cancelBy') && rate.get('cancelBy') !== 'Nonrefundable';
    // NOTE: Refundability does not add appreciable value to a pair - as they are not refundable anyway;
    if (isRefundable && isHotelOnly) {
      rateValue += 1;
    }
    if (rate.get('amenitiesValue') > amenitiesValue) {
      amenitiesValue = rate.get('amenitiesValue');
      amenitiesCounter += 1;
      rateValue += amenitiesCounter;
    }
    return rate.set('comparativeValueIndex', rateValue);
  });
};

const decorateRatesWithVisibility = (roomRates, isHotelOnly, isMember) => {
  let currentRateCompareValue = -1;
  return roomRates.map((rate) => {
    const rateCompareValue = rate.get('comparativeValueIndex');
    const rateVisibility = rate.getIn(['package', 'visibility']);
    let isVisible = false;
    if (rateVisibility === 'member' && isHotelOnly && isMember) {
      isVisible = true;
      currentRateCompareValue = rateCompareValue;
    } else if (rateVisibility !== 'public' && isHotelOnly) {
      isVisible = false;
    } else if (rateCompareValue > currentRateCompareValue) {
      isVisible = true;
      currentRateCompareValue = rateCompareValue;
    }
    return rate.set('visible', isVisible).set('visibility', rateVisibility);
  });
};

const sortRatesByValue = (rates) =>
  rates.sort((b, a) => {
    const aPrice = Math.floor(a.getIn(['pricing', 'totalPrice']));
    const bPrice = Math.floor(b.getIn(['pricing', 'totalPrice']));
    const aCompareIndex = a.get('comparativeValueIndex');
    const bCompareIndex = b.get('comparativeValueIndex');

    if (aPrice > bPrice) {
      return -1;
    }
    if (bPrice > aPrice) {
      return 1;
    }
    if (aCompareIndex < bCompareIndex) {
      return -1;
    }
    if (bCompareIndex < aCompareIndex) {
      return 1;
    }
    return 0;
  });

const getRateId = (rate, hotelCode) => {
  const checkIn = moment(rate.get('checkIn'));
  const checkOut = moment(rate.get('checkOut'));
  const strCheckIn = checkIn.format('DDMMM').toUpperCase();
  const strDuration = `${checkOut.diff(checkIn, 'days')}NT`;
  return [strCheckIn, strDuration, hotelCode, rate.get('productCode')].join('-');
};

const decorateRateWithId = (room, rates, hotelCode, requestKey, received) =>
  rates.map((rate) => {
    const checkIn = moment(rate.get('checkIn'));
    const checkOut = moment(rate.get('checkOut'));
    const duration = checkOut.diff(checkIn, 'days');
    return rate
      .set('lengthOfStay', duration)
      .set('id', getRateId(rate, hotelCode))
      .set('requestKey', requestKey)
      .set('hotelCode', hotelCode)
      .set('roomName', room.get('name'))
      .set('roomCode', room.get('code'))
      .set('received', received);
  });

export const decorateRates = (
  room,
  rates,
  compareRate,
  hotelCode,
  requestKey,
  received,
  isHotelOnly,
  isMember = false
) => {
  const keyedRates = decorateRateWithId(room, rates, hotelCode, requestKey, received);
  const ratesWithCompare = decorateRatesWithCompareRate(keyedRates, compareRate);
  const ratesWithComparativeValueIndex = decorateRatesWithComparativeValueIndex(ratesWithCompare, isHotelOnly);
  const sortedRates = sortRatesByValue(ratesWithComparativeValueIndex);
  return decorateRatesWithVisibility(sortedRates, isHotelOnly, isMember);
};
