import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 18 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon-marker" transform="translate(-1.000000, 0.000000)">
      <path d="M18.943,10.17 C18.943,11.931 18.576,13.847 17.489,15.571 C14.213,20.658 10.067,25.327 10.067,25.327 C10.067,25.327 5.3,20.822 2.255,15.479 C1.492,13.812 0.993,12.047 0.993,10.28 C0.993,5.313 5.01,0.999 9.968,0.999 C14.926,1 18.943,5.204 18.943,10.17 Z M15.415,9.807 C15.415,6.84 13.012,4.44 10.049,4.44 C7.086,4.44 4.683,6.839 4.683,9.807 C4.683,12.772 7.086,15.172 10.049,15.172 C13.012,15.172 15.415,12.772 15.415,9.807 Z" id="map-marker_4_" />
    </g>
  </svg>
);

export default Svg;
