import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const Styled = styled.div`
  &.sign-up-form-component,
  &.sign-in-form-component {
    /* max-width: 450px; */
  }

  .access-denied-message {
    max-width: 450px;
  }
  /* &.modal-sign-in, */
  &.modal-sign-up {
    min-height: 250px;
    border-radius: 2px;
    background: ${colors.white};
    @media (max-width: ${variables.screen.xs}) {
      height: 100%;
    }

    // from .flex-fill
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    & > * {
      flex: 1;
    }

    form {
      button {
        @media (max-width: ${variables.screen.xs}) {
          white-space: normal;
        }
      }
    }
  }
`;

export default Styled;
