import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_chat">
      <path d="M21,0 L3,0 C1.35,0 0,1.35 0,3 L0,14 C0,15.65 1.35,17 3,17 L5,17 L5,23 L11,17 L21,17 C22.65,17 24,15.65 24,14 L24,3 C24,1.35 22.65,0 21,0 Z" />
    </g>
  </svg>
);

export default Svg;
