import React from 'react';

export const Svg = props => (
  <svg {...props} viewBox="2 3 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-plane-descending">
      <path d="M10 4.09757C10 3.48297 10.506 3.00599 11.0996 3.00006C11.1844 2.99921 11.2722 3.00764 11.3594 3.02692C11.7464 3.11239 12.0577 3.39429 12.1777 3.76563L14.7891 11.855L19.7832 13.1674C20.4894 13.3008 20.9998 13.9077 21 14.6142C21 15.428 20.3284 16.0878 19.5 16.0878C19.3815 16.0874 19.2635 16.0732 19.1484 16.0455V16.0494L4.48828 12.2311C3.61128 12.0032 3 11.2226 3 10.3315V7.7662C3 7.31135 3.43086 6.97427 3.88086 7.07545C4.11786 7.1285 4.31148 7.29568 4.39648 7.51868L5.08398 9.33764L10 10.6098V4.09757ZM3 20.0175C3 19.4753 3.448 19.0351 4 19.0351H20C20.552 19.0351 21 19.4753 21 20.0175C21 20.5598 20.552 20.9999 20 20.9999H4C3.448 20.9999 3 20.5598 3 20.0175Z"></path>
    </g>
  </svg>
);

export default Svg;
