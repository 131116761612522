import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

export const SiteContainer = styled.div`
  max-width: ${variables.siteWidthMax};
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${variables.siteWidthMax}) {
    padding: 0 15px;
  }

  &-padded {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
