import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { getAuthToken } from 'constants/config';
import { AUTH_API_ENDPOINT } from 'constants/endpoints';

import * as reducers from './reducers';

const IDENTIFICATION_ENDPOINT = `${AUTH_API_ENDPOINT}/identify_user`;

const AUTHENTICATION_ENDPOINT = `${AUTH_API_ENDPOINT}/auth_user`;
const IDENTIFY_SUCCESS = 'skylark/authentication/IDENTIFY_SUCCESS';
const SIGNIN_SUCCESS = 'skylark/authentication/SIGNIN_SUCCESS';
const SIGNIN_REQUEST = 'skylark/authentication/SIGNIN_REQUEST';
const SIGNIN_FAILURE = 'skylark/authentication/SIGNIN_FAILURE';

const SIGNUP_ENDPOINT = `${AUTH_API_ENDPOINT}/users`;
const SIGNUP_REQUEST = 'skylark/authentication/SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'skylark/authentication/SIGNUP_SUCCESS';
const SIGNUP_FAILURE = 'skylark/authentication/SIGNUP_FAILURE';

const TOKEN_AUTHENTICATE_NULL = 'skylark/authentication/TOKEN_AUTHENTICATE_NULL';
const TOKEN_AUTHENTICATE_REQUEST = 'skylark/authentication/TOKEN_AUTHENTICATE_REQUEST';
const TOKEN_AUTHENTICATE_SUCCESS = 'skylark/authentication/TOKEN_AUTHENTICATE_SUCCESS';
const TOKEN_AUTHENTICATE_FAILURE = 'skylark/authentication/TOKEN_AUTHENTICATE_FAILURE';

const FORGOT_PASSWORD_ENDPOINT = `${AUTH_API_ENDPOINT}/forgot_password`;
const FORGOT_PASSWORD_REQUEST = 'skylark/authentication/FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'skylark/authentication/FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = 'skylark/authentication/FORGOT_PASSWORD_FAILURE';

const RESET = 'skylark/authentication/RESET';
const CLEAR_ERRORS = 'skylark/authentication/CLEAR_ERRORS';
const SIGN_OUT = 'skylark/authentication/SIGN_OUT';
const GET_PROFILE_FAILURE = 'skylark/user/GET_PROFILE_FAILURE';

const USER_UPDATE_SUCCESS = 'skylark/user/UPDATE_SUCCESS';
const USER_GET_PROFILE_SUCCESS = 'skylark/user/GET_PROFILE_SUCCESS';

const { initialState } = reducers;

export function AuthHeaders() {
  const authToken = getAuthToken();
  return authToken ? { Authorization: authToken } : {};
}

// ACTION CREATORS
export function clearAuthErrors() {
  return { type: CLEAR_ERRORS };
}

function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    if (typeof object[key] !== 'undefined') {
      formData.append(key, object[key]);
    }
  });
  return formData;
}

export function identify(credentials, method) {
  // console.log('authentication > identify', { credentials });
  const credentialsFormData = getFormData({ method, ...credentials });

  return {
    [CALL_API]: {
      endpoint: IDENTIFICATION_ENDPOINT,
      method: 'POST',
      types: [
        {
          type: SIGNIN_REQUEST,
          meta: { email: credentials.email },
        },
        {
          type: IDENTIFY_SUCCESS,
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            return res.json().then(json => ({
              skylarkJWT,
              user: json.user,
            }));
          },
        },
        {
          type: SIGNIN_FAILURE,
          meta: { email: credentials.email },
        },
      ],
      body: credentialsFormData,
    },
  };
}

export function signIn(credentials) {
  const credentialsFormData = new FormData();
  credentialsFormData.append('email', credentials.email);
  credentialsFormData.append('password', credentials.password);
  return {
    [CALL_API]: {
      endpoint: AUTHENTICATION_ENDPOINT,
      method: 'POST',
      types: [
        {
          type: SIGNIN_REQUEST,
          meta: { email: credentials.email },
        },
        {
          type: SIGNIN_SUCCESS,
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            return res.json().then(json => ({
              skylarkJWT,
              user: json.user,
            }));
          },
        },
        {
          type: SIGNIN_FAILURE,
          meta: { email: credentials.email },
        },
      ],
      body: credentialsFormData,
    },
  };
}

export function signOut() {
  return { type: SIGN_OUT };
}

export const signUp = credentials => {
  const { referrer_id, referral_code, invitation_code, organization_app_id, app_settings } = credentials;
  const userData = {
    user: {
      email: credentials.email,
      password: credentials.password,
      first_name: credentials.first_name,
      last_name: credentials.last_name,
      home_airport: credentials.home_airport,
    },
  };
  if (referrer_id) {
    userData.user.referrer_id = referrer_id;
  }
  if (referral_code) {
    userData.referral_code = referral_code;
  }
  if (invitation_code) {
    userData.invitation_code = invitation_code;
  }
  if (organization_app_id) {
    userData.user.organization_app_id = organization_app_id;
  }
  if (app_settings) {
    userData.user.app_settings = app_settings;
  }
  const credentialsFormData = JSON.stringify(userData);
  return {
    [CALL_API]: {
      endpoint: SIGNUP_ENDPOINT,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        {
          type: SIGNUP_REQUEST,
          meta: { email: credentials.email },
        },
        {
          type: SIGNUP_SUCCESS,
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            return res.json().then(json => ({
              skylarkJWT,
              user: json,
            }));
          },
        },
        {
          type: SIGNUP_FAILURE,
          meta: { email: credentials.email },
        },
      ],
      body: credentialsFormData,
    },
  };
};

const shouldBailout = state => state.authentication.get('loggedIn') || state.authentication.get('loading');

export function authenticateFromResetToken(authToken) {
  // console.log('Authentication > authenticateFromResetToken', authToken);
  const credentialsFormData = new FormData();
  if (authToken) {
    credentialsFormData.append('authentication_token', authToken);
  }
  return {
    [CALL_API]: {
      endpoint: AUTHENTICATION_ENDPOINT,
      method: 'POST',
      types: [
        TOKEN_AUTHENTICATE_REQUEST,
        {
          type: TOKEN_AUTHENTICATE_SUCCESS,
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            return res.json().then(json => ({
              skylarkJWT,
              user: json.user,
            }));
          },
        },
        TOKEN_AUTHENTICATE_FAILURE,
      ],
      body: credentialsFormData,
      bailout: state => shouldBailout(state),
    },
  };
}

export function requestPassword(payload) {
  return {
    [CALL_API]: {
      endpoint: FORGOT_PASSWORD_ENDPOINT,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        {
          type: FORGOT_PASSWORD_REQUEST,
          meta: {
            email: payload.email,
          },
        },
        FORGOT_PASSWORD_SUCCESS,
        {
          type: FORGOT_PASSWORD_FAILURE,
          meta: {
            email: payload.email,
          },
        },
      ],
      body: JSON.stringify(payload),
    },
  };
}

// REDUCER
export default function reducer(state = initialState(), action) {
  // console.log('ACTION', action.type, action);
  switch (action.type) {
    case TOKEN_AUTHENTICATE_NULL:
      return state;
    case TOKEN_AUTHENTICATE_REQUEST:
      return reducers.onIdentifyRequest(state);
    case TOKEN_AUTHENTICATE_SUCCESS:
      return reducers.onAuthenticateSuccess(state, action.payload);
    case TOKEN_AUTHENTICATE_FAILURE:
      return reducers.onSignInFailure(state, action);
    case SIGNIN_REQUEST:
      return reducers.onIdentifyRequest(state, action);
    case IDENTIFY_SUCCESS:
      return reducers.onIdentifySuccess(state, action.payload);
    case SIGNIN_SUCCESS:
      return reducers.onSignInSuccess(state, action.payload);
    case SIGNIN_FAILURE:
      return reducers.onSignInFailure(state, action);
    case SIGNUP_REQUEST:
      return reducers.onSignUpRequest(state);
    case SIGNUP_SUCCESS:
      return reducers.onSignupSuccess(state, action.payload);
    case SIGNUP_FAILURE:
      return reducers.onSignUpFailure(state, action);
    case SIGN_OUT:
      return reducers.onSignOut(state, action.email);
    case GET_PROFILE_FAILURE:
      return reducers.onProfileNotFound(state);
    case RESET:
    case CLEAR_ERRORS:
      return state.set('authErrors', Immutable.List());
    case FORGOT_PASSWORD_REQUEST:
      return reducers.onForgotPasswordRequest(state, action);
    case FORGOT_PASSWORD_SUCCESS:
      return reducers.onForgotPasswordSuccess(state);
    case FORGOT_PASSWORD_FAILURE:
      return reducers.onForgotPasswordFailure(state, action);
    case USER_UPDATE_SUCCESS:
    case USER_GET_PROFILE_SUCCESS:
      return reducers.onUserProfileLoaded(state, action);
    default:
      return state;
  }
}
