import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { useAuth } from 'hooks';
import { Button } from 'components/UI';

import { SignInForm } from './SignInForm';
import RequestPasswordUpdate from '../RequestPasswordUpdate';

const TogglePasswordForm = ({ onClick, currentView }) => (
  <div className="component-footer">
    <Button.Link onClick={onClick}>
      {currentView === 'password' ? 'I remembered my password.' : 'Forgot your Password?'}
    </Button.Link>
  </div>
);

TogglePasswordForm.defaultProps = {
  currentView: null,
};

TogglePasswordForm.propTypes = {
  currentView: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const SignIn = ({ buttonText, headerText, email, className }) => {
  const [viewMode, setViewMode] = useState('login');

  const { signIn, requestPassword, passwordRequested, authErrors, clearAuthErrors, isLoading } = useAuth();

  useEffect(() => {
    clearAuthErrors();
  }, []);

  const toggleViewMode = () => {
    setViewMode(viewMode === 'login' ? 'password' : 'login');
  };

  const onSubbmit = formData => {
    signIn(formData);
  };

  const requestPasswordReset = formData => {
    requestPassword(formData);
  };

  const requestStatus = passwordRequested ? 'success' : null;

  const baseClass = classNames('sign-in-component', className);

  return (
    <div className={baseClass}>
      {viewMode === 'password' ? (
        <RequestPasswordUpdate
          loading={isLoading}
          email={email}
          status={requestStatus}
          onSubmit={requestPasswordReset}
          serverErrors={authErrors}
        >
          <TogglePasswordForm onClick={() => toggleViewMode()} currentView={viewMode} />
        </RequestPasswordUpdate>
      ) : (
        <SignInForm
          loading={isLoading}
          email={email}
          headerText={headerText || undefined}
          buttonText={buttonText || undefined}
          serverErrors={authErrors}
          onSubmit={values => onSubbmit(values)}
        >
          <TogglePasswordForm onClick={() => toggleViewMode()} currentView={viewMode} />
        </SignInForm>
      )}
    </div>
  );
};

SignIn.propTypes = {
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  email: PropTypes.string,
  className: PropTypes.string,
};

export default SignIn;
