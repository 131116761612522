import PropTypes from 'prop-types';
import Immutable from 'immutable';
import moment from 'moment';

const searchPropType = PropTypes.shape({
  departDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  returnDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  travelerCount: PropTypes.number,
  roomCount: PropTypes.number,
  origin: PropTypes.string,
  selectedOrigin: PropTypes.string,
  selection: PropTypes.instanceOf(Immutable.Map),
  cabinClass: PropTypes.string,
});

const tripRequestPropType = PropTypes.shape({
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  travelers: PropTypes.number,
  rooms: PropTypes.number,
  origin: PropTypes.string,
  hotel: PropTypes.instanceOf(Immutable.Map),
  cabinClass: PropTypes.string,
});

export default {
  search: searchPropType,
  tripRequest: tripRequestPropType,
};
