import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 28 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_mail">
      <path d="M26.72,18 L1.28,18 L11.57,11.67 C13.077459,12.5239705 14.922541,12.5239705 16.43,11.67 L26.72,18 Z M12.43,9.86 C13.3931019,10.4514878 14.6068981,10.4514878 15.57,9.86 L28,2.21 L28,1 C28,0.44771525 27.5522847,0 27,0 L1,0 C0.44771525,0 0,0.44771525 0,1 L0,2.21 L12.43,9.86 Z M0,4.56 L0,16.44 L9.65,10.5 L0,4.56 Z M18.35,10.5 L28,16.44 L28,4.56 L18.35,10.5 Z" id="Shape" fillRule="nonzero"/>
    </g>
  </svg>
);

export default Svg;