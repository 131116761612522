import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

export const AgentLinkWidget = styled.div`
  position: absolute;
  bottom: calc(50% - 15px);
  right: -25px;
  .link-icon {
    fill: ${colors.link};
  }
  .link-options {
    opacity: 0;
    cursor: pointer;
    background: ${toRgba(colors.white, 1)};
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 0px;
    box-shadow: 1px 1px 5px ${toRgba(colors.black, 0.25)};
    z-index: 1;
    & > div svg {
      fill: ${colors.darkGray};
      &:hover {
        fill: ${colors.black};
      }
      &:active {
        fill: ${colors.skylarkPurple};
      }
    }
  }
  &:hover {
    .link-options {
      opacity: 1;
    }
  }
`;
