import React from 'react';
import PropTypes from 'prop-types';
import FormFieldWrapper from '../FormFieldWrapper';
import SelectWrapper from './SelectWrapper';

export const SelectInput = ({ options, control, label, hint, error, errors, flex, ...rest }) => (
  <FormFieldWrapper label={label} error={error} hint={hint} errors={errors} flex={flex}>
    <SelectWrapper name={options} options={options} control={control} error={error} {...rest} />
  </FormFieldWrapper>
);

SelectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  hint: PropTypes.string,
  flex: PropTypes.number,
  error: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Array),
  control: PropTypes.instanceOf(Object),
};

export default SelectInput;
