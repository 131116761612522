import styled from 'styled-components';
import theme from 'config/theme';

const { colors, rgba } = theme;

export const Container = styled.div`
  display: block;
  color: ${colors.softBlack};
  padding-top: 1em;
  padding-bottom: 1em;

  em {
    font-weight: 500;
    color: ${colors.black};
  }

  .tile-heading {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0.5rem 0;
    @media (max-width: 768px) {
      margin: 0.5rem;
    }
  }

  .tile-body {
    .hero-image {
      text-align: center;
      min-height: 220px;
      h2 {
        max-width: 90%;
        font-size: 2.2em;
        text-shadow: 0 0 10px ${rgba(colors.black, 0.35)};
      }
    }
  }

  .tile-footer {
    padding: 1em 0;
    height: 60px;
    p {
      font-size: 15px;
      margin: 0;
      @media (max-width: 768px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    @media (max-width: 768px) {
      height: 100px;
      padding: 1em 0.5rem;
    }
    @media (max-width: 768px) {
      height: 80px;
    }
  }

  .more-link {
    color: ${colors.link};
    margin-left: 3px;
    display: inline-block;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 768px) {
      display: block;
      margin-top: 3px;
      margin-left: 0;
    }
  }
`;
