import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .from-price {
    font-size: 12px;
    padding: 5px 15px;
    font-family: ${variables.fontFamily.serif};
    font-style: italic;
    text-shadow: 0 0 5px ${toRgba(colors.black, 0.75)};
    .currency {
      font-weight: bold;
      font-size: 150%;
    }
  }
`;
