import styled from 'styled-components';
import { colors, toRgba } from 'styles';

export const FormFieldLabel = styled.label`
  header {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;
    ${props => props.theme.typography.formLabel};
    padding: ${props => props.theme.dimensions.spacerHalf} 0;
    .form-field-error-message {
      font-weight: normal;
      text-align: right;
    }
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.typography.form};

  /* &.form-field-padded {
    margin-bottom: 1rem;
  } */

  .form-field-content {
    width: 100%;
    display: flex;
    & > * {
      width: 100%;
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .field-input-error,
  .field-input-error:focus,
  .select.field-input-error > div:first-child {
    border-color: red;
    outline-color: red;
  }

  .DateInput {
    width: 100%;
  }

  input {
    box-sizing: border-box;
    min-height: calc(${props => props.theme.dimensions.formInput} + 2px);
    ${props => props.theme.typography.form};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.dimensions.borderRadius};
    padding: ${props => props.theme.dimensions.spacerHalf} ${props => props.theme.dimensions.spacer};
    &::placeholder {
      color: ${props => props.theme.colors.neutral30};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[type='date'] {
      font-family: ${props => props.theme.fonts.body};
      height: ${props => props.theme.dimensions.formInput};
    }
  }

  .select input {
    min-height: 20px;
  }

  .form-field-error-message,
  .form-field-hint-message {
    ${props => props.theme.typography.small};
    padding: 2px;
    font-weight: normal;
  }

  .form-field-error-message {
    color: ${props => props.theme.colors.danger};
  }
  .form-field-hint-message {
    color: ${colors.darkGray};
  }
`;
