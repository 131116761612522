import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
});

// ACTION TYPES
const REQUEST = 'skylark/airports/REQUEST';
const SUCCESS = 'skylark/airports/SUCCESS';
const FAILURE = 'skylark/airports/FAILURE';
// const ADD_TO_STATE  = 'skylark/airports/ADD_TO_STATE';

const shouldBailout = (state, code) => {
  const doBailout = !!state.airports.get(code) || !code;
  // console.log('BAILOUT', code, doBailout, state.airports.toJS())
  return doBailout;
};

// ACTION CREATORS
export function requestAirport(code = '') {
  return {
    [CALL_API]: {
      endpoint: `${CONTENT_API_ENDPOINT}/airports/${code}`,
      method: 'GET',
      types: [
        {
          type: REQUEST,
          meta: { code },
        },
        {
          type: SUCCESS,
          meta: { code },
        },
        {
          type: FAILURE,
          meta: { code },
        },
      ],
      bailout: state => shouldBailout(state, code),
    },
  };
}

// REDUCER HELPER METHODS
export const airportRequested = (state, code) =>
  state
    .setIn([code, 'isLoading'], true)
    .setIn([code, 'isLoaded'], false)
    .setIn([code, 'name'], 'Loading...')
    .setIn([code, 'type'], 'airport');

const decorateAirport = airport => airport.set('type', 'airport').set('loaded', true);

// Convert Returned Array into (Airport) Code keyed Map
const keyedAirports = airports => {
  const multiAirportResponse = Array.isArray(airports);
  const airportsArray = multiAirportResponse ? airports : [airports];
  const airportsMap = Immutable.Map(Immutable.fromJS(airportsArray).map(v => [v.get('code'), decorateAirport(v)]));
  return airportsMap;
};

const airportReceived = (airport, state) => {
  const airportMap = keyedAirports(airport);
  const newState = state.merge(airportMap);
  return newState;
};

// REDUCER
export default function reducer(state = initialState, action = {}) {
  const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case REQUEST:
      return airportRequested(state, code);
    case SUCCESS:
      return airportReceived(action.payload, state);
    case FAILURE:
      return state.setIn([code, 'loading'], false).setIn([code, 'loaded'], false);
    default:
      return state;
  }
}
