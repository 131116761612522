import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActiveLink from 'components/UI/ActiveLink';
import track from 'react-tracking';

import { BLOG_ENDPOINT } from 'constants/endpoints';

import Styled from './styled';

const NavigationList = ({ onSearch, className, layoutMode, scrolled, ...props }) => {
  const baseClass = classNames('nav-list', className, {
    'is-home': layoutMode === 'home',
    'is-scrolled': scrolled,
  });

  return (
    <Styled className={baseClass} {...props}>
      <div>
        <button type="button" className="link" onClick={onSearch}>
          Search
        </button>
      </div>
      <div>
        <ActiveLink activeClassName="active" href="/destinations">
          <a>Destinations</a>
        </ActiveLink>
      </div>
      <div className="hide-2">
        <ActiveLink activeClassName="active" href="/collections">
          <a>Collections</a>
        </ActiveLink>
      </div>
      <div className="hide-1">
        <ActiveLink activeClassName="active" href="/deals/hotel">
          <a>Today&apos;s Deals</a>
        </ActiveLink>
      </div>
      <div className="hide-1">
        <a href={BLOG_ENDPOINT} rel="noopener noreferrer" target="_blank">
          Inspire Me
        </a>
      </div>
      <div className="hide-1">
        <a href="https://inspire.skylark.com/articles/faq-28153053-edfe-4f03-842c-77a28be6de47" rel="noopener noreferrer" target="_blank">
          FAQ
        </a>
      </div>
    </Styled>
  );
};

NavigationList.propTypes = {
  className: PropTypes.string,
  layoutMode: PropTypes.string,
  scrolled: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
};

export default track(() => ({ components: ['Navigation List'] }))(NavigationList);
