import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconSearch } from 'components/svg';

import Styled from './styled';

const SearchWidget = ({ onClick, className, scrolled }) => {
  const baseClass = classNames('search-wrapper', className, {
    'is-scrolled': scrolled,
  });

  return (
    <Styled className={baseClass} onClick={onClick}>
      <IconSearch className="icon" width={20} />
      <span className="placeholder">Search...</span>
    </Styled>
  );
};

SearchWidget.propTypes = {
  className: PropTypes.string,
  scrolled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SearchWidget;
