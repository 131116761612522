import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import track, { useTracking } from 'react-tracking';
import { DestinationLink } from 'components/Links';

import { Pluralize } from 'components/Helpers';
import HeroImage from 'components/UI/HeroImage';

import Events from 'constants/events';
import { getCardEntityInfo } from 'lib/trackingHelpers';

import BaseCard from '../BaseCard';

const ViewLink = () => (
  <button type="button" className="btn-view-destination">
    View
  </button>
);

export const DestinationCard = ({ destination, header, className, fallbackImageUrl, index, ...props }) => {
  const tracking = useTracking();
  const isWaiting = !destination.get('id') || destination.get('loading');
  const imageUrl = destination.getIn(['images', 0, 'large_url']) || fallbackImageUrl;
  const heroClassName = fallbackImageUrl && 'no-loader';
  const heroHeader = header || <ViewLink />;
  const baseClass = classNames({ waiting: isWaiting }, className);

  const onClick = () => {
    const cardEntity = getCardEntityInfo(destination.toJS(), 'destination');
    tracking.trackEvent({
      event: Events.CARD_CLICKED,
      ...cardEntity,
      position: index,
    });
  };

  return (
    <DestinationLink destination={destination} onClick={onClick}>
      <BaseCard className={baseClass} loading={isWaiting} {...props}>
        <HeroImage imageUrl={imageUrl} header={heroHeader} className={heroClassName} />
        <div className="card-body">
          <h2>{destination.get('name')}</h2>
          {destination.get('hotel_count') ? (
            <p>
              <Pluralize count={destination.get('hotel_count')}>Hotel</Pluralize>
              {destination.get('child_count') ? (
                <span>
                  {' '}
                  in <Pluralize count={destination.get('child_count')}>Region</Pluralize>
                </span>
              ) : null}
            </p>
          ) : null}
        </div>
      </BaseCard>
    </DestinationLink>
  );
};

DestinationCard.defaultProps = {
  destination: Immutable.Map(),
};

DestinationCard.propTypes = {
  destination: PropTypes.instanceOf(Immutable.Map),
  header: PropTypes.node,
  className: PropTypes.string,
  fallbackImageUrl: PropTypes.string,
  index: PropTypes.number,
};

export default track(() => ({ components: ['DestinationCard'] }))(DestinationCard);
