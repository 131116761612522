import colors from './colors';

const spacerUnit = 8;

export const dimensions = {
  containerWidth: '1280px',
  paragraphWidth: '800px',
  borderRadius: `${spacerUnit / 2}px`,

  spacerHalf: `${spacerUnit / 2}px`,
  spacer: `${spacerUnit}px`,
  spacer2: `${spacerUnit * 2}px`,
  spacer3: `${spacerUnit * 3}px`,
  spacer4: `${spacerUnit * 4}px`,
  spacer5: `${spacerUnit * 5}px`,
  spacer6: `${spacerUnit * 6}px`,
  formInput: '34px',
};

export const aspectRatio = {
  square: 'padding-top: 100%',
  standard: 'padding-top: 75%',
  wide: 'padding-top: 66.66%',
  wider: 'padding-top: 75%',
  theatre: 'padding-top: 52.25%',
  inherit: 'padding-top: inherit',
};

export const fonts = {
  body: '"proxima-nova", arial, sans-serif',
  block: '"akzidenz-grotesk-extended", "Arial Black", sans-serif',
  mono: '"Roboto Mono", courier',
  material: 'Material Icons',
};

export const typography = {
  large: 'font-size: 24px; line-height: 36px',
  medium: 'font-size: 16px; line-height: 22px',
  small: 'font-size: 14px; line-height: 18px',
  xs: 'font-size: 11px; line-height: 13px',
  tiny: 'font-size: 11px; line-height: 13px',

  form: 'font-size: 15px; line-height: 20px',
  formLabel: 'font-size: 14px; line-height: 18px',
  label: 'font-size: 16px; line-height: 20px; font-weight: 600',
};

// const shadows = {
//   itinerary: '0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22)',
// };

export const mediaQuery = {
  maxMobileWidth: '900px',
};

const toHexAlpha = decimalValue => {
  const alpha = Math.floor(decimalValue * 255);
  const hex = (alpha + 0x10000)
    .toString(16)
    .substr(-2)
    .toUpperCase();
  return hex;
};

export const rgba = (hexColor, alphaDecimal) => `${hexColor}${toHexAlpha(alphaDecimal)}`;

export default {
  colors,
  fonts,
  typography,
  dimensions,
  rgba,
  aspectRatio,
  mediaQuery,
};
