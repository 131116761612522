import Immutable from 'immutable';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { CONFIG_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
});

// ACTION TYPES
const REQUEST = 'skylark/clientConfig/REQUEST';
const SUCCESS = 'skylark/clientConfig/SUCCESS';
const FAILURE = 'skylark/clientConfig/FAILURE';

export const fetchConfig = (anonymousId, segmentAnonymousId, userId, sessionInfo) => async dispatch => {
  // export const fetchConfig = async anonymousId => {
  const resourceUrl = `${CONFIG_API_ENDPOINT}/client_config`;

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.get(resourceUrl, {
      params: { aid: anonymousId, seg_aid: segmentAnonymousId, uid: userId, session: sessionInfo },
      headers: requestHeaders,
    });
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    // console.log('e.response', e.response);
    dispatch({
      type: FAILURE,
      payload: { error: respStatus },
    });
  }
};

export const initialize = (anonymousId, segmentAnonymousId, userId) =>
  fetchConfig(anonymousId, segmentAnonymousId, userId);

// REDUCER HELPER METHODS
const configRequested = state => state.set('loading', true).set('loaded', false);

const configReceived = (state, configPayload) =>
  Immutable.fromJS(configPayload)
    .set('loading', false)
    .set('loaded', true);

const requestFailed = (state, configPayload) => {
  Sentry.captureException(new Error('requestFailed', 'modules/clientConfig.js'));
  return Immutable.fromJS(configPayload)
    .set('loading', false)
    .set('loaded', false)
    .set('error', configPayload.error);
};

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return configRequested(state);
    case SUCCESS:
      return configReceived(state, action.payload);
    case FAILURE:
      return requestFailed(state, action.payload);
    default:
      return state;
  }
}
