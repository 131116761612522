import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Label = ({ label, fieldName, className, isRequired, error, children }) => (
  <label className={className}>
    <div className="label-container">
      {label && (
        <span className="label" htmlFor={fieldName}>
          {label}
          {label && label.length > 1 && isRequired && <strong>*</strong>}
        </span>
      )}
      {label && error && <span className="label error-label">{error}</span>}
    </div>
    {children}
  </label>
);

Label.defaultProps = {
  fieldName: null,
  label: null,
  className: null,
  error: null,
  children: null,
};

Label.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  children: PropTypes.node,
};

export default Label;
