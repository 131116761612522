import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const Styled = styled.div`
  flex: 0 !important;

  header {
    display: block;
    margin: 0;
    z-index: 3;
    min-height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    background: ${colors.white};
    transition: background-color 0.25s ease;
    transition: box-shadow 0.25s ease;
  }

  &.home-page {
    header {
      svg {
        fill: ${colors.white};
        transition: fill 0.1s;
      }
      background: ${toRgba(colors.white, 0)};
      .currency-select__single-value {
        color: ${colors.white};
      }
    }
  }

  /* .header-wrapper {
    border: 1px dashed lime;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > * {
      border: 1px dashed orange;
    }
  } */

  .header-content {
    position: relative;
    font-size: 16px;
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    height: 60px;
    & > div {
      flex: 0;
        /* border: 1px solid magenta; */
      
    }

    div.currency-select__option {
      color: ${colors.black};
    }

    margin-right: 10px;
    margin-left: 10px;
    transition: all 0.3s ease;
    transition-delay: 0s;
  }

  .layout-header.is-scrolled {
    padding: 0;
    background: ${toRgba(colors.white, 1)};
    box-shadow: 0 0 15px 5px ${toRgba(colors.black, 0.15)};

    svg {
      fill: ${colors.black};
    }

    .header-content {
      font-size: 14px;
      color: ${colors.black};
    }

    .currency-select__single-value {
      color: inherit;
      .has-value.Select--single > .Select-control .Select-value .Select-value-label {
        color: inherit !important;
      }
    }

    .nav-list {
      transition: transform 0.2s 0.2s ease;
    }
  }

  @media (min-width: 769px) and (max-width: 1150px) {
    .header-content {
      font-size: 14px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`;

export default Styled;
