import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 308 255" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <g id="Skylark-Logo">
          <path d="M74.9595,143.1136 C73.0965,142.1786 70.9835,142.0236 69.0055,142.6836 C67.0275,143.3406 65.4255,144.7256 64.4915,146.5896 C63.5575,148.4526 63.4055,150.5676 64.0645,152.5436 C64.7205,154.5206 66.1055,156.1236 67.9695,157.0576 C69.0715,157.6116 70.2615,157.8896 71.4595,157.8896 C72.2865,157.8896 73.1155,157.7556 73.9225,157.4866 C78.0055,156.1316 80.2235,151.7066 78.8645,147.6286 C78.2085,145.6506 76.8225,144.0486 74.9595,143.1136" id="Fill-1"></path>
          <path d="M81.2573,49.2415 C89.7793,39.4055 100.8993,30.9995 113.4183,24.9335 C127.6653,18.0335 142.7703,14.5335 158.3143,14.5335 C174.1173,14.5335 188.1193,16.9335 205.0903,28.6485 C219.3273,38.4775 228.8613,56.0145 230.5953,75.5635 C232.3403,95.2225 224.8343,112.1945 212.7583,126.4105 C190.2283,152.9375 151.5503,157.8195 120.7283,139.7705 C120.2873,139.5125 117.7943,138.3995 116.6503,140.1385 C114.4883,143.4265 113.1403,148.1425 116.4033,150.2865 C127.8893,157.8305 143.2383,162.1575 158.5193,162.1575 C188.6023,162.1575 215.9833,145.5595 229.9763,118.8405 C242.6813,94.5885 242.1423,66.4785 228.5203,41.6345 L229.4083,41.6345 L229.4243,41.6515 C231.4813,43.7485 244.5663,53.0525 251.3523,80.1955 C254.2433,91.7585 255.0513,104.9055 252.0273,121.9205 C246.7663,151.5435 227.1323,174.4045 210.0093,183.9195 C180.1873,200.4895 143.2933,200.7705 113.9293,183.3875 C111.2693,181.8125 109.0193,182.4375 107.8113,183.9795 C105.8963,186.4235 104.3533,189.6785 104.1833,192.6385 C104.0403,195.1255 106.2903,197.2505 108.5523,198.4145 C126.6433,207.7195 140.2193,211.4565 158.2233,211.4565 C202.6593,211.4565 243.6283,182.2675 257.8493,140.4765 C266.9083,113.8595 263.9313,94.5585 260.1913,79.0975 C254.0873,53.8625 235.4653,31.1925 217.5323,19.1195 C199.5993,7.0465 179.9543,0.9995 158.5003,0.9995 C123.7893,0.9995 93.0743,17.0315 72.0063,46.1415 C69.2173,49.9955 69.4473,52.8905 70.9103,54.0495 C72.7353,55.4955 76.6373,54.5685 81.2573,49.2415" id="Fill-3"></path>
          <path d="M268.752,184.4681 C266.742,183.2061 262.814,184.5361 258.911,190.4701 C246.252,209.7191 220.671,232.5021 186.847,238.7221 C178.541,240.2831 169.688,241.0411 159.78,241.0411 C121.878,241.0411 94.838,228.6711 69.367,199.6781 C54.864,183.1651 39.076,173.0041 22.44,169.4751 L22.139,168.2061 C26.547,165.0791 31.983,160.9021 35.388,156.1231 C39.281,150.6621 41.415,144.9501 43.481,139.4261 C45.566,133.8481 47.535,128.5791 51.125,124.1061 C61.654,110.9781 73.307,105.6001 88.095,100.9451 C88.915,108.1751 93.234,115.5721 97.775,120.4381 C99.082,121.8381 103.507,125.6771 105.69,124.2631 C108.025,122.7501 105.628,119.7341 104.313,118.3251 C99.008,112.6401 97.369,102.6181 102.733,96.4501 C102.741,96.4421 103.89,95.2931 103.948,94.2491 C104.008,93.1631 103.296,92.2501 102.092,91.8031 C99.726,90.9271 94.94,91.5521 92.085,91.9251 L91.238,92.0351 C62.06,95.6421 47.196,110.3351 40.93,118.7721 C37.074,123.9651 35.135,129.7911 33.261,135.4261 C31.942,139.3921 30.695,143.1381 28.881,146.2031 C21.452,158.7551 6.342,168.5551 0.19,172.1851 L0,172.2971 L0.612,175.3801 L0.863,175.3851 C24.276,175.7501 47.945,193.0661 57.272,205.2461 C69.974,221.8311 85.743,234.6081 104.136,243.2221 C121.356,251.2851 140.078,255.3761 159.78,255.3761 C169.22,255.3761 178.959,254.1371 188.72,251.6931 C188.72,251.6931 189.166,251.5931 189.246,251.5731 L189.549,251.4901 C222.749,243.2881 250.037,222.8391 268.481,192.3361 C270.911,188.3171 270.367,185.4881 268.752,184.4681" id="Fill-6"></path>
          <path d="M302.8828,34.2488 C279.2228,34.8918 264.7838,58.4238 269.5888,80.6078 C271.3318,88.6548 277.1868,87.2968 275.4368,79.2168 C274.1108,73.0958 277.8488,64.9448 281.2528,60.1098 C287.1378,51.7498 296.0898,48.0228 306.0978,47.7508 C309.7258,47.6528 307.5478,34.1228 302.8828,34.2488" id="Fill-9"></path>
      </g>
  </svg>

export default Svg;
