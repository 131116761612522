import React from 'react';

export const Svg = props => (
  <svg {...props} viewBox="2 3 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-plane-descending">
      <path
        // fillRule="evenodd"
        // clipRule="evenodd"
        d="M8.01112 4.04149C8.09707 4.01619 8.18576 4.00309 8.27321 4.00047C8.53555 3.99259 8.79489 4.08437 8.9925 4.26337L15.229 9.90543L20.352 8.59094V8.59281C20.4756 8.56065 20.6029 8.54435 20.7308 8.54433C21.5422 8.54433 22.2 9.18544 22.2 9.97629C22.1996 10.6712 21.6874 11.2655 20.9852 11.3859L20.9795 11.3877L6.55723 15.0907C5.70705 15.3084 4.81341 14.9477 4.36873 14.2088L3.10614 12.1093C2.85343 11.6893 3.07198 11.1486 3.54996 11.0092C3.79874 10.9357 4.06851 10.9896 4.26734 11.1528L5.72124 12.3442L10.7104 11.0614L7.42574 5.47718C7.10251 4.9273 7.38818 4.22573 8.01112 4.04149ZM3.2 19.0453C3.2 18.5184 3.6388 18.0907 4.17947 18.0907H19.8509C20.3916 18.0907 20.8304 18.5184 20.8304 19.0453C20.8304 19.5723 20.3916 20 19.8509 20H4.17947C3.6388 20 3.2 19.5723 3.2 19.0453Z"
      ></path>
    </g>
  </svg>
);

export default Svg;
