import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import * as Styled from './Loader.styled';

const Loader = ({ className, size, hidden, children }) => {
  const baseClass = classNames('loader-component', className);

  if (hidden) {
    return null;
  }

  return (
    <Styled.Loader className={baseClass}>
      <div>
        <div className="loader-spinnner">
          <div className={`tag-spinner-wrapper ${size}`}>
            <div className={`tag-spinner lark ${size}`} />
          </div>
        </div>
        {children && (
          <div className="loader-message">
            <span>{children}</span>
          </div>
        )}
      </div>
    </Styled.Loader>
  );
};

Loader.defaultProps = {
  className: null,
  size: 'large',
  hidden: false,
  children: null,
};

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  hidden: PropTypes.bool,
  children: PropTypes.node,
};

export default Loader;
