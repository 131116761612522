import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 54 51" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-diving" fillRule="nonzero">
      <path d="M53,8 L50,8 C50,3.9 44.3,3 41.3,2.3 C36.8,1.3 32.1,0.2 27.4,0.1 C22.7,7.87814258e-13 17.9,1.1 13.4,2.1 C10,2.9 4,3.3 4,7.9 L1,7.9 C0.4,7.9 0,8.3 0,8.9 L0,18.9 C0,19.5 0.4,19.9 1,19.9 L4,19.9 C4,22 5.7,23.9 7.8,24.1 L21.6,25.7 C22,25.7 22.5,25.7 22.9,25.6 C23.1,25.8 23.4,25.9 23.8,25.9 L29.8,25.9 C30.2,25.9 30.5,25.8 30.7,25.6 C31.1,25.7 31.6,25.7 32,25.7 L46.2,24.1 C48.3,23.9 50,22 50,19.9 L53,19.9 C53.6,19.9 54,19.5 54,18.9 L54,8.9 C54,8.4 53.6,8 53,8 Z M48,20 C48,21.1 47.1,22.1 46,22.2 L31.8,23.8 C30.7,23.9 29.7,21.2 29.4,20.1 L28.7,17.9 C28.4,16.9 28.6,14.6 27.1,14.6 C25.6,14.6 25.8,17 25.5,17.9 L24.8,20.1 C24.5,21.1 23.1,23.9 22,23.8 L8.2,22.2 C7.1,22.1 6.2,21.1 6.2,20 L6.2,19 L6.2,9 L6.2,8 C6.2,6.9 7,6 8.1,5.8 C10.8,5.3 13.5,4.7 16.3,4.3 C19.8,3.7 23.6,2.7 27.2,2.7 C30.7,2.7 34.3,3.6 37.7,4.2 C40.6,4.7 43.4,5.3 46.2,5.8 C47.3,6 48.2,6.9 48.2,8 L48.2,9 L48.2,19 L48.2,20 L48,20 Z" id="Shape"></path>
      <path d="M24.1,7.1 L24.1,7.1 L9.6,8.7 C8.8,8.8 8.1,9.6 8.1,10.4 L8.1,16.6 C8.1,17.4 8.8,18.3 9.7,18.4 L20.2,19.8 C21,19.9 21.9,19.3 22.2,18.5 L25.1,8.2 C25.2,7.5 24.8,7.1 24.1,7.1 Z" id="Shape"></path>
      <path d="M44.4,8.7 L29.7,7.1 C29.1,7.1 28.8,7.6 29,8.2 L31.9,18.5 C32.1,19.3 33,19.9 33.9,19.8 L44.4,18.4 C45.2,18.3 46,17.5 46,16.6 L46,10.4 C46,9.6 45.3,8.8 44.4,8.7 Z" id="Shape"></path>
      <path d="M16,44 L40,44 L40,50 L16,50 L16,44 Z M42,44 L42,50 L50,50 L50,44 L42,44 Z M18,37.5 C18,36.2 17.4,36.5 16.5,36.2 C15.6,35.9 15.7,33.8 16.7,33.8 C18.6,33.7 17.9,31.5 17.9,30.1 L15.4,30.1 C14.3,30.1 13.9,32.1 13.9,32.1 C8.7,32.1 3.4,35.4 3.4,41.1 C3.4,46.8 8.7,50.1 13.9,50.1 L13.9,44.1 C11.5,44.1 9.4,44.1 9.4,41.1 C9.4,38.1 11.5,38.1 13.9,38.1 C13.9,38.1 14.3,40.1 15.4,40.1 L17.9,40.1 C18,40 18,40 18,37.5 Z" id="Shape"></path>
    </g>
  </svg>

export default Svg;
