import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useIsMobile from 'lib/UserAgent';
import Slider from './Slider';

import * as Styled from './Carousel.styled';

const Carousel = ({ images, className, children }) => {
  const isMobile = useIsMobile();
  const sliderSettings = {
    dots: true,
    infinite: true,
    fade: !isMobile.any,
    easing: 'linear',
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
  };

  const baseClass = classNames(className);
  return (
    <Styled.Carousel className={baseClass}>
      {children && <Styled.ContentOverlay>{children}}</Styled.ContentOverlay>}
      <Slider {...sliderSettings}>
        {images.map((image) => (
          <div key={image} className="slide-wrapper">
            <div className="slide" style={{ backgroundImage: `url(${image})` }}>
              <Styled.CarouselGradient />
            </div>
          </div>
        ))}
      </Slider>
    </Styled.Carousel>
  );
};

Carousel.defaultProps = {
  className: undefined,
  images: undefined,
  children: null,
};

Carousel.propTypes = {
  className: PropTypes.string,
  images: PropTypes.instanceOf(Array),
  children: PropTypes.node,
};

export default Carousel;
