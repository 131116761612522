import React from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import { Pluralize } from 'components/Helpers';

import '../index.scss';
import './index.scss';

class StaySlider extends React.Component {
  state = {
    range: this.props.range,
    value: this.props.value,
  };

  componentDidMount() {
    this.initializeSlider(this.props);
  }

  componentWillReceiveProps(props) {
    this.initializeSlider(props);
  }

  onAfterChange(v) {
    this.props.onChange(v);
  }

  initializeSlider(props) {
    const { range, value } = props;
    this.setState({
      range,
      value,
    });
  }

  render() {
    const step = 1; // this.state.stepDistance;
    const { range } = this.props;
    const value = this.state.value || this.props.value;

    return (
      <div className="slider-component">
        <div className="range-slider-component">
          <Range
            value={value}
            min={range[0]}
            max={range[1]}
            step={step}
            pushable={step}
            onAfterChange={v => this.onAfterChange(v)}
            onChange={v => this.setState({ value: v })}
            withBars
          />
        </div>
        <div className="slider-display-text">
          <span className="display-value">
            <Pluralize count={value[0]}>night</Pluralize>
          </span>
          <span> ⟷ </span>
          <span className="display-value">
            <Pluralize count={value[1]}>night</Pluralize>
          </span>
        </div>
      </div>
    );
  }
}

StaySlider.defaultProps = {
  label: 'Staying between',
  value: [1, 7],
  range: [1, 7],
  onChange: () => null,
};

StaySlider.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number),
  range: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export default StaySlider;
