import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({ id, required, children }) =>
  children ? (
    <label htmlFor={id}>
      {children} {required ? <span className="required">*</span> : null}
    </label>
  ) : null;

Label.defaultProps = {
  id: '',
  children: '',
  required: false,
};

Label.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
};

const ErrorMessage = ({ errorMessage }) =>
  errorMessage ? (
    <span className="error-message">{errorMessage}</span>
  ) : (
    <span className="error-message" style={{ opacity: 0 }}>
      Error Message
    </span>
  );

ErrorMessage.defaultProps = {
  errorMessage: '',
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

// ONLY USED IN TRIPSEARCH
export const InputWrapper = ({
  children,
  label,
  type,
  required,
  errorMessage,
  className,
  id,
  LabelComponent,
  hint,
  ...props
}) => {
  const typeSpecificClassName = `form-control-${type}`;
  const fieldClass = classNames(
    {
      'form-control-error': !!errorMessage,
    },
    'form-control',
    typeSpecificClassName,
    className
  );
  return (
    <div {...props} className={fieldClass}>
      <div className="form-control-header">
        {LabelComponent || (
          <Label id={id} required={required}>
            {label}
          </Label>
        )}
        <ErrorMessage errorMessage={errorMessage} />
      </div>
      {children}
      {hint ? <span className="hint">{hint}</span> : null}
    </div>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  LabelComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  hint: PropTypes.string,
};
