import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 40 52" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_train">
      <path d="M40,32 L40,24 L36.6,3.9 C36.3,1.7 34.2,0 32,0 L8,0 C5.8,0 3.7,1.8 3.4,3.9 L0,24 L0,32 C0,32 2,40 8,40 L10.7,40 L0,52 L8,52 L14.4,40 L25.5,40 L32,52 L40,52 L29.1,40 L32,40 C38,40 40,32 40,32 Z M12,34 L4,32 L4,26 L12,30 L12,34 Z M20,26.6 C12,26.6 4,22 4,22 L7.1,4.6 C7.1,4.4 7.5,4 7.8,4 L32.4,4 C32.6,4 33,4.3 33.1,4.6 L36.1,22 C36,22 28,26.6 20,26.6 Z M28,34 L28,30 L36,26 L36,32 L28,34 Z" id="Shape" />
    </g>
  </svg>
);

export default Svg;
