import Immutable from 'immutable';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import AnalyticsService, { Events } from 'components/Analytics/AnalyticsService';
import { AuthHeaders } from 'modules/authentication/authentication';

import { BOOKING_API_ENDPOINT, requestHeaders } from 'constants/endpoints';
// import { initialize } from './clientConfig';

export const initialState = Immutable.fromJS({
  request: null,
  user: null,
  travelers: null,
  passengers: [],
  rooms: null,
  items: [],
  hotelStays: [],
  primaryTraveler: null,
  hotelStayItems: [],
  airfareItems: [],
  paymentMethod: null,
  currencyCode: 'USD',
  status: 'new',
  step: 'shop',
  recordLocator: null,
  processing: false,
  processed: false,
});

// ACTION TYPES
const INITIALIZE = 'skylark/bookingCart/INITIALIZE';
const UPDATE = 'skylark/bookingCart/UPDATE';
const EMPTY = 'skylark/bookingCart/EMPTY';
const SET_USER = 'skylark/bookingCart/SET_USER';
const SET_BROWSER_SESSION_KEY = 'skylark/bookingCart/SET_BROWSER_SESSION_KEY';
const SET_CURRENCY_CODE = 'skylark/bookingCart/SET_CURRENCY_CODE';
const ADD_ITEM = 'skylark/bookingCart/ADD_ITEM';
const INCREMENT_ITEM = 'skylark/bookingCart/INCREMENT_ITEM';
const DECREMENT_ITEM = 'skylark/bookingCart/DECREMENT_ITEM';
const REMOVE_ITEM = 'skylark/bookingCart/REMOVE_ITEM';

const BUILD = 'skylark/bookingCart/BUILD';

const PROCESS = 'skylark/bookingCart/PROCESS';
const PROCESS_SUCCESS = 'skylark/bookingCart/PROCESS_SUCCESS';
const PROCESS_FAILURE = 'skylark/bookingCart/PROCESS_FAILURE';
// const REQUEST = 'skylark/bookingCart/REQUEST';
// const SUCCESS = 'skylark/bookingCart/SUCCESS';
// const FAILURE = 'skylark/bookingCart/FAILURE';

export const processBooking = (cart) => async (dispatch) => {
  console.log('processBooking > cart', cart && cart.toJS());

  const payload = {
    uuid: cart.get('uuid'),
  };

  console.log('processBooking > payload', payload);

  const resourceUrl = `${BOOKING_API_ENDPOINT}/booking_carts/process_booking`;

  dispatch({
    type: PROCESS,
  });

  try {
    const results = await axios.post(resourceUrl, payload, { headers: AuthHeaders() });
    console.log('processBooking > results', results);
    dispatch({
      type: PROCESS_SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    console.log('processBooking > onFailure', e);
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: PROCESS_FAILURE,
      payload: respStatus,
    });
  }
};

export const persistToServer = async (cart) => {
  // console.log('persistToServer > cart', cart && cart.toJS());

  const primaryTraveler = cart && cart.get('primaryTraveler');
  const hotelStays = cart && cart.get('hotelStays');
  const flights = cart && cart.get('airfareItems');
  const paymentMethod = cart && cart.get('paymentMethod');
  const passengers = cart && cart.get('passengers');
  const payload = {
    user_id: cart.getIn(['user', 'id']),
    request: cart.get('request'),
    travelers: cart.get('travelers'),
    primary_traveler: primaryTraveler && primaryTraveler.toJS(),
    hotel_stays: hotelStays && hotelStays.toJS(),
    flights: flights && flights.toJS(),
    payment_method: paymentMethod && paymentMethod.toJS(),
    currency_code: cart.get('currencyCode'),
    passengers: passengers && passengers.toJS(),
    uuid: cart.get('uuid'),
    session_key: cart.get('sessionKey'),
    browser_session_key: cart.get('browserSessionKey'),
    organization_app_id: cart.getIn(['user', 'organization_app_id']),
    status: cart.get('status'),
    step: cart.get('step'),
    loading: false,
    loaded: false,
  };
  // console.log('persist > payload', payload);

  const resourceUrl = `${BOOKING_API_ENDPOINT}/booking_carts`;

  try {
    const results = await axios.post(resourceUrl, payload, { headers: AuthHeaders() });
    // console.log('persist > results', results);
    // dispatch({
    //   type: PERSIST_SUCCESS,
    //   payload: results.data,
    // });
  } catch (e) {
    console.log('persist > onFailure', e);
    // const respStatus = e.response ? e.response.status : null;
    // dispatch({
    //   type: PERSIST_FAILURE,
    //   payload: respStatus,
    // });
  }
};

export const initialize = ({ user, travelers, passengers, rooms, hotelStayItems, airfareItems }) => async (
  dispatch
) => {
  dispatch({
    type: INITIALIZE,
    payload: { user, travelers, passengers, rooms, hotelStayItems, airfareItems },
  });
};

export const update = ({ userInfo, primaryTraveler, passengers, paymentMethod, step }) => async (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: { userInfo, primaryTraveler, passengers, paymentMethod, step },
  });
};

export const empty = () => async (dispatch) => {
  dispatch({
    type: EMPTY,
    payload: {},
  });
};

export const setUser = ({ user }) => async (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: { user },
  });
};

export const setBrowserSessionKey = (browserSessionKey) => async (dispatch) => {
  dispatch({
    type: SET_BROWSER_SESSION_KEY,
    payload: { browserSessionKey },
  });
};

export const setCurrencyCode = ({ currencyCode }) => async (dispatch) => {
  dispatch({
    type: SET_CURRENCY_CODE,
    payload: { currencyCode },
  });
};

export const addItem = ({ id, item, category, quantity }) => async (dispatch) => {
  dispatch({
    type: ADD_ITEM,
    payload: { id, item, category, quantity },
  });
};

export const incrementItem = ({ id, item, category }) => async (dispatch) => {
  dispatch({
    type: INCREMENT_ITEM,
    payload: { id, item, category },
  });
};

export const decrementItem = ({ id, category }) => async (dispatch) => {
  dispatch({
    type: DECREMENT_ITEM,
    payload: { id, category },
  });
};

export const removeItem = ({ id, quantity }) => async (dispatch) => {
  dispatch({
    type: REMOVE_ITEM,
    payload: { id, quantity },
  });
};

export const build = (requestQuery) => async (dispatch, getState) => {
  const { clientConfig } = getState();
  dispatch({
    type: BUILD,
    userId: clientConfig.get('uid'),
    sessionKey: clientConfig.get('session_key'),
    payload: { request: requestQuery },
  });
};

// REDUCERS

const initializeReducer = (state, action) => {
  const { user, travelers, passengers, rooms, hotelStayItems, airfareItems } = action.payload;
  return state
    .set('user', user)
    .set('travelers', travelers)
    .set('passengers', passengers)
    .set('rooms', rooms)
    .set('hotelStayItems', hotelStayItems)
    .set('airfareItems', airfareItems);
};

const setUserReducer = (state, action) => {
  const { user } = action.payload;
  const updatedBooking = state.set('user_id', user.get('id')).set('user', user);
  // persistToServer(updatedBooking);
  return updatedBooking;
};

const setBrowserSessionKeyReducer = (state, action) => {
  const { browserSessionKey } = action.payload;
  const updatedBooking = state.set('browserSessionKey', browserSessionKey);
  // persistToServer(updatedBooking);
  return updatedBooking;
};

const setCurrencyCodeReducer = (state, action) => {
  const { currencyCode } = action.payload;
  const updatedBooking = state.set('currencyCode', currencyCode);
  return updatedBooking;
};

const updateReducer = (state, action) => {
  // console.log('updateReducer > action', action);
  const { userInfo, primaryTraveler, passengers, paymentMethod, step } = action.payload;
  const updatedBooking = state
    .set('user', userInfo || state.get('user'))
    .set('primaryTraveler', primaryTraveler || state.get('primaryTraveler'))
    .set('passengers', passengers || state.get('passengers'))
    .set('paymentMethod', paymentMethod || state.get('paymentMethod'))
    .set('step', step);

  persistToServer(updatedBooking);
  return updatedBooking;
};

const emptyReducer = (state) =>
  state
    .set('request', initialState.get('request'))
    .set('travelers', initialState.get('travelers'))
    .set('items', initialState.get('items'))
    .set('hotelStays', initialState.get('hotelStays'))
    .set('hotelStayItems', initialState.get('hotelStayItems'))
    .set('airfareItems', initialState.get('airfareItems'))
    .set('paymentMethod', initialState.get('paymentMethod'))
    .set('primaryTraveler', initialState.get('primaryTraveler'))
    .set('passengers', initialState.get('passengers'))
    .set('recordLocator', initialState.get('recordLocator'));

const getCartItem = (state, category, id) => {
  const items = state.get('items').filter((i) => i.get('id') === id && i.get('category') === category);
  // console.log('items', items);
  return items && items.first();
};

const incrementItemReducer = (state, action) => {
  const { id, item, category } = action.payload;
  const existingItem = getCartItem(state, category, id);
  const quantity = (existingItem ? existingItem.get('quantity') : 0) + 1;
  const upsetItem = item
    .set('id', id)
    .set('quantity', quantity)
    .set('category', category);

  const cartItems = state.get('items').filterNot((i) => i.get('id') === id && i.get('category') === category);
  const items = cartItems.push(upsetItem);
  return state.set('items', items);
};

const decrementItemReducer = (state, action) => {
  const { id, category } = action.payload;
  const item = getCartItem(state, category, id);
  const quantity = item ? item.get('quantity') - 1 : 0;
  const cartItems = state.get('items').filterNot((i) => i.get('id') === id && i.get('category') === category);
  const items = quantity > 0 ? cartItems.push(item.set('quantity', quantity)) : cartItems;
  return state.set('items', items);
};

const addItemReducer = (state, action) => {
  const { id, item, category, quantity } = action.payload;
  const existingItem = getCartItem(state, category, id);
  const cartItems = state.get('items').filterNot((i) => i.get('id') === id && i.get('category') === category);

  // console.log('item', item);

  const cartItem = item
    .set('id', id || uuidv4())
    .set('quantity', ((existingItem && existingItem.get('quantity')) || 0) + quantity)
    .set('category', category);
  const items = cartItems.push(cartItem);
  return state.set('items', items);
};

const removeItemReducer = (state, action) => {
  const { itemId } = action.payload;
  const items = state.get('items').filterNot((item) => item.get('id') === itemId);
  return state.set('items', items);
};

const getHotelStayItems = (state) => {
  const category = 'hotelStay';
  return state.get('items').filter((i) => i.get('category') === category);
};

const getAirfareItems = (state) => {
  const category = 'airfare';
  return state.get('items').filter((i) => i.get('category') === category);
};

const buildHotelRoomItem = (rate) => {
  // console.log('RATE', rate && rate.toJS());
  const pricing = rate.get('pricing');
  const comparePricing = rate.get('comparePricing') || pricing;
  const savings = (comparePricing.get('totalPrice') - pricing.get('totalPrice')).toFixed(2);
  return Immutable.fromJS({
    id: uuidv4(),
    key: rate.get('id'),
    productCode: rate.get('productCode'),
    hotelCode: rate.get('hotelCode'),
    hotelName: rate.get('hotelName'),
    roomName: rate.get('roomName'),
    roomCode: rate.get('roomCode'),
    checkIn: rate.get('checkIn'),
    checkOut: rate.get('checkOut'),
    cancelBy: rate.get('cancelBy'),
    guaranteeType: rate.get('guaranteeType'),
    prepaidIndicator: rate.get('prepaidIndicator'),
    rateKey: rate.get('rateKey'),
    nightlyRateBreakdown: rate.get('nightlyRateBreakdown'),
    pricing,
    comparePricing,
    savings,
    amenities: rate.get('amenities'),
    amenitiesValue: rate.get('amenitiesValue'),
    received: rate.get('received'),
    guestFirstName: '',
    guestLastName: '',
    guestEmail: '',
    guestPhone: '',
  });
};

const buildFlightSegment = (segment, bookingCode, keyedFareBasisCodes) => {
  const fareBasisCode = keyedFareBasisCodes.get(segment.get('key'));
  return segment.set('booking_code', bookingCode).set('fare_basis_code', fareBasisCode);
};

const buildFlightLeg = (leg, keyedFareBasisCodes) => {
  const bookingCodes = leg.get('booking_codes');
  const segments = leg
    .get('flight_segments')
    .map((fs, index) => buildFlightSegment(fs, bookingCodes.get(index) || '?', keyedFareBasisCodes));
  return Immutable.Map({
    origin: leg.get('origin'),
    destination: leg.get('destination'),
    airlines: leg.get('airlines'),
    arrives_at: leg.get('arrives_at'),
    departs_at: leg.get('departs_at'),
    cabin_codes: leg.get('cabin_codes'),
    booking_codes: leg.get('booking_codes'),
    elapsed_time: leg.get('elapsed_time'),
    layover_time: leg.get('layover_time'),
    red_eye: leg.get('red_eye'),
    stops: leg.get('stops'),
    flight_segments: segments,
  });
};

const buildItinerary = (itinerary) => {
  const segmentKeys = itinerary.get('segment_keys');
  const fareBasisCodes = itinerary.get('fare_basis_codes');
  // TODO: Move fareBasisCode logic back into itinerary Selector
  const keyedFareBasisCodes = Immutable.Map(segmentKeys.map((v, index) => [v, fareBasisCodes.get(index)]));
  // console.log('keyedFareBasisCodes', keyedFareBasisCodes && keyedFareBasisCodes.toJS());

  const legs = itinerary.get('legs').map((leg) => buildFlightLeg(leg, keyedFareBasisCodes));
  return Immutable.Map({
    raw_fare: itinerary.get('raw_fare'),
    adjusted_fare: itinerary.get('adjusted_fare'),
    compare_fare: itinerary.get('compare_fare'),
    private_fare: itinerary.get('private_fare'),
    last_ticket_date: itinerary.get('last_ticket_date'),
    passenger_type: itinerary.get('passenger_type'),
    legs,
  });
  // return itinerary.set('legs', legs);
};

const buildFlights = (flights) => flights.map((f) => f.set('itinerary', buildItinerary(f.get('itinerary'))));

const buildHotelStay = (state, hotelCode) => {
  const items = getHotelStayItems(state).filter((i) => i.get('hotelCode') === hotelCode);
  const rooms = items.flatMap((item) => Array(item.get('quantity')).fill(buildHotelRoomItem(item)));
  const checkIn = items.getIn([0, 'checkIn']);
  const checkOut = items.getIn([0, 'checkOut']);
  // console.log('rooms', rooms && rooms.toJS());
  const hotelCurrencyCodes = rooms && rooms.getIn([0, 'nightlyRateBreakdown']).keySeq();
  // console.log('hotelCurrencyCodes', hotelCurrencyCodes && hotelCurrencyCodes.toJS());
  const hotelCurrencyCode = hotelCurrencyCodes && (hotelCurrencyCodes.filterNot((cc) => cc === 'USD').first() || 'USD');
  // console.log('hotelCurrencyCode', hotelCurrencyCode);
  return Immutable.fromJS({
    hotelCode,
    checkIn,
    checkOut,
    rooms,
    hotelCurrencyCode,
  });
};

const buildHotelStays = (state) => {
  const hotelCodes = getHotelStayItems(state)
    .map((i) => i.get('hotelCode'))
    .toSet();

  const stays = hotelCodes.map((hotelCode) => buildHotelStay(state, hotelCode)).toList();
  return stays;
};

const buildAirfareItems = (state) => {
  const items = getAirfareItems(state);
  // console.log('Airfare Items', items && items.toJS());
  return items;
};

const buildRequest = (request) =>
  Immutable.fromJS(request)
    .delete('destinationPath')
    .delete('0')
    .delete('1')
    .delete('travelers')
    .delete('cabinClass')
    .delete('check_in')
    .delete('check_out')
    .toJS();

const buildReducer = (state, action) => {
  console.log('buildReducer > action', action);
  const cartId = uuidv4();
  const hotelStays = buildHotelStays(state);
  const airfareItems = buildAirfareItems(state);
  const flights = buildFlights(airfareItems);
  const { request } = action.payload;
  // console.log('buildReducer > request', request);
  // console.log('buildReducer > flights', flights && flights.toJS());
  // console.log('buildReducer > hotelStays', hotelStays && hotelStays.toJS());
  // console.log('buildReducer > airfareItems', airfareItems && airfareItems.toJS());
  return state
    .set('uuid', cartId)
    .set('request', buildRequest(request))
    .set('sessionKey', action.sessionKey)
    .set('userId', action.userId)
    .set('hotelStays', hotelStays)
    .set('airfareItems', flights)
    .set('passengers', initialState.get('passengers'))
    .set('primaryTraveler', initialState.get('primaryTraveler'))
    .set('paymentMethod', initialState.get('paymentMethod'))
    .set('items', initialState.get('items')); // Clear out cart items
};

const processReducer = (state, action) => {
  console.log('processReducer > action', action);
  return state.set('processing', true);
};

const processSuccessReducer = (state, action) => {
  console.log('processReducer > action', action);
  AnalyticsService.trackReducer(Events.BOOKING_COMPLETE, {
    id: action.payload.id,
    record_locator: action.payload.record_locator,
    hotel_code: action.payload.hotel_code,
    hotel_name: action.payload.hotel_name,
    status: action.payload.status,
    total_price: action.payload.total_price,
    trip_type: action.payload.trip_type,
  });
  return state
    .set('processing', false)
    .set('processed', true)
    .set('id', action.payload.id)
    .set('recordLocator', action.payload.record_locator);
};

const processFailureReducer = (state, action) => {
  console.log('processFailureReducer > action', action);
  return state.set('processing', false).set('processed', false);
};

// REDUCER METHODS

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALIZE:
      return initializeReducer(state, action);
    case UPDATE:
      return updateReducer(state, action);
    case EMPTY:
      return emptyReducer(state, action);
    case SET_USER:
      return setUserReducer(state, action);
    case SET_BROWSER_SESSION_KEY:
      return setBrowserSessionKeyReducer(state, action);
    case SET_CURRENCY_CODE:
      return setCurrencyCodeReducer(state, action);
    case ADD_ITEM:
      return addItemReducer(state, action);
    case INCREMENT_ITEM:
      return incrementItemReducer(state, action);
    case DECREMENT_ITEM:
      return decrementItemReducer(state, action);
    case REMOVE_ITEM:
      return removeItemReducer(state, action);
    case BUILD:
      return buildReducer(state, action);
    case PROCESS:
      return processReducer(state, action);
    case PROCESS_SUCCESS:
      return processSuccessReducer(state, action);
    case PROCESS_FAILURE:
      return processFailureReducer(state, action);
    default:
      return state;
  }
}
