import Immutable from 'immutable';

export const currencyData = Immutable.fromJS({
  AED: { code: 'AED', flag: 'ae', symbol: 'د.إ', title: 'United Arab Emirates Dirham' },
  AUD: { code: 'AUD', flag: 'au', symbol: '$', title: 'Australian Dollar' },
  USD: { code: 'USD', flag: 'us', symbol: '$', title: 'United States Dollar' },
  CAD: { code: 'CAD', flag: 'ca', symbol: '$', title: 'Canadian Dollar' },
  GBP: { code: 'GBP', flag: 'gb', symbol: '£', title: 'Great British Pound' },
  EUR: { code: 'EUR', flag: 'eu', symbol: '€', title: 'Euro' },
  HKD: { code: 'HKD', flag: 'hk', symbol: '$', title: 'Hong Kong Dollar' },
  ILS: { code: 'ILS', flag: 'il', symbol: '₪', title: 'Israeli new shekel' },
  INR: { code: 'INR', flag: 'in', symbol: '₹', title: 'Indian Rupee' },
  SGD: { code: 'SGD', flag: 'sg', symbol: '$', title: 'Singapore Dollar' },
});

export default currencyData;
