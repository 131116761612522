import Immutable from 'immutable';

import { CALL_API } from 'redux-api-middleware';
import { PRICING_API_ENDPOINT } from 'constants/endpoints';
import { urlDate, flatDate } from 'components/Helpers';

export const requestKey = request => {
  const r = request.toJS();
  const strDepartsOn = flatDate(r.departsOn);
  const strReturnsOn = flatDate(r.returnsOn);
  return `${r.originCode}|${r.destinationCode}|${r.hotelCode}|${strDepartsOn}|${strReturnsOn}|${r.travelerCount}|${r.roomCount}|${r.cabinClass}`;
};

const isValid = request => {
  const r = request.toJS();
  return r.originCode && r.destinationCode && r.hotelCode && r.travelerCount && r.roomCount && r.cabinClass;
};

const requestPath = request => {
  const r = request.toJS();
  // console.log('REQUEST', r);
  const strDepartsOn = urlDate(r.departsOn);
  const strReturnsOn = urlDate(r.returnsOn);
  return `${r.originCode}/${r.destinationCode}/${r.hotelCode}/${strDepartsOn}/${strReturnsOn}/${r.travelerCount}/${r.roomCount}/${r.cabinClass}`;
};

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
});

// Action Types
const REQUEST = 'skylark/availability/REQUEST';
const SUCCESS = 'skylark/availability/SUCCESS';
const FAILURE = 'skylark/availability/FAILURE';
const MERGED_REQUEST = 'skylark/availability/MERGED_REQUEST';
const MERGED_SUCCESS = 'skylark/availability/MERGED_SUCCESS';

// Action Creators

export function requestMergedAvailability(request = {}, refresh = false) {
  const key = requestKey(request);
  const invalidRequest = !isValid(request);
  const requestURL = `${PRICING_API_ENDPOINT}/csl_merged_trip/${requestPath(request)}`;
  // console.log('requestMergedAvailability', requestURL);
  return {
    [CALL_API]: {
      endpoint: requestURL,
      method: 'GET',
      types: [
        {
          type: MERGED_REQUEST,
          meta: { key },
        },
        {
          type: MERGED_SUCCESS,
          meta: { key },
        },
        {
          type: FAILURE,
          meta: { key },
        },
      ],
      bailout: state => (state.availability.get(key) && !refresh) || invalidRequest,
    },
  };
}

export function requestAvailability(request = {}) {
  const key = requestKey(request);
  const invalidRequest = !isValid(request);
  const requestURL = `${PRICING_API_ENDPOINT}/csl_trip/${requestPath(request)}`;
  return {
    [CALL_API]: {
      endpoint: requestURL,
      method: 'GET',
      types: [
        {
          type: REQUEST,
          meta: { key },
        },
        {
          type: SUCCESS,
          meta: { key },
        },
        {
          type: FAILURE,
          meta: { key },
        },
      ],
      bailout: state => invalidRequest || state.availability.get(key),
    },
  };
}

// Reducer Methods
const availabiltyRequested = (state, key) => {
  // console.log('REQUESTED')
  const timeStamp = Date.now();
  const newState = Immutable.fromJS({ loading: true, loaded: false }).set('requestTime', timeStamp);
  return state.set(key, newState);
};

const availabiltyReceived = (availability, state, key) => {
  // console.log('SUCCESS')
  const requestTime = state.getIn([key, 'requestTime']);
  const responseTime = Date.now() - requestTime;
  const availabilityOptions = Immutable.fromJS(availability)
    .set('received', Date.now())
    .set('responseTime', responseTime);
  return state.set(key, availabilityOptions);
};

const mergedAvailabiltyRequested = (state, key) => {
  // console.log('MERGED_REQUESTED')
  const timeStamp = Date.now();
  const newState = Immutable.fromJS({ loading: true, loaded: false }).set('requestTime', timeStamp);
  return state.set(key, newState);
};

const mergedAvailabiltyReceived = (availability, state, key) => {
  // console.log('MERGED_SUCCESS');
  const requestTime = state.getIn([key, 'requestTime']);
  const responseTime = Date.now() - requestTime;
  const availabilityOptions = Immutable.fromJS(availability)
    .set('received', Date.now())
    .set('responseTime', responseTime);
  return state.set(key, availabilityOptions);
};

const availabiltyFailed = (errorMessage, state, key) => {
  // console.log('AVAILABILITY > FAILURE', errorMessage);
  const requestTime = state.getIn([key, 'requestTime']);
  const responseTime = Date.now() - requestTime;
  const errors = Immutable.fromJS(errorMessage);
  return state
    .setIn([key, 'errors'], errors)
    .setIn([key, 'loading'], false)
    .setIn([key, 'responseTime'], responseTime);
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const key = action.meta ? action.meta.key : null;
  // console.log('AVAILABILITY REDUCER', action);
  switch (action.type) {
    case REQUEST:
      // console.log('CALL REQUESTED!!');
      return availabiltyRequested(state, key);
    case SUCCESS:
      // console.log('CALL SUCCESS!!');
      return availabiltyReceived(action.payload, state, key);
    case MERGED_REQUEST:
      // console.log('CALL REQUESTED!!');
      return mergedAvailabiltyRequested(state, key);
    case MERGED_SUCCESS:
      // console.log('CALL SUCCESS!!');
      return mergedAvailabiltyReceived(action.payload, state, key);
    case FAILURE:
      // console.log('CALL FAILED!!');
      return availabiltyFailed(action.payload, state, key);
    default:
      return state;
  }
}
