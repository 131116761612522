import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './HeroContent.styled';

const HeroContent = ({ children, ...props }) => <Styled.HeroContent {...props}>{children}</Styled.HeroContent>;

HeroContent.propTypes = {
  children: PropTypes.node,
};

export default HeroContent;
