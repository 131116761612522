// URL: https://requirements-api.sandbox.joinsherpa.com/v2/countries/FR?include=restriction,procedure&filter[restriction.originCountries]=USA&filter[procedure.originCountries]=USA&language=en&key=AIzaSyD181LWkwSCLTB4NTqSGOq_UkkAso4_HEg

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPaymentMethods } from 'modules/paymentMethods';
import { useAuth } from 'hooks';

const usePaymentMethods = () => {
  const dispatch = useDispatch();

  const paymentMethods = useSelector(state => state.paymentMethods);
  const { userId, isLoggedIn } = useAuth();

  // console.log('usePaymentMethods', { userId, isLoggedIn });

  useEffect(() => {
    if (userId && isLoggedIn) {
      dispatch(fetchPaymentMethods(userId, v => console.log('I got called back', v)));
    }
  }, [userId, isLoggedIn]);

  return paymentMethods;
};

export default usePaymentMethods;
