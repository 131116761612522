import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 26 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-car">
        <path d="M25.5,7 L23.6,7 L21.957,1.525 C21.391,0.391 20.953,0 19.641,0 L13,0 L6.359,0 C5.047,0 4.609,0.391 4.043,1.525 L2.4,7 L0.5,7 C0.224,7 0,7.224 0,7.5 L0,8.5 C0,8.776 0.224,9 0.5,9 L1.417,9 L1,10 L1,21.5 C1,21.775 1.225,22 1.5,22 L3.5,22 C3.775,22 4,21.775 4,21.5 L4,20 L13,20 L22,20 L22,21.5 C22,21.775 22.225,22 22.5,22 L24.5,22 C24.775,22 25,21.775 25,21.5 L25,10 L24.583,9 L25.5,9 C25.776,9 26,8.776 26,8.5 L26,7.5 C26,7.224 25.776,7 25.5,7 Z M5.627,2.404 C5.752,2.185 5.922,2 6.234,2 L13,2 L19.766,2 C20.078,2 20.248,2.185 20.373,2.404 C20.541,2.773 21.56,7 21.56,7 L13,7 L4.439,7 C4.439,7 5.458,2.773 5.627,2.404 Z M7,14 L4,14 L3,13 L3,10.9 L7,12 L7,14 Z M17,18 L9,18 L9,16 L17,16 L17,18 Z M23,13 L22,14 L19,14 L19,12 L23,10.9 L23,13 Z" id="Shape"></path>
    </g>
  </svg>

export default Svg;
