import React from 'react';
import PropTypes from 'prop-types';

const calculateFlightDuration = (mins, precision) => {
  if (precision === 'loose') {
    const hrs = Math.round(mins / 60);
    return { standard: `${hrs === 0 ? '½' : hrs}h` };
  }
  let hours = Math.floor(mins / 60);
  const minutes = parseInt(mins, 10) % 60;
  let frac = Math.floor(minutes / 15);
  const fracRemainder = parseInt(minutes, 10) % 15;
  const hmString = `${hours}h ${minutes}m`;
  let hmMobileString = '';

  // mobile
  frac += fracRemainder > 6;

  if (frac === 4) {
    hours += 1;
    hmMobileString = `${hours}h`;
  } else if (hours) {
    // (hours) ? hmMobileString = hours : ''
    switch (frac) {
      case 3:
        hmMobileString = `${hours}¾h`;
        break;
      case 2:
        hmMobileString = `${hours}½h`;
        break;
      case 1:
        hmMobileString = `${hours}¼h`;
        break;
      case 0:
        hmMobileString = `${hours === 0 ? '½' : hours}h`;
        break;
      default:
        hmMobileString = hours;
    }
  }
  return {
    standard: hmString,
    mobile: hmMobileString,
  };
};

export const FlightDuration = ({ minutes, precision, children }) => {
  if (!minutes || minutes === 0) {
    return <span>NA</span>;
  }

  const flightDuration = calculateFlightDuration(minutes, precision);
  return precision === 'loose' ? (
    <span>{flightDuration.standard}</span>
  ) : (
    <span>
      {flightDuration.mobile}
      {children}
    </span>
  );
};

FlightDuration.defaultProps = {
  minutes: undefined,
  precision: 'loose',
  children: undefined,
};

FlightDuration.propTypes = {
  minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  precision: PropTypes.string,
  children: PropTypes.node,
};

export default FlightDuration;
