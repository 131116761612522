import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { AUTH_API_ENDPOINT } from 'constants/endpoints';
import { SESSION_TIMEOUT, setAuthToken } from 'constants/config';
import AnalyticsService, { Events } from 'components/Analytics/AnalyticsService';

// import CREATE_MEMBERSHIP_SUCCESS from 'modules/membershipApplication';
const CREATE_MEMBERSHIP_SUCCESS = 'skylark/membership/CREATE_MEMBERSHIP_SUCCESS';

const GET_PROFILE_ENDPOINT = `${AUTH_API_ENDPOINT}/profile`;
const GET_PROFILE_REQUEST = 'skylark/user/GET_PROFILE_REQUEST';
const GET_PROFILE_SUCCESS = 'skylark/user/GET_PROFILE_SUCCESS';
const GET_PROFILE_FAILURE = 'skylark/user/GET_PROFILE_FAILURE';

const UPDATE_ENDPOINT = `${AUTH_API_ENDPOINT}/profile`;
const UPDATE_REQUEST = 'skylark/user/UPDATE_REQUEST';
const UPDATE_SUCCESS = 'skylark/user/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'skylark/user/UPDATE_FAILURE';

const SIGN_OUT = 'skylark/authentication/SIGN_OUT';

const initialState = Immutable.Map({
  loading: false,
  loaded: false,
});

export function getUserProfile() {
  return {
    [CALL_API]: {
      endpoint: `${GET_PROFILE_ENDPOINT}`,
      method: 'GET',
      types: [
        GET_PROFILE_REQUEST,
        {
          type: GET_PROFILE_SUCCESS,
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            setAuthToken(skylarkJWT);
            return res.json().then(json => json);
          },
        },
        GET_PROFILE_FAILURE,
      ],
      bailout: state => state.user.get('loaded') || state.user.get('loading'), // || !userId,
    },
  };
}

export function updateUserProfile(profileData) {
  const updatedProfile = profileData && profileData.toJS();
  AnalyticsService.trackReducer(Events.USER_PROFILE_UPDATE, updatedProfile);
  // console.log('updatedProfile', updatedProfile);
  // TODO meta tags to indicate which profile properties have changed?
  return {
    [CALL_API]: {
      endpoint: UPDATE_ENDPOINT,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData.toJS()),
      types: [
        UPDATE_REQUEST,
        {
          type: UPDATE_SUCCESS,
          meta: { source: 'UPDATE_USER_PROFILE' },
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            setAuthToken(skylarkJWT);
            return res.json().then(json => json);
          },
        },
        UPDATE_FAILURE,
      ],
    },
  };
}

export function changePassword(changePasswordData) {
  return {
    [CALL_API]: {
      endpoint: UPDATE_ENDPOINT,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changePasswordData),
      types: [
        UPDATE_REQUEST,
        {
          type: UPDATE_SUCCESS,
          meta: { source: 'CHANGE_PASSWORD' },
          payload: (action, state, res) => {
            const skylarkJWT = res.headers.get('authorization');
            setAuthToken(skylarkJWT);
            return res.json().then(json => json);
          },
        },
        UPDATE_FAILURE,
      ],
    },
  };
}

const onGetProfileRequest = state => state.set('loading', true).set('loaded', false);

const onGetProfileSuccess = (state, payload) => {
  const user = state
    .merge(Immutable.Map(payload))
    .set('loading', false)
    .set('loaded', true);
  // console.log('user > onGetProfileSuccess', user && user.toJS());
  // console.log('user > onGetProfileSuccess', payload);
  AnalyticsService.identify(user);
  return user;
};

const onUpdateUserProfileRequest = state => state.set('updating', true).set('updated', false);

const onUpdateUserProfileSuccess = (state, user, meta) => {
  // console.log('onUpdateUserProfile > user', user);
  AnalyticsService.trackReducer(
    meta.source === 'CHANGE_PASSWORD' ? Events.USER_PASSWORD_UPDATED : Events.USER_PROFILE_UPDATED,
    user
  );
  return state
    .merge(Immutable.Map(user))
    .set('updating', false)
    .set('updated', true);
};

const onUpdateProfileFailed = (state, meta) => {
  AnalyticsService.trackReducer(
    meta.source === 'CHANGE_PASSWORD' ? Events.USER_PASSWORD_UPDATE_FAILED : Events.USER_PROFILE_UPDATE_FAILED
  );
  return state.set('updating', false).set('updated', false);
};

const reducer = (state = initialState, action) => {
  // console.log('ACTION', action);
  switch (action.type) {
    case CREATE_MEMBERSHIP_SUCCESS:
      console.log('USER - CREATE_MEMBERSHIP_SUCCESS', action);
      // NOTE: Mark active membership true
      // this avoids the need to reload the user in process
      return state.set('active_membership', true);
    case GET_PROFILE_REQUEST:
      return onGetProfileRequest(state);
    case GET_PROFILE_SUCCESS:
      return onGetProfileSuccess(state, action.payload);
    case GET_PROFILE_FAILURE:
      console.log('GET_PROFILE_FAILURE');
      return initialState;
    case UPDATE_REQUEST:
      return onUpdateUserProfileRequest(state);
    case UPDATE_SUCCESS:
      return onUpdateUserProfileSuccess(state, action.payload, action.meta);
    case UPDATE_FAILURE:
      return onUpdateProfileFailed(state, action.meta);

    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
