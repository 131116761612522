import styled from 'styled-components';
import { variables, colors, toRgba } from 'styles';

export const Footer = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  p,
  a {
    font-size: 85%;
  }

  .mobile-hide {
    @media (max-width: ${variables.screen.xs}) {
      display: none !important;
    }
  }
  .mobile-only {
    a {
      margin-bottom: 5px;
    }
    @media (min-width: ${variables.screen.xs}) {
      display: none !important;
    }
    @media (max-width: ${variables.screen.xs}) {
      display: flex;
      font-size: 13px;
      /* flex: 2; */
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1140px;
    margin: auto;
    padding: 20px;
    @media (max-width: ${variables.screen.xs}) {
      max-width: 600px;
    }

    .address-container {
      margin-bottom: 10px;
    }

    & > * {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 15px;
      @media (max-width: ${variables.screen.xs}) {
        align-items: center;
        max-width: 380px;
        min-height: 0;
      }
      & > * {
        flex: 1;
        &:first-child {
          flex: 2;
          @media (max-width: ${variables.screen.xs}) {
            flex: 1;
          }
          margin-right: 20px;
        }
        p {
          margin: 0;
        }
      }
    }

    h2 {
      margin: 0;
      text-transform: uppercase;
    }
    a {
      color: ${colors.white};
      margin-right: 15px;
      margin-bottom: 3px;
      display: block;
      @media (max-width: ${variables.screen.xs}) {
        margin-right: 0;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  svg {
    fill: ${colors.white};
  }

  footer {
    justify-content: space-between;
    align-items: center !important;
    & > * {
      &:last-child {
        flex: 0;
        margin-right: 20px;
      }
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  margin-top: 25px;
  @media (max-width: ${variables.screen.xs}) {
    margin-top: 0;
  }
  a {
    flex: 0;
    /* border: 1px solid lime; */
    margin-right: 15px !important;
  }
`;

export const MadeInNY = styled.div`
  background-image: url('https://d2q9bdd302n972.cloudfront.net/assets/made-in-ny-logo-white.png');
  height: 55px;
  width: 55px;
  background-size: cover;
  @media (max-width: ${variables.screen.xs}) {
    height: 40px;
    width: 40px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;
