import React from 'react';
import PropTypes from 'prop-types';

export const HiddenInput = ({ name, register, control, ...props }) => (
  <input type="hidden" name={name} {...register(name)} control={control} {...props} />
);

HiddenInput.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.instanceOf(Object),
};

export default HiddenInput;
