import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

export const ContentContainer = styled.div`
  max-width: ${variables.contentWidthMax};
  margin: 0 auto 20px;
  @media (max-width: ${variables.screen.tablet}) {
    padding-left: 10px;
    padding-right: 10px;
    h1 {
      text-align: center;
      margin-top: 20px;
    }
  }
`;

export const ContentGrid = styled.div`
  /* border: 1px dashed magenta; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    display: block;
    flex: 0 0 calc(${props => 100 / (props.columns[0] || 3)}% - 1rem);
    margin: 0 0 2rem !important;

    @media (max-width: ${variables.screen.xs}) {
      flex: 0 0 calc(${props => 100 / (props.columns[1] || 1)}% - 0.5rem);
      max-width: calc(${props => 100 / (props.columns[1] || 1)}% - 0.5rem) !important;
    }
  }
`;

export const ContentRow = styled.div`
  /* border: 1px dashed lime; */
  margin-bottom: 2.5rem;
  display: flex;
  align-items: ${props => (props.center ? 'center' : 'stretch')};

  @media (max-width: ${variables.screen.xs}) {
    flex-direction: column;
  }
  @media (min-width: ${variables.screen.xs}) {
    flex-direction: ${props => (props.alternate ? 'row-reverse' : 'row')};
  }

  & > * {
    padding-left: 2rem;
    margin-bottom: 0 !important;
    /* border: 1px dashed orange; */
    flex: 1;
    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      display: block;
      width: 80%;
      box-shadow: 2px 2px 5px ${toRgba(colors.black, 0.25)};
    }

    @media (max-width: ${variables.screen.xs}) {
      padding-left: 0;
      img {
        width: 100%;
        aspect-ratio: 1.5/1;
      }
    }
    /* flex */
  }
`;

export const ContentSection = styled.div`
  background: ${props => (props.alternate ? colors.paleGray : colors.white)};
  border-bottom: 1px solid ${colors.coolGray};
  padding-top: ${props => (props.padded ? '2.5rem' : 'none')};
  /* padding-bottom: ${props => (props.padded ? '2rem' : 'none')}; */
`

export const ContentBlock = styled.div`
  /* border: 1px dashed magenta; */
  margin-bottom: 2.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    font-family: ${variables.fontFamily.display};
    strong {
      font-weight: 500;
    }
  }

  header {
    /* font-family: ${variables.fontFamily.display}; */

    @media (max-width: ${variables.screen.xs}) {
      margin-top: 0.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 400;
      text-transform: uppercase;
      font-family: ${variables.fontFamily.display};
      strong {
        font-weight: 500;
      }
      &.sub,
      .sub {
        font-weight: 300;
      }
      .sub {
        font-size: 90%;
      }
    }
    margin-bottom: 1rem;
  }

  p {
    /* font-size: 1rem; */
    line-height: 1.5;
    font-family: ${variables.fontFamily.text};
    margin-bottom: 1rem;
    /* text-align: justify; */
  }
`;

export const ContainedContentBlock = styled(ContentBlock)`
  border: 1px solid ${colors.coolGray};
  background: ${colors.white};
  margin: 0 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${props => props.linked && `1px 1px 5px ${toRgba(colors.black, 0.15)}`};
  &:hover {
    box-shadow: ${props => props.linked && `1px 1px 10px ${toRgba(colors.black, 0.25)}`};
  }

  @media (max-width: ${variables.screen.xs}) {
    /* padding: 0.5rem;
    p {
      font-size: 0.9rem;
    } */
  }
`;
