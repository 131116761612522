import React from 'react';
import PropTypes from 'prop-types';
import { SimpleFormat } from './SimpleFormat';

export const ContentSection = ({ title, children, ...props }) => {
  if (!children || (children && children.replace(/\s/g, '') == '')) {
    return null;
  }
  return (
    <section {...props}>
      {title && <h3>{title}</h3>}
      <SimpleFormat>{children}</SimpleFormat>
    </section>
  );
};

ContentSection.defaultProps = {
  title: null,
  children: null,
};

ContentSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
};
