import React from 'react';
import PropTypes from 'prop-types';

const uniqueKey = () => Math.random().toString(36).replace(/[^a-z]/, '').substr(2);

const Line = ({ tag, children }) => (
  (tag === 'span')
    ? <span style={{ display: 'block' }}>{children}</span>
    : <p>{children}</p>
);

Line.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};


export class SimpleFormat extends React.PureComponent {
  render() {
    const { children, tag } = this.props;
    return (
      children
        ? (children.split('\n').map(line => <Line key={uniqueKey()} tag={tag}>{line}</Line>))
        : null
    );
  }
}

SimpleFormat.defaultProps = {
  children: null,
  tag: 'p'
};

SimpleFormat.propTypes = {
  children: PropTypes.string,
  tag: PropTypes.string
};

export default SimpleFormat;
