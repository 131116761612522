import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { COLLECTIONS_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const fetchCollections = async () => {
  const resourceUrl = `${COLLECTIONS_API_ENDPOINT}/hotel_collections`;
  const res = await fetch(resourceUrl, requestHeaders);
  const data = await res.json();
  return data;
};

export const fetchCollection = async id => {
  const resourceUrl = `${COLLECTIONS_API_ENDPOINT}/hotel_collections/${id}`;
  const res = await fetch(resourceUrl, requestHeaders);
  const data = await res.json();
  return data;
};

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  list: [],
  items: {},
});

// ACTION TYPES
const REQUEST_COLLECTION_LIST = 'skylark/collections/REQUEST_COLLECTION_LIST';
const REQUEST_COLLECTION_LIST_SUCCESS = 'skylark/collections/REQUEST_COLLECTION_LIST_SUCCESS';
const REQUEST_COLLECTION_LIST_FAILURE = 'skylark/collections/REQUEST_COLLECTION_LIST_FAILURE';
const REQUEST_COLLECTION = 'skylark/collections/REQUEST_COLLECTION';
const REQUEST_COLLECTION_SUCCESS = 'skylark/collections/REQUEST_COLLECTION_SUCCESS';
const REQUEST_COLLECTION_FAILURE = 'skylark/collections/REQUEST_COLLECTION_FAILURE';

// ACTION CREATORS
export const requestCollectionList = () => {
  const endPoint = `${COLLECTIONS_API_ENDPOINT}/hotel_collections`;
  return {
    [CALL_API]: {
      endpoint: endPoint,
      method: 'GET',
      types: [REQUEST_COLLECTION_LIST, REQUEST_COLLECTION_LIST_SUCCESS, REQUEST_COLLECTION_LIST_FAILURE],
      bailout: state => state.collections.get('loaded') || state.collections.get('loading'),
    },
  };
};

export const requestCollectionSet = (ids) => {
  const endPoint = `${COLLECTIONS_API_ENDPOINT}/hotel_collections?id=${ids.join(',')}`;
  return {
    [CALL_API]: {
      endpoint: endPoint,
      method: 'GET',
      types: [REQUEST_COLLECTION_LIST, REQUEST_COLLECTION_LIST_SUCCESS, REQUEST_COLLECTION_LIST_FAILURE],
      bailout: state => state.collections.get('loaded') || state.collections.get('loading'),
    },
  };
};

const collectionPresent = (state, code) => state.collections.getIn(['items', code]);

export function requestCollection(code = '') {
  const endpoint = `${COLLECTIONS_API_ENDPOINT}/hotel_collections/${code}`;
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        { type: REQUEST_COLLECTION, meta: { code } },
        { type: REQUEST_COLLECTION_SUCCESS, meta: { code } },
        { type: REQUEST_COLLECTION_FAILURE, meta: { code } },
      ],
      bailout: state => collectionPresent(state, code),
    },
  };
}

// REDUCER HELPER METHODS
const collectionListRequested = state => state.set('loading', true).set('loaded', false);

const collectionListReceived = (list, state) => state.set('list', Immutable.fromJS(list)).set('loaded', true);

const collectionListFailed = state => state.set('loading', false).set('loaded', false);

const collectionRequested = (state, code) =>
  state.setIn(['items', code, 'loading'], true).setIn(['items', code, 'loaded'], false);

// Convert Hotels Array into code keyed, Immutable Map
const createHotelsMap = hotels => {
  if (!hotels) {
    return null;
  }
  return Immutable.Map(hotels.map(hotel => [hotel.get('code'), hotel.set('object_type', 'hotel')]));
};

const collectionReceived = (collection, state, code) => {
  // console.log('collectionReceived', collection);
  const collectionMap = Immutable.fromJS(collection)
    .set('id', code)
    .set('type', 'collection')
    .set('display_name', collection.name)
    .set('loading', false)
    .set('loaded', true);
  const hotelMap = createHotelsMap(collectionMap.get('hotels'));
  return state.setIn(
    ['items', code],
    collectionMap.set('hotels', hotelMap.sortBy(hotel => parseInt(hotel.get('lead_hotel_price') || 999999, 10)))
  );
};

const collectionFailed = (state, code) =>
  state.setIn(['items', code, 'loading'], false).setIn(['items', code, 'loaded'], false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case REQUEST_COLLECTION_LIST:
      return collectionListRequested(state);
    case REQUEST_COLLECTION_LIST_SUCCESS:
      return collectionListReceived(action.payload, state);
    case REQUEST_COLLECTION_LIST_FAILURE:
      return collectionListFailed(state);

    case REQUEST_COLLECTION:
      return collectionRequested(state, code);
    case REQUEST_COLLECTION_SUCCESS:
      return collectionReceived(action.payload, state, code);
    case REQUEST_COLLECTION_FAILURE:
      return collectionFailed(state, code);
    default:
      return state;
  }
}
