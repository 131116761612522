import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import Link from 'next/link';
import queryString from 'query-string';

const getTargetUrl = (path, query) => {
  const { cabin, check_in, check_out, origin, travelers, rooms } = query;
  const prefix = `/destinations/${path}`;
  const suffix = check_in ? `?${queryString.stringify({ cabin, check_in, check_out, origin, travelers, rooms })}` : '';
  return prefix + suffix;
};

const getTargetHref = path => {
  const destinationPath = path ? path.split('/hotels/')[0] : null;
  const hotelCode = path ? path.split('/hotels/')[1] : null;
  return { pathname: '/hotelPage', query: { destinationPath, hotelCode } };
};

const HotelLink = ({ query, hotel, className, children, onClick, rel }) => {
  const path = hotel.get('path');
  // HACK: null check needed to not create errors in a component loading situation
  // This is specific to cases where child component has a loading view
  const targetHref = path ? getTargetHref(path) : '/';
  const targetUrl = path ? getTargetUrl(path, query) : '/';

  return (
    <Link href={targetHref} as={targetUrl}>
      <a onClick={onClick} className={className} rel={rel}>
        {children}
      </a>
    </Link>
  );
};

HotelLink.defaultProps = {
  hotel: Immutable.Map(),
  query: {},
};

HotelLink.propTypes = {
  children: PropTypes.node,
  query: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  rel: PropTypes.string,
  hotel: PropTypes.instanceOf(Immutable.Map),
  onClick: PropTypes.func,
};

export default HotelLink;
