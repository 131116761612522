import Immutable from 'immutable';
import { flatDate } from 'components/Helpers';
import { decorateRates } from './rateDecorator';

export const getHotelRequestKey = (request) => {
  const checkIn = flatDate(request.get('checkIn') || request.get('departsOn'));
  const checkOut = flatDate(request.get('checkOut') || request.get('returnsOn'));
  const hotelCode = request.get('hotelCode');
  const travelerCount = request.get('travelerCount');
  const roomCount = request.get('roomCount');
  const key = `${hotelCode}|${checkIn}|${checkOut}|${travelerCount}|${roomCount}`;
  return key;
};

const getCompareRateForRoom = (room) => {
  const rates = room.get('rates');
  const rackRate = rates.find((rate) => rate.getIn(['package', 'type']) === 'rack');
  const publishedRate = rates.find((rate) => rate.getIn(['package', 'type']) === 'published');
  return rackRate || publishedRate;
};

const decorateRoomsWithCompareRate = (rooms, hotelCode, requestKey, received, isHotelOnly, isMember) =>
  rooms &&
  rooms.map((room) => {
    const compareRate = getCompareRateForRoom(room);
    // console.log('decorateRoomsWithCompareRate > room', room && room.toJS());
    const ratesWithVisibilityIndicator = decorateRates(
      room,
      room.get('rates'),
      compareRate,
      hotelCode,
      requestKey,
      received,
      isHotelOnly,
      isMember
    );

    const memberLeadRate = ratesWithVisibilityIndicator
      .filter((rate) => rate.get('visibility') === 'member')
      .sort((rate) => rate.getIn(['pricing', 'totalPrice']))
      .first();

    const visibleLeadRate = ratesWithVisibilityIndicator
      .filter((rate) => rate.get('visible'))
      .sort((rate) => rate.getIn(['pricing', 'totalPrice']))
      .first();

    const memberSavings = () => {
      if (!memberLeadRate || !visibleLeadRate) {
        return Immutable.Map({
          baseNightRate: 0,
          totalPrice: 0,
        });
      }
      const baseNightRate = memberLeadRate
        ? visibleLeadRate.getIn(['pricing', 'baseNightRate']) - memberLeadRate.getIn(['pricing', 'baseNightRate'])
        : 0;
      const totalPrice = memberLeadRate
        ? visibleLeadRate.getIn(['pricing', 'totalPrice']) - memberLeadRate.getIn(['pricing', 'totalPrice'])
        : 0;
      return Immutable.Map({
        baseNightRate,
        totalPrice,
      });
    };

    const visibleLeadTotalPrice = visibleLeadRate && visibleLeadRate.getIn(['pricing', 'totalPrice']);

    return room
      .set('compareRate', compareRate)
      .set('memberRate', memberLeadRate)
      .set('memberSavings', memberSavings())
      .set('visibleLeadPrice', visibleLeadTotalPrice)
      .set('rates', ratesWithVisibilityIndicator);
  });

const decorateRatesWithCheckInOutAndFreeNights = (rates, checkIn, checkOut) =>
  rates.map((rate) =>
    rate
      .set('checkIn', checkIn)
      .set('checkOut', checkOut)
      .set(
        'freeNights',
        rate.getIn(['nightlyRateBreakdown', 'USD']).filter((night) => night.get('before_tax_rate') === 0).size
      )
  );

const decorateRoomsWithCheckInOut = (rooms, checkIn, checkOut) =>
  rooms &&
  rooms.map((room) =>
    room.set('rates', decorateRatesWithCheckInOutAndFreeNights(room.get('rates'), checkIn, checkOut))
  );

export const decorateRooms = (rooms, request, received, isHotelOnly, isMember = false) => {
  const hotelCode = request && request.get('hotelCode');
  const checkIn = request && request.get('checkIn');
  const checkOut = request && request.get('checkOut');
  const decoratedRooms = decorateRoomsWithCheckInOut(rooms, checkIn, checkOut);
  // console.log('decoratesRooms > REQUEST', request && request.toJS());
  const requestKey = request && getHotelRequestKey(request);
  return decorateRoomsWithCompareRate(decoratedRooms, hotelCode, requestKey, received, isHotelOnly, isMember);
};
