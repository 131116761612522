import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 41 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_check">
      <path d="M34.7 0l5.9 5.8L14.3 32 0 17.8 5.9 12l8.4 8.3L34.7 0z"></path>
    </g>
  </svg>
);

export default Svg;

{
  /* <svg aria-hidden="true" role="presentation" focusable="false" class="bui-nav-progress__icon" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32">
<path class="bui_fill_white" d="M34.7 0l5.9 5.8L14.3 32 0 17.8 5.9 12l8.4 8.3L34.7 0z"></path>
</svg> */
}
