import React from 'react';
import PropTypes from 'prop-types';

export const AgentPhoneLink = ({ children, ...props }) => (
  <a {...props} className="link" href="tel:1-212-759-5275" rel="noopener noreferrer" target="_blank">
    {children}
  </a>
);

AgentPhoneLink.defaultProps = {
  children: '(212) SKYLARK',
};

AgentPhoneLink.propTypes = {
  children: PropTypes.node,
};

export const AgentChatLink = ({ children, ...props }) => (
  <a {...props} className="link" id="intercom_launcher_link" href="mailto:v7rfulc5@incoming.intercom.io">
    {children}
  </a>
);

AgentChatLink.defaultProps = {
  children: 'click here',
};

AgentChatLink.propTypes = {
  children: PropTypes.node,
};
