import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const Pill = styled.span`
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  padding: 2px 5px;
  text-transform: uppercase;
  margin-right: 5px;
`;

export const Member = styled(Pill)`
  background: ${colors.infoBlue};
  color: ${colors.white};
`;

export const Perks = styled(Pill)`
  background: ${colors.skylarkPurple};
  color: ${colors.white};
`;

export const Refund = styled(Pill)`
  background: ${colors.cautionYellow};
  color: ${colors.black};
`;

export const Savings = styled(Pill)`
  background: ${colors.posGreen};
  color: ${colors.white};
  margin-bottom: 5px;
`;

export default Pill;
