import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon-star" >
      <polygon id="Shape" points="24.906 9 15.468 9 12.546 0 9.575 9 0 9 7.757 14.455 4.844 23.317 12.492 17.753 20.171 23.261 17.262 14.411" />
    </g>
  </svg>
);

export default Svg;
