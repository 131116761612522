import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const height = 60;
const fontSize = 24;
const marginTop = 0;
const transSpeed = 0.4;
const bunWidth = 25;
const borderWidth = 2;
const txtPad = 30;

const vars = {
  height: `${height}px`, // Height (and width) of the burger menu
  marginTop: `${marginTop}px`, // Burger menu margin (distance from top)
  borderWidth: `${borderWidth}px`, // Width of the borders and 'buns'
  bunWidth: `${bunWidth}px`, // Width of the 'buns'
  bunHeight: `${borderWidth}px`, // Height of the 'buns'
  transSpeed, // Transition speed settings
  transition: `all ${transSpeed}s ease`, // Transitions
  fontSize: `${fontSize}px`, // Font size of brand and links
  txtPad: `${txtPad}px`, // Left padding on both brand and links
  padLeft: `${height / 2 - bunWidth / 2 - 2}px`, // Horizontally centers bun in menu
  padTop: `${height / 2 - bunWidth / 2}px`, // Vertically centers bun in menu
  foreground: colors.black,
  foregroundAlt: colors.white,
  foregroundOpen: colors.white,
  scrollForeground: colors.black,
};

const Styled = styled.div`
  &.b-container {
    height: ${vars.height};

    &.open {
      .b-bun--top {
        background: ${vars.foregroundOpen};
        top: 9px;
        transform: rotate(45deg);
      }

      .b-bun--mid {
        opacity: 0;
      }

      .b-bun--bottom {
        background: ${vars.foregroundOpen};
        top: 5px;
        transform: rotate(-45deg);
      }
    }
  }

  @media (min-width: 1025px) {
    display: none;
  }

  .b-menu {
    cursor: pointer;
    display: inline-block;
    height: ${vars.height};
    padding-left: ${vars.padLeft};
    padding-top: ${vars.padTop};
    position: relative;
    transition: ${vars.transition};
    user-select: none;
    z-index: 12;
  }

  .b-bun {
    background: ${vars.foreground};
    position: relative;
    transition: ${vars.transition};

    &--top {
      height: ${vars.bunHeight};
      top: 0;
      width: ${vars.bunWidth};
    }

    &--mid {
      height: ${vars.bunHeight};
      top: 8px;
      width: ${vars.bunWidth};
    }

    &--bottom {
      height: ${vars.bunHeight};
      top: 16px;
      width: ${vars.bunWidth};
    }
  }

  &.is-home {
    .b-bun {
      background: ${vars.foregroundAlt};
      transition: background 0.1s;
    }
  }

  &.is-scrolled {
    .b-bun {
      background: ${vars.scrollForeground};
    }
    .b-container {
      &.open {
        .b-bun--top,
        .b-bun--mid,
        .b-bun--bottom {
          background: ${vars.scrollForeground};
        }
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translate3d(-250px, 0, 0);
      visibility: visible;
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-250px, 0, 0);
      visibility: hidden;
    }
  }

  * {
    box-sizing: border-box;
  }
`;

export default Styled;
