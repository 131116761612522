import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { Highlight, Pluralize } from 'components/Helpers';
import { IconPlane, IconHotel, IconStar, IconMarker } from 'components/svg';

const compact = arr => arr.filter(obj => obj);

const hotelDisplay = (hotel, showDetails) => {
  const details = showDetails && `- ${hotel.location_description}`;
  return compact([hotel.name, details]).join(' ');
};

const destinationDisplay = destination => {
  const { keywordMatch, description, title, name, city } = destination;
  const displayName = description || title || name || city;
  if (keywordMatch) {
    return `${displayName}, ${keywordMatch}`;
  }
  return displayName;
};

const collectionDisplay = (collection, showDetails) => {
  const details = showDetails && 'Skylark Collection -';
  return compact([details, collection.name]).join(' ');
};

const airportDisplay = airport => {
  const name = airport.name && airport.name.replace('International', 'Intl.');
  const keyword = airport.keyword && `${airport.keyword},`;
  const codeDisplay = airport.code && `(${airport.code})`;
  return compact([codeDisplay, keyword, name]).join(' ');
};

const itemDisplay = (item, showDetails) => {
  switch (item.type) {
    case 'hotel':
      return hotelDisplay(item, showDetails);
    case 'airport':
      return airportDisplay(item);
    case 'destination':
      return destinationDisplay(item);
    case 'collection':
      return collectionDisplay(item, showDetails);
    default:
      return null;
  }
};

const searchTypeIcon = {
  airport: <IconPlane width="13" />,
  hotel: <IconHotel width="13" />,
  collection: <IconStar width="13" />,
  destination: <IconMarker width="13" />,
};

const OptionIcon = ({ type }) => <span className="option-icon">{searchTypeIcon[type]}</span>;

OptionIcon.propTypes = {
  type: PropTypes.string,
};

export const SingleValue = ({ data, ...otherProps }) => {
  const item = data;
  const searchVal = '';
  const display = itemDisplay(item);
  return (
    <components.SingleValue {...otherProps}>
      <div className="option-content-wrapper">
        <OptionIcon type={item.type} />
        <div className="option-content">
          <Highlight searchVal={searchVal}>{display || ''}</Highlight>
        </div>
      </div>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.instanceOf(Object),
};

const SearchOption = ({ data, ...otherProps }) => {
  const item = data;
  const searchVal = data.search_term;
  const display = itemDisplay(item, true);

  return (
    <components.Option {...otherProps}>
      <div className="option-content-wrapper">
        <OptionIcon type={item.type} />
        <div className="option-content">
          <Highlight searchVal={searchVal}>{display || ''}</Highlight>
          {!!item.hotel_count && (
            <Pluralize className="option-hotel-count" count={parseInt(item.hotel_count, 10)}>
              Hotel
            </Pluralize>
          )}
        </div>
      </div>
    </components.Option>
  );
};

SearchOption.propTypes = {
  data: PropTypes.instanceOf(Object),
  hideCount: PropTypes.bool,
};

export default SearchOption;
