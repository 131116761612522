import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Content.styled';

const ContentRow = ({ children, center, ...props }) => (
  <Styled.ContentRow center={center} {...props}>
    {children}
  </Styled.ContentRow>
);

ContentRow.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
};

export default ContentRow;
