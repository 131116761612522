import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import Link from 'next/link';
import queryString from 'query-string';

const CollectionLink = ({ collection, query, className, children, rel, onClick }) => {
  const { cabin, check_in, check_out, origin, travelers, rooms } = query;

  // const targetHref = { pathname: 'collections/[id]', query: { id: collection.get('slug') } };
  let targetUrl = `/collections/${collection.get('slug')}`;
  if (check_in) {
    targetUrl += `?${queryString.stringify({ cabin, check_in, check_out, origin, travelers, rooms })}`;
  }

  return (
    collection.get('id') && (
      <Link href={targetUrl} prefetch={false}>
        <a onClick={onClick} className={className} rel={rel}>
          {children}
        </a>
      </Link>
    )
  );
};

CollectionLink.defaultProps = {
  query: {},
};

CollectionLink.propTypes = {
  collection: PropTypes.instanceOf(Immutable.Map),
  query: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  children: PropTypes.node,
  rel: PropTypes.string,
  onClick: PropTypes.func,
};

export default CollectionLink;
