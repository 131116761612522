import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

const API_BASE = CONTENT_API_ENDPOINT;

const REQUEST_CURRENCY = 'skylark/settings/REQUEST_CURRENCY';
const REQUEST_CURRENCY_SUCCESS = 'skylark/settings/REQUEST_CURRENCY_SUCCESS';
const REQUEST_CURRENCY_FAILURE = 'skylark/settings/REQUEST_CURRENCY_FAILURE';

const initialState = Immutable.fromJS({
  currencies: {
    USD: {
      conversion_rate: 1.0,
      symbol: '$',
      name: 'United States Dollar',
      subunit: 'Cent',
      subunit_to_unit: 100,
      decimal_mark: '.',
      thousands_separator: ',',
    },
  },
});

// ACTION CREATE HELPER METHODS

const shouldBailout = (state, currencyCode) => {
  const doBailout = !!state.currency.getIn(['currencies', currencyCode]) || !currencyCode;
  return doBailout;
};

// ACTION CREATORS
export function getCurrency(currencyCode = '') {
  const endpoint = `${API_BASE}/currency/${currencyCode}`;
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        { type: REQUEST_CURRENCY, meta: { currencyCode } },
        { type: REQUEST_CURRENCY_SUCCESS, meta: { currencyCode } },
        { type: REQUEST_CURRENCY_FAILURE, meta: { currencyCode } },
      ],
      bailout: state => shouldBailout(state, currencyCode),
    },
  };
}

// REDUCER HELPER METHODS

const requestCurrency = (state, currencyCode) =>
  state
    .setIn(['currencies', currencyCode, 'isLoading'], true)
    // .setIn(['currencies', currencyCode, 'requestStartTime'], moment())
    .setIn(['currencies', currencyCode, 'isLoaded'], state.getIn(['currencies', currencyCode, 'isLoaded']) || false);

const requestCurrencySuccess = (state, payload) => {
  const currency = Immutable.fromJS(payload)
    .set('isLoading', false)
    .set('isLoaded', true);
  // console.log('INCOMING CURRENCY', currency ? currency.toJS() : null)
  const currencyCode = currency.get('code');
  return state.mergeIn(['currencies', currencyCode], currency);
};

const requestCurrencyFailure = (state, currencyCode) =>
  state.setIn(['currencies', currencyCode, 'isLoading'], false).setIn(['currencies', currencyCode, 'isLoaded'], false);

const reducer = (state = initialState, action = {}) => {
  // console.log('ACTION', action)
  // const currencyCode = action.meta ? action.meta.currencyCode : null;
  const currencyCode = action.currencyCode || (action.meta ? action.meta.currencyCode : null);
  switch (action.type) {
    case REQUEST_CURRENCY:
      return requestCurrency(state, currencyCode);
    case REQUEST_CURRENCY_SUCCESS:
      return requestCurrencySuccess(state, action.payload);
    case REQUEST_CURRENCY_FAILURE:
      return requestCurrencyFailure(state, currencyCode);
    default:
      return state;
  }
};

export default reducer;
