import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Content.styled';

const ContentContainer = ({ children, center, ...props }) => (
  <Styled.ContentContainer center={center} {...props}>
    {children}
  </Styled.ContentContainer>
);

ContentContainer.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
};

export default ContentContainer;
