import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';
import SiteContainer from '../SiteContainer/SiteContainer';

export const HeroContent = styled(SiteContainer)`
  display: flex;
  & > * {
    flex: 1;
  }

  text-shadow: 0px 0px 35px ${toRgba(colors.black, 1)}, 0px 0px 3px ${toRgba(colors.black, 0.75)};

  @media (max-width: ${variables.screen.xs}) {
    display: block;
    text-align: center;
  }

  h2,
  h3 {
    font-family: ${variables.fontFamily.display};
    margin-bottom: 0;
    @media (max-width: ${variables.screen.xs}) {
      br {
        display: none;
      }
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    @media (max-width: ${variables.screen.xs}) {
      font-size: 1.25rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    @media (max-width: ${variables.screen.xs}) {
      font-size: 1rem;
    }
  }
`;
