import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import * as Input from 'components/Forms/Input';
import { Button } from 'components/UI';

import Events from 'constants/events';

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Must be at least 6 characters'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

export const PasswordForm = ({
  headerText,
  buttonText,
  serverErrors,
  className,
  loading,
  disabled,
  onSubmit,
  children,
}) => {
  const baseClass = classNames({ loading }, 'sign-in-form-component', className);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <div className={baseClass}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex-form">
        {headerText && <h4>{headerText}</h4>}
        <div>
          <Input.Password
            name="password"
            label="Password"
            autoComplete="password"
            error={errors?.password}
            disabled={disabled}
            register={register}
          />
          <Input.Password
            name="passwordConfirm"
            label="Confirm Password"
            autoComplete="password"
            error={errors?.passwordConfirm}
            disabled={disabled}
            register={register}
          />
        </div>

        <div>
          {serverErrors.size > 0 && (
            <ul className="form-errors">
              {serverErrors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
          {children}
        </div>

        <Button
          event={Events.USER_UPDATED_PASSWORD}
          context="sign in form"
          type="submit"
          disabled={loading || disabled}
          className="button-cta"
        >
          {buttonText}
        </Button>
      </form>
    </div>
  );
};

PasswordForm.defaultProps = {
  buttonText: 'Update Password',
  serverErrors: Immutable.List(),
  onSubmit: v => console.log('PasswordForm > onSubmit', v),
};

PasswordForm.propTypes = {
  className: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  serverErrors: PropTypes.instanceOf(Immutable.List),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default PasswordForm;
