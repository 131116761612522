import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const Styled = styled.div`
  background-color: ${colors.white};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 21px auto;
  background-image: url(${props => props.imageUrl});
  border: 1px solid ${colors.coolGray};
  color: transparent;
  height: 25px;
  position: relative;
  width: 25px;
  z-index: 2;
  &:nth-child(2) {
    position: absolute;
    height: 20px;
    width: 20px;
    background-size: 18px auto;
    bottom: -2px;
    right: 13px;
  }
  &:nth-child(3) {
    position: absolute;
    height: 20px;
    width: 20px;
    background-size: 18px auto;
    bottom: -7px;
    right: 8px;
  }
`;

export default Styled;
