import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import classNames from 'classnames';
import Link from 'next/link';
import Events from 'constants/events';

const ActiveLink = ({ children, href, activeClassName, ...otherProps }) => {
  const router = useRouter();
  const tracking = useTracking();
  const child = Children.only(children);
  const isActive = router && router.pathname === href;
  const className = classNames(child.props.className, {
    [activeClassName]: isActive && activeClassName,
  });

  const onMouseUp = () => {
    tracking.trackEvent({
      event: Events.LINK_CLICKED,
      properties: {
        content: child.props.children,
        target: href,
      },
    });
  };

  return (
    <Link href={href} {...otherProps}>
      {React.cloneElement(child, { className, onMouseUp })}
    </Link>
  );
};

ActiveLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default ActiveLink;
