import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const Styled = styled.div`
  h4 {
    padding: 1em 2em;
    /* margin: 0 0; */
    border: 1px solid ${toRgba(colors.green, 0.5)};
    background: ${toRgba(colors.green, 0.25)};
  }
`;

export default Styled;
