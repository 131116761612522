import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Content.styled';

const ContentSection = ({ children, alternate, padded, ...props }) => (
  <Styled.ContentSection alternate={alternate} padded={padded} {...props}>
    {children}
  </Styled.ContentSection>
);

ContentSection.defaultProps = {
  padded: true,
};

ContentSection.propTypes = {
  alternate: PropTypes.bool,
  padded: PropTypes.bool,
  children: PropTypes.node,
};

export default ContentSection;
