import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import Link from 'next/link';
import queryString from 'query-string';

const DestinationLink = ({ destination, destinationPath, query, className, children, rel, onClick }) => {
  const { cabin, check_in, check_out, origin, travelers, rooms } = query;

  const path = destinationPath || (destination && destination.get('path'));
  const targetHref = { pathname: '/destinations', query: { destinationPath: path } };

  let targetUrl = path ? `/destinations/${path}` : '/destinations';
  if (check_in) {
    targetUrl += `?${queryString.stringify({ cabin, check_in, check_out, origin, travelers, rooms })}`;
  }

  return (
    <Link href={targetHref} as={targetUrl} prefetch={false}>
      <a onClick={onClick} className={className} rel={rel}>
        {children}
      </a>
    </Link>
  );
};

DestinationLink.defaultProps = {
  query: {},
};

DestinationLink.propTypes = {
  destination: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.Map), PropTypes.instanceOf(Immutable.List)]),
  destinationPath: PropTypes.string,
  query: PropTypes.any,
  className: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default DestinationLink;
