import { useDispatch, useSelector } from 'react-redux';
import { submitProspect, closeForm } from 'modules/prospect';

const useProspect = () => {
  const dispatch = useDispatch();
  const prospect = useSelector(state => state.prospect);


  const clientConfig = useSelector((state) => state.clientConfig)?.toJS();
  const browser_session_id = clientConfig?.browser_session_id;

  console.log('useProspect > clientConfig', clientConfig);
  // console.log('useProspect > prospect', prospect);
  const signUp = (firstName, lastName, email) => dispatch(submitProspect(firstName, lastName, email, browser_session_id));
  const onFormClose = () => dispatch(closeForm());
  return [prospect, signUp, onFormClose];
};

export default useProspect;
