import Immutable from 'immutable';
import axios from 'axios';

import { AuthHeaders } from 'modules/authentication/authentication';

import { AUTH_API_ENDPOINT } from 'constants/endpoints';

export const initialState = Immutable.fromJS({ items: [], loading: false, loaded: false });

// ACTION TYPES
const REQUEST = 'skylark/referrals/REQUEST';
const SUCCESS = 'skylark/referrals/SUCCESS';
const FAILURE = 'skylark/referrals/FAILURE';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';

export const fetchReferrals = () => async dispatch => {
  const resourceUrl = `${AUTH_API_ENDPOINT}/referrals`;
  dispatch({ type: REQUEST });

  try {
    const results = await axios.get(resourceUrl, { headers: AuthHeaders() });
    dispatch({ type: SUCCESS, payload: results.data });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({ type: FAILURE, payload: respStatus });
  }
};

// REDUCER HELPER METHODS
const requested = state => state.set('loading', true).set('loaded', false);

const received = (state, action) =>
  state
    .set('items', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true);

const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    case AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
