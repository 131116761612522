import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styled from './styled';

const MiniCarrierIcon = ({ code, className }) => {
  const baseClass = classNames('mini-carrier-icon-component', className);
  const imageUrl = `https://s1.travix.com/global/assets/airlineLogos/${code}_mini.png`;
  return code ? <Styled className={baseClass} imageUrl={imageUrl} /> : null;
};

MiniCarrierIcon.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string,
};

export default MiniCarrierIcon;
