import moment from 'moment';

export const toDate = date => (date ? moment(date) : null);

export const dateFormat = (date, format = 'ddd, MMM D') => (date ? moment.parseZone(date).format(format) : null);

export const urlDate = date => (moment(date).isValid() ? moment.parseZone(date).format('YYYY-MM-DD') : null);

export const flatDate = date => (date ? moment.parseZone(date).format('YYYYMMDD') : null);

export const dateMatch = (date1, date2) => flatDate(date1) === flatDate(date2);

export const isPast = date => date && moment(date) < moment();

export const dateShift = (date1, date2) => {
  const start = moment(date1).startOf('day');
  const end = moment(date2).startOf('day');
  return end.diff(start, 'days');
};

export const duration = (start, end) => dateShift(moment(start), moment(end));

export const localTime = timeStamp => moment.parseZone(timeStamp).format('h:mma');

export const offsetDate = (date, days) => moment(date).add(days, 'd');

export const timeAgoInWords = time => {
  const gmtHourOffset = moment().utcOffset() / 60;
  const runAt = moment(time).add(gmtHourOffset, 'hours');
  let output = runAt
    .fromNow()
    .replace('a few seconds ago', 'Just now')
    .replace('a minute ago', 'Just now')
    .replace('a day ago', 'Yesterday');
  output = output.includes('hours') ? 'Today' : output;
  output = output.includes('minutes') ? 'Minutes ago' : output;
  return output;
};

export const DateFormat = ({ children, format }) => dateFormat(children, format);
export const CheckInDate = ({ children }) => dateFormat(children);
export const CheckOutDate = ({ children }) => dateFormat(children, 'ddd, MMM D, YYYY');
