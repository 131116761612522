const Events = {
  SITE_VISIT: 'Site Visit',
  TRIP_SEARCH_CHANGED: 'Trip Search Changed',
  TRIP_INQUIRY_REQUESTED: 'Trip Inquiry Made',
  SEARCH_CONTROL_OPENED: 'Search Control Opened',
  SEARCH_CONTROL_CLOSED: 'Search Control Closed',

  USER_CLICKED_SIGN_IN: 'User Clicked Sign In',
  USER_CLICKED_SIGN_UP: 'User Clicked Sign Up',

  USER_IDENTIFIED: 'User Identified',
  USER_SIGNED_IN: 'User Signed In',
  USER_REGISTERED: 'User Registered',
  USER_PROFILE_UPDATE: 'User Updated Profile',
  USER_PROFILE_UPDATED: 'User Profile Updated',
  USER_PROFILE_UPDATE_FAILED: 'User Profile Update Failed',
  USER_SIGN_IN_FAILED: 'User Sign In Failed',
  USER_REGISTRATION_FAILED: 'User Reistration Failed',
  USER_PASSWORD_UPDATED: 'Password Changed',
  USER_PASSWORD_UPDATE_FAILED: 'Password Change Failed',
  USER_SIGNED_OUT: 'User Signed Out',
  PROFILE_NOT_FOUND: 'User Profile not Found',
  USER_ACTIVATE_ACCOUNT: 'Account Activation Submitted',
  USER_CHANGE_PASSWORD: 'Password Change Submitted',

  // TODO: Merge below into a single call
  REQUEST_PASSWORD_RESET: 'Request Password Reset',
  REQUESTED_PASSWORD_RESET_SENT: 'User Requested Password Reset',
  REQUESTED_PASSWORD_RESET_FAILED: 'User Requested Password Reset Failed',
  USER_UPDATED_PASSWORD: 'User Updated Password',

  CARD_CLICKED: 'Card Clicked',

  TRIP_WATCH_TOGGLED: 'Trip Watch Toggled',
  TRIP_WATCH_FORM_SUBMITTED: 'Trip Watch form Submitted',

  HOTEL_VIEWED: 'Hotel Viewed',

  LINK_CLICKED: 'Link Clicked',
  CONTENT_TILE_CLICKED: 'Content Tile Clicked',
  HOTEL_TILE_CLICKED: 'Hotel Tile Clicked',
  MAP_POINT_CLICKED: 'Map Point Clicked',
  WATCH_LIST_TRIP_CLICKED: 'Watch List Trip Clicked',

  HOTEL_RATES_RETURNED: 'Hotel Rates Returned',
  HOTEL_RATES_NONE_RETURNED: 'No Hotel Availability Returned',
  BUNDLE_RATES_RETURNED: 'Bundle Rates Returned',
  BUNDLE_RATES_NONE_RETURNED: 'No Bundle Rates Returned',

  RATE_SELECTION_CLICKED: 'Rate Selection Clicked',
  LEAD_HOTEL_STAY_CLICKED: 'Lead Hotel Stay Clicked',

  BOOK_HOTEL_CLICKED: 'Book Hotel Clicked',
  BOOK_BUNDLE_CLICKED: 'Book Bundle Clicked',
  BOOKING_SUBMIT_TRAVELER_FORM: 'Booking - Traveler form Submitted',
  BOOKING_SUBMIT_PAYMENT_FORM: 'Booking - Payment form Submitted',
  BOOKING_COMPLETE: 'Booking Complete',
  CONVERSION_TAG_SENT: 'Conversion Tag sent to Google',

  ACCESS_DENIED: 'Access Denied',

  SELECT_ITINERARY: 'Select itinerary',

  DESTINATION_SEARCH_NO_RESULTS: 'Destination Search Returned Empty',
  PROSPECT_SIGNUP_SUCCESS: 'Prospect Signed Up',
  PROSPECT_SIGNUP_FAILED: 'Prospect Signed Up Failed',
};

export default Events;
