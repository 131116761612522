import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const Styled = styled.div`
  flex: 1 !important;
  display: flex;
  & > div {
    flex: 1;
  }
  a,
  button {
    white-space: nowrap;
  }

  &.is-home {
    span,
    a,
    button.link {
      font-weight: 500;
      color: ${colors.white} !important;
    }
    &.is-scrolled {
      span,
      a,
      button.link {
        font-weight: 500;
        color: ${colors.black} !important;
      }
    }
  }

  .identity-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    & > * {
      display: inline-block;
    }
    .seperator {
      color: ${colors.black};
      padding: 0 5px;
    }
    button {
      margin: 0;
    }
  }

  a.activate-button {
    height: 30px;
    padding: 5px 10px;
    font-weight: 500;
    &:hover {
      background: ${colors.skylarkPurple};
      color: white;
      border-radius: 15px;
    }
  }

  .join-now {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  a.start-planning-button {
    margin-right: 10px;
    @media (max-width: 900px) {
        display: none;
    }
  }

  .burger-button {
    z-index: 99;
    display: none;
  }

  .membership-badge {
    background: ${colors.skylarkPurple};
    border-radius: 50%;
    width: 19px;
    height: 19px;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    vertical-align: bottom;
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    .identity-column {
      display: none;
    }
    .burger-button {
      display: block;
      float: right;
    }
  }
`;

export default Styled;
