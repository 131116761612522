import React from 'react';

export const Svg = props => (
  <svg {...props} viewBox="0 0 48 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="0" fill="none" fillRule="evenodd">
      <g id="icon-calendar" stroke="black">
        <path d="M1,1 L1,39 L47,39 L47,1 L1,1 Z" id="Rectangle" strokeWidth="2" />
        <path d="M1.5,11 L46.5,11 L1.5,11 Z" id="Line" strokeWidth="2" strokeLinecap="square" />
        <path strokeWidth="1" d="M14.5,18.5 L14.5,19.5 L15.5,19.5 L15.5,18.5 L14.5,18.5 Z M20.5,18.5 L20.5,19.5 L21.5,19.5 L21.5,18.5 L20.5,18.5 Z M26.5,18.5 L26.5,19.5 L27.5,19.5 L27.5,18.5 L26.5,18.5 Z M32.5,18.5 L32.5,19.5 L33.5,19.5 L33.5,18.5 L32.5,18.5 Z M8.5,24.5 L8.5,25.5 L9.5,25.5 L9.5,24.5 L8.5,24.5 Z M14.5,24.5 L14.5,25.5 L15.5,25.5 L15.5,24.5 L14.5,24.5 Z M20.5,24.5 L20.5,25.5 L21.5,25.5 L21.5,24.5 L20.5,24.5 Z M26.5,24.5 L26.5,25.5 L27.5,25.5 L27.5,24.5 L26.5,24.5 Z M38.5,18.5 L38.5,19.5 L39.5,19.5 L39.5,18.5 L38.5,18.5 Z M32.5,24.5 L32.5,25.5 L33.5,25.5 L33.5,24.5 L32.5,24.5 Z M38.5,24.5 L38.5,25.5 L39.5,25.5 L39.5,24.5 L38.5,24.5 Z M8.5,30.5 L8.5,31.5 L9.5,31.5 L9.5,30.5 L8.5,30.5 Z M14.5,30.5 L14.5,31.5 L15.5,31.5 L15.5,30.5 L14.5,30.5 Z M20.5,30.5 L20.5,31.5 L21.5,31.5 L21.5,30.5 L20.5,30.5 Z M26.5,30.5 L26.5,31.5 L27.5,31.5 L27.5,30.5 L26.5,30.5 Z" id="Combined-Shape" />
      </g>
    </g>
  </svg>
);

export default Svg;
