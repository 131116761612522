import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FlightDuration from './FlightDuration';

const timeBetween = (s, e) => {
  const start = moment.utc(s);
  const end = moment.utc(e);
  const diff = end.diff(start, 'minutes');
  return diff.toString();
};

export class TimeBetween extends React.PureComponent {
  render() {
    return (
      <FlightDuration minutes={timeBetween(this.props.start, this.props.end)} />
    );
  }
}

TimeBetween.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
};

export default TimeBetween;
