import styled from 'styled-components';
import { colors } from 'styles';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  &,
  th,
  td {
    border: 1px solid ${colors.coolGray};
    border-left: none;
    border-right: none;
  }

  th,
  td {
    text-align: left;
    padding: 0.25rem 0.5rem;
    &.right {
      text-align: right;
    }
  }

  th {
    background: ${colors.warmGray};
    padding: 0.5rem;
  }
`;

export default Table;
