import styled from 'styled-components';
import { colors } from 'styles';

const Styled = styled.div`
  opacity: 0;
  width: 0px;
  height: 60px;
  border-left: 1px solid ${colors.coolGray};
  color: ${colors.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-right: 50px;
  transition: all 0.1s ease;
  .icon {
    fill: ${colors.warmGray};
    margin-left: 15px;
  }
  .placeholder {
    margin-left: 15px;
  }

  &.is-scrolled {
    opacity: 1;
    width: 180px;
    transition: all 0.5s ease 0.25s;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export default Styled;
