import styled from 'styled-components';
import theme from 'config/theme';

const { colors, rgba } = theme;

export const Container = styled.div`
  /* border: 3px solid lime; */
  min-height: 120px;
  position: relative;
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${colors.white};

  .hero-image-header {
    width: 100%;
    z-index: 1;
  }

  .hero-image-body {
    width: 100%;
  }

  .hero-image-footer {
    width: 100%;
  }

  .hero-image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: 50% 50%;
    background-size: cover;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: ' ';
      z-index: 1;
      background-image: linear-gradient(
        ${rgba(colors.link, 0.2)},
        ${rgba(colors.black, 0)} 40%,
        ${rgba(colors.black, 0.4)}
      );
    }
  }

  & > * {
    z-index: 0;
    &.hero-image-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
  }
`;
