import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

const Styled = styled.button`
  &:disabled {
    opacity: 0.5;
  }
  &.link {
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgb(0, 0, 238);
    border-style: none;
    box-sizing: content-box;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
    width: auto;
    outline: none;
    appearance: none;
  }

  &.button-inline {
    margin: 0;
  }

  &.button-small {
    font-size: 75%;
    padding: 5px 8px;
  }

  &.button-form {
    font-weight: 400;
    padding: 5px 8px;
    &[type='submit'] {
      background: ${colors.skylarkPurple};
      color: ${colors.white};
    }
  }

  &.button-form {
    padding: 0.25rem 0.75rem;
    line-height: 1.5;
  }

  &.button-info {
    font-weight: normal;
    border: 1px solid ${colors.coolGray};
  }

  /* Mozilla uses a pseudo-element to show focus on buttons, */
  /* but anchors are highlighted via the focus pseudo-class. */

  @supports (-moz-appearance: none) {
    /* Mozilla-only */
    &.link::-moz-focus-inner {
      /* reset any predefined properties */
      border: none;
      padding: 0;
    }
    &.link:focus {
      /* add outline to focus pseudo-class */
      outline-style: dotted;
      outline-width: 1px;
    }
  }
`;

export default Styled;
