import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_user">
      <path d="M22,17.208 C21.356,16.842 17.157,14.357 15,13.484 L15,9.707 C15.624,9.251 16,8.549 16,7.484 L16,4.5 C16,2.025 14.5,0 11.5,0 C8.5,0 7,2.025 7,4.5 L7,7.484 C7,8.559 7.376,9.263 8,9.717 L8,13.484 C5.968,14.357 1.644,16.841 1,17.208 C0.199,17.663 0,18.096 0,19.148 L0,21.5 C0,21.775 0.225,22 0.5,22 L22.5,22 C22.775,22 23,21.775 23,21.5 L23,19.148 C23,18.096 22.801,17.663 22,17.208 Z" />
    </g>
  </svg>
);

export default Svg;
