import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { dimensions } from 'config/theme';

export { default as Text } from './TextInput';
export { default as Hidden } from './HiddenInput';
// export { default as Select } from './SelectInput';

const selectStyles = hasError => ({
  dropdownIndicator: provided => {
    const padding = '7px';
    return { ...provided, padding };
  },
  control: provided => {
    const minHeight = dimensions.formInput;
    const border = hasError ? '1px solid red' : provided.border;
    return { ...provided, minHeight, border };
  },
});

const SelectWrapper = ({ name, options, control, error, validateOnChange, ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      // const { autoComplete = rest.autoComplete } = rest.selectProps;
      // console.log('SelectWrapper > ref', ref);
      // console.log('SelectWrapper > name', name);
      // console.log('...rest', { ...rest });
      <ReactSelect
        className={classNames('select', { 'field-input-error': !!error })}
        inputId={name}
        styles={selectStyles(!!error)}
        options={options}
        value={options.find(option => option.value === value)}
        onBlur={onBlur}
        onChange={v => onChange(v.value)}
        {...rest}
      />
    )}
  />
);

SelectWrapper.propTypes = {
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  control: PropTypes.instanceOf(Object),
  validateOnChange: PropTypes.instanceOf(Array),
  error: PropTypes.instanceOf(Object),
};

export default SelectWrapper;
