import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const Styled = styled.div`
  position: relative;
  align-items: center;
  justify-content: start;
  display: flex;
  gap: 15px;
  flex: 1 !important;
  transition: transform 0.4s ease;
  transition-delay: 0s;
  /* border: 1px solid lime; */

  & > * {
    /* border: 1px solid orange; */
  }

  span,
  a,
  button.link {
    font-weight: 500;
    color: ${colors.black} !important;
  }

  &.is-home {
    span,
    a,
    button.link {
      font-weight: 500;
      color: ${colors.white} !important;
    }
    &.is-scrolled {
      span,
      a,
      button.link {
        font-weight: 500;
        color: ${colors.black} !important;
      }
    }
  }

  & > div {
    /* padding: 0 15px; */
    a {
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1024px) {
    .hide-1 {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .hide-2 {
      display: none;
    }
  }
`;

export default Styled;
