import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconEye } from 'components/svg';
import FormFieldWrapper from '../FormFieldWrapper';
import * as Styled from './Input.styled';

const PasswordInput = ({ name, label, hint, error, register, control, errorPosition, flex, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fieldType = showPassword ? 'text' : 'password';

  return (
    <>
      <FormFieldWrapper label={label} error={error} flex={flex} errorPosition={errorPosition} hint={hint}>
        <Styled.PasswordInputWrapper>
          <input
            type={fieldType}
            name={name}
            className={classNames({ 'field-input-error': !!error })}
            control={control}
            {...register(name)}
            {...props}
          />
          <Styled.PasswordVisibilityIcon
            visible={showPassword}
            title={showPassword ? 'Hide password' : 'Show password'}
            onClick={toggleVisibility}
          >
            <IconEye width={20} />
          </Styled.PasswordVisibilityIcon>
        </Styled.PasswordInputWrapper>
      </FormFieldWrapper>
    </>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  errorPosition: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  flex: PropTypes.number,
};

export default PasswordInput;
