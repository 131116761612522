import Immutable from 'immutable';
import {
  getAvailabilityForItinerary,
  getRoomAvailabilitiesByCheckInDate,
  getTripRequestKey,
  getLeadItinerary,
} from 'selectors/hotelTripSelector';

export const getBundleRequestDefinition = (
  hotel,
  checkIn,
  checkOut,
  originCode,
  destinationCode,
  passengers,
  rooms,
  cabin
) => {
  const request = Immutable.Map({
    hotelCode: hotel.get('code'),
    originCode,
    destinationCode: destinationCode || hotel.get('city_code'),
    cabinClass: cabin,
    departsOn: checkIn,
    returnsOn: checkOut,
    travelerCount: passengers,
    roomCount: rooms,
    hotelOnly: false,
  });
  return request.set('key', getTripRequestKey(request));
};

export const getBundleTripLeadResult = (request, response) => {
  const { travelerCount, roomCount } = request.toJS();
  const leadItinerary = getLeadItinerary(response);
  const roomsByDate = getRoomAvailabilitiesByCheckInDate(request, response);

  const availabilityForItinerary = getAvailabilityForItinerary(roomsByDate, leadItinerary);
  const leadRoom = availabilityForItinerary && availabilityForItinerary.getIn(['rooms', 0]);

  const leadItinerarySalePrice = leadItinerary && leadItinerary.get('adjusted_fare');
  const leadItineraryComparePrice = leadItinerary && leadItinerary.get('compare_fare');
  const leadRate = leadRoom && leadRoom.get('rates').first();
  const leadHotelOnlyRate = leadRoom && leadRoom.get('compareRate');
  const leadRoomSalePrice = leadRate && leadRate.getIn(['pricing', 'totalPrice']);
  const leadRoomComparePrice = leadRoom && leadRoom.getIn(['compareRate', 'pricing', 'totalPrice']);

  const leadSalePrice = leadItinerarySalePrice * travelerCount + leadRoomSalePrice * roomCount;
  const leadComparePrice = leadItineraryComparePrice * travelerCount + leadRoomComparePrice * roomCount;

  const freeNights =
    leadRate &&
    leadRate.getIn(['nightlyRateBreakdown', 'USD']).filter(night => night.get('before_tax_rate') === 0).size;

  const amenities = leadRate && leadRate.get('amenities');
  const amenitiesValue = leadRate && leadRate.get('amenitiesValue');

  const leadTripSavings = leadComparePrice - leadSalePrice;
  const leadTripPercentSavings = (leadTripSavings / leadComparePrice) * 100;

  return Immutable.fromJS({
    itinerary: leadItinerary,
    room: leadRoom,
    rate: leadRate,
    hotelOnlyRate: leadHotelOnlyRate,
    freeNights,
    amenities: { value: amenitiesValue, items: amenities },
    bundleSavings: leadTripSavings,
    percentSavings: Number.isNaN(leadTripPercentSavings) ? null : leadTripPercentSavings,
    pricing: {
      salePrice: leadSalePrice,
      comparePrice: leadComparePrice,
      percentSavings: Number.isNaN(leadTripPercentSavings) ? null : leadTripPercentSavings,
    },
  });
};

const getAvailablePairRates = rooms => {
  const availableRates = rooms && rooms.map(room => room.get('rates')).flatten(true);
  return availableRates;
};

export const getHotelRateByProductCode = (rooms, selectedProductCode) => {
  const rates = getAvailablePairRates(rooms);
  const leadProduct = rates && rates.sortBy(p => p.getIn(['pricing', 'totalPrice'])).first();
  const currentProduct = rates && rates.find(rate => rate.get('productCode') === selectedProductCode);
  return currentProduct || leadProduct || Immutable.Map();
};
