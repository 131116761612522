import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-clock">
        <path d="M12,0 C5.383,0 0,5.383 0,12 C0,18.617 5.383,24 12,24 C18.617,24 24,18.617 24,12 C24,5.383 18.617,0 12,0 Z M12,21 C7.029,21 3,16.971 3,12 C3,7.029 7.029,3 12,3 C16.971,3 21,7.029 21,12 C21,16.971 16.971,21 12,21 Z M13,10.277 L13,5.515 C13,5.24 12.758,5 12.483,5 L11.501,5 C11.227,5 11.001,5.24 11.001,5.516 L11,10.277 C10.7,10.452 10.452,10.7 10.277,11 L7.5,11 C7.225,11 7,11.273 7,11.548 L7,12.5 C7,12.775 7.225,13 7.5,13 L10.277,13 C10.623,13.595 11.261,14 12,14 C13.105,14 14,13.104 14,12 C14,11.262 13.595,10.624 13,10.277 Z" id="Shape"></path>
    </g>
  </svg>

export default Svg;
