import Immutable from 'immutable';
import { getRequestKey } from 'selectors/hotelTripSelector';

export const getHotelRequestDefinition = (hotel, checkIn, checkOut, passengers, rooms) => {
  const request = Immutable.Map({
    hotelCode: hotel.get('code'),
    departsOn: checkIn,
    returnsOn: checkOut,
    travelerCount: passengers,
    roomCount: rooms,
    hotelOnly: true,
  });
  return request.set('key', getRequestKey(request));
};
