import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

export const Carousel = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;

  .slick-list {}

  @media (max-width: ${variables.screen.xs}) {
    .slick-prev,
    .slick-next {
      display: none !important;
      z-index: 1;
    }
  }

  .slick-slide > div,
  div.slide-wrapper,
  div.slide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.slide {
    background-position: 50% 50%;
    background-size: cover;
  }
`;

export const ContentOverlay = styled.div`
  flex: 1;
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: ${colors.white};

  @media (max-width: ${variables.screen.xs}) {
    margin-top: 0;
  }

  h1,
  h3 {
    text-shadow: 0 0 4px ${toRgba(colors.black, 0.35)};
  }
`;

export const CarouselGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';

  background-image: linear-gradient(
    ${toRgba(colors.link, 0.2)},
    ${toRgba(colors.black, 0)} 50%,
    ${toRgba(colors.black, 0.35)}
  );
`;
