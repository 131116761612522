import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import classNames from 'classnames';

import { currencyData } from 'constants/currency';
import Flag from 'components/Helpers/Flag';

import Styled from './styled';

const output = (amount, round = false, comma = false, conversionRate) => {
  const baseFormatter = comma ? '0,0' : '0';
  const formatString = round ? baseFormatter : `${baseFormatter}.00`;

  if (!(Math.abs(amount) > 0 && conversionRate)) {
    return null;
  }
  return numeral(amount * conversionRate).format(formatString);
};

export class Currency extends React.PureComponent {
  render() {
    const {
      amount,
      children,
      round,
      comma,
      suffix,
      currencyCode,
      conversionRate,
      flag,
      loading,
      hideCurrencyCode,
      prefixCurrencyCode,
      defaultValue,
      className,
      ...props
    } = this.props;

    const convertedAmount = output(amount || children, round, comma, conversionRate);
    const displayAmount = convertedAmount; // Math.abs(convertedAmount);
    const isNegative = convertedAmount < 0;

    const symbol = currencyData.getIn([currencyCode, 'symbol']);
    const countryCode = currencyData.getIn([currencyCode, 'flag']);
    const showCurrencyCode = !hideCurrencyCode; // symbol === '$' && currencyCode !== 'USD';

    const showCurrencyCodePrefix = prefixCurrencyCode && showCurrencyCode;
    const showCurrencyCodeSuffix = !prefixCurrencyCode && showCurrencyCode;

    const baseClass = classNames('currency', className, {
      negative: isNegative,
    });

    return (
      <Styled className="currency-component">
        <span className={baseClass} {...props}>
          {showCurrencyCodePrefix && <span className="currency-code-prefix">{currencyCode} </span>}
          <span>
            {isNegative && '-'}
            {!!displayAmount && symbol}
            <span className="amount">
              {displayAmount ? displayAmount.replace('-', '') : defaultValue}
              {suffix}
            </span>
          </span>
          {flag && <Flag countryCode={countryCode} />}
          {showCurrencyCodeSuffix && displayAmount ? <span className="currency-code">({currencyCode})</span> : null}
        </span>
      </Styled>
    );
  }
}

Currency.defaultProps = {
  defaultValue: '----',
};

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  round: PropTypes.bool,
  comma: PropTypes.bool,
  suffix: PropTypes.string,
  currencyCode: PropTypes.string,
  conversionRate: PropTypes.number,
  flag: PropTypes.bool,
  className: PropTypes.string,
  hideCurrencyCode: PropTypes.bool,
  prefixCurrencyCode: PropTypes.bool,
  defaultValue: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Currency;
