import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './PageHeading.styled';

const PageHeading = ({ children, ...props }) => <Styled.PageHeading {...props}>{children}</Styled.PageHeading>;

PageHeading.propTypes = {
  children: PropTypes.node,
};

export default PageHeading;
