const smoothStep = (start, end, point) => {
  if (point <= start) {
    return 0;
  }
  if (point >= end) {
    return 1;
  }
  const x = (point - start) / (end - start);
  return x * x * (3 - 2 * x);
};

const scrollFrame = (duration, startY, targetY, scrollingElement, previousY, startTime = null) => {
  const isScrollingWindow = scrollingElement === window;
  // const promise = new Promise()
  const now = Date.now();
  const sT = startTime || now;
  const distance = targetY - startY;
  const endTime = sT + duration;
  const point = smoothStep(sT, endTime, now);
  const frameTop = Math.round(startY + distance * point);
  if (!isScrollingWindow) {
    scrollingElement.scrollTop = frameTop; // eslint-disable-line no-param-reassign
  } else {
    scrollingElement.scrollTo(0, frameTop);
  }
  if (now >= endTime) {
    return null;
  }
  if (!isScrollingWindow && scrollingElement.scrollTop === previousY && scrollingElement.scrollTop !== frameTop) {
    return null;
  }
  const nextY = isScrollingWindow ? scrollingElement.pageYOffset : scrollingElement.scrollTop;
  return setTimeout(() => {
    scrollFrame(duration, startY, targetY, scrollingElement, nextY, sT);
  }, 0);
  // return null;
};

export default scrollFrame;
