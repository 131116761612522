import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 27 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_eye">
      <circle id="Oval" cx="13.5" cy="8.5" r="3.5" />
      <path d="M13.5,0 C6.1362,0 0,7.89270873 0,8.5 C0,9.10669763 6.1362,17 13.5,17 C20.8635,17 27,9.10729127 27,8.5 C27,7.89241191 20.8638,0 13.5,0 Z M13.5,14.5711318 C10.11,14.5711318 7.3638,11.8534588 7.3638,8.5 C7.3638,5.14565073 10.11,2.42886825 13.5,2.42886825 C16.8897,2.42886825 19.6362,5.14565073 19.6362,8.5 C19.6362,11.8534588 16.8897,14.5711318 13.5,14.5711318 Z"/>
    </g>
  </svg>
);

export default Svg;



// <svg width="27px" height="17px" viewBox="0 0 27 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink">
//   <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
//     <desc>Created with Sketch.</desc>
//   <defs></defs>
//   <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//     <g id="Group" fill="#000000" fill-rule="nonzero">
//       <circle id="Oval" cx="13.5" cy="8.5" r="3.5"></circle>
//       <path d="M13.5,0 C6.1362,0 0,7.89270873 0,8.5 C0,9.10669763 6.1362,17 13.5,17 C20.8635,17 27,9.10729127 27,8.5 C27,7.89241191 20.8638,0 13.5,0 Z M13.5,14.5711318 C10.11,14.5711318 7.3638,11.8534588 7.3638,8.5 C7.3638,5.14565073 10.11,2.42886825 13.5,2.42886825 C16.8897,2.42886825 19.6362,5.14565073 19.6362,8.5 C19.6362,11.8534588 16.8897,14.5711318 13.5,14.5711318 Z" id="Shape"></path>
//     </g>
//   </g>
// </svg>