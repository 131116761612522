// URL: https://requirements-api.sandbox.joinsherpa.com/v2/countries/FR?include=restriction,procedure&filter[restriction.originCountries]=USA&filter[procedure.originCountries]=USA&language=en&key=AIzaSyD181LWkwSCLTB4NTqSGOq_UkkAso4_HEg

import { useEffect } from 'react';
import Immutable from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from 'hooks';
import { fetchPaymentMethods } from 'modules/paymentMethods';

const getPaymentMethod = (paymentMethods, id) => paymentMethods.find(pm => pm.get('id') === parseInt(id));

const usePaymentMethod = id => {
  const dispatch = useDispatch();

  const paymentMethods = useSelector(state => state.paymentMethods.get('items'));
  const paymentMethod = id === 'new' ? Immutable.Map() : paymentMethods && getPaymentMethod(paymentMethods, id);
  const { userId, isLoggedIn } = useAuth();

  console.log('usePaymentMethod', { userId, isLoggedIn });

  useEffect(() => {
    if (userId && isLoggedIn && !paymentMethod) {
      dispatch(fetchPaymentMethods(userId));
    }
  }, [userId, paymentMethod]);

  return paymentMethod;
};

export default usePaymentMethod;
