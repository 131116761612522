import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Container.styled';

const BorderContainer = ({ children, ...props }) => (
  <Styled.BorderContainer {...props}>{children}</Styled.BorderContainer>
);

BorderContainer.propTypes = {
  children: PropTypes.node,
};

export default BorderContainer;
