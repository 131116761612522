import PropTypes from 'prop-types';
import React from 'react';
import Favicon from 'react-favicon';

const SkylarkFavicon = ({ host }) => {
  const isServer = typeof window === 'undefined';
  const hostName = host || (!isServer ? window.location.hostname : null);
  const baseUrl = 'https://s3.amazonaws.com/web.skylark.com/favicons';
  const icons = {
    'skylark.com': 'favicon.ico',
    'www.skylark.com': 'favicon.ico',
    'staging.skylark.com': 'favicon_purple.png',
    'next.skylark.com': 'favicon_blue.png',
    development: 'favicon_orange.png',
  };
  const path = icons[hostName] || icons.development;
  return <Favicon url={`${baseUrl}/${path}`} />;
};

SkylarkFavicon.propTypes = {
  host: PropTypes.string,
};

export default SkylarkFavicon;
