import React from 'react';
import Link from 'next/link';

import { IconLogo, IconInstagram, IconFacebook } from 'components/svg';
import * as Styled from './Footer.styled';
// import './index.scss';

const address1 = '666 3rd Ave, 4th Floor';
const address2 = 'New York, NY 10017 USA';
const skylarkEmail = 'info@skylark.com';
const phoneNumber = '(212) SKYLARK';

const EmailTel = () => (
  <div>
    <a href={`mailto:${skylarkEmail}`}>{skylarkEmail}</a>
    <a href="tel:1-212-759-5275">{phoneNumber}</a>
  </div>
);

const SocialIcons = () => (
  <Styled.SocialIcons>
    <a href="https://www.instagram.com/skylark/">
      <IconInstagram height={24} />
    </a>
    <a href="https://www.facebook.com/travelwithskylark/">
      <IconFacebook height={24} />
    </a>
  </Styled.SocialIcons>
);

const ExploreLinks = () => (
  <div className="column">
    <Link href="/destinations">
      <a>Destinations</a>
    </Link>
    <Link href="/collections">
      <a>Collections</a>
    </Link>
    <Link href="/deals">
      <a>Deals</a>
    </Link>
    <Link href="/contact/inquire">
      <a>Start Planning</a>
    </Link>
  </div>
);

const CompanyLinks = () => (
  <div className="column">
    <Link href="/about/company">
      <a>About Us</a>
    </Link>
    <Link href="/about/privacy">
      <a>Privacy Policy</a>
    </Link>
    <Link href="/about/terms">
      <a>Terms and Conditions</a>
    </Link>
  </div>
);

const ContactLinks = () => (
  <div className="column">
    <a
      href="https://www.google.com/maps/place/666+3rd+Ave,+New+York,+NY+10017/@40.751213,-73.9768348,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25903ac8d3069:0xcae8dd9e85796635!8m2!3d40.751213!4d-73.9746461"
      className="address-container"
    >
      <p>{address1}</p>
      <p>{address2}</p>
    </a>

    <p>
      <a href={`mailto:${skylarkEmail}`}>{skylarkEmail}</a>
    </p>
    <p>
      <a href="tel:1-212-759-5275">{phoneNumber}</a>
    </p>
  </div>
);

const Footer = () => (
  <Styled.Footer className="site-footer">
    <div>
      <div>
        <div>
          <IconLogo height={36} />
        </div>

        <h3 className="mobile-hide">Explore</h3>
        <h3 className="mobile-hide">Company</h3>
        <h3 className="mobile-hide">Contact</h3>
      </div>

      <div className="mobile-only">
        <Link href="/about/company">
          <a>About Us</a>
        </Link>
        <Link href="/about/terms">
          <a>Terms & Conditions</a>
        </Link>
        <Link href="/about/privacy">
          <a>Privacy Policy</a>
        </Link>
      </div>

      <div className="mobile-only">
        <EmailTel />
      </div>

      <div className="mobile-hide">
        <div>
          <p>
            Skylark is a new kind of luxury travel company. Amazing deals on 5-star hotels and flights + perks with experienced agents at the ready.
          </p>
          <SocialIcons />
        </div>
        <ExploreLinks />
        <CompanyLinks />
        <ContactLinks />
      </div>
      <div className="mobile-only">
        <SocialIcons />
      </div>
      <footer>
        <div className="copyright">
          <p>© {new Date().getFullYear()} Skylark Travel Group, Inc</p>
        </div>
        <div>
          <Styled.MadeInNY />
        </div>
      </footer>
    </div>
  </Styled.Footer>
);

export default Footer;
