import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import clipboard from 'clipboard-polyfill/build/clipboard-polyfill.promise';

import { IconChat, IconPhone, IconMail, IconEye, IconClock, IconLink } from 'components/svg';

import * as Styled from './AgentLinkWidget.styled';

const copyToClipBoard = (e, content) => {
  const dt = new clipboard.DT();
  dt.setData('text/plain', content);
  dt.setData('text/html', content);
  clipboard.write(dt);
};

const linkUrl = (user, content) => {
  const userName = user.get('email').split('@')[0];
  const utmString = `utm_source=agent&utm_medium=email&utm_campaign=agentrec&utm_name=${userName}&utm_content=${content}`;

  if (window.location.href.includes('?')) {
    return `${window.location.href}&${utmString}`;
  }
  return `${window.location.href}?${utmString}`;
};

const AgentLinkWidget = ({ user }) => {
  if (!user.get('debug_enabled')) {
    return null;
  }
  return (
    <Styled.AgentLinkWidget>
      <div className="link-icon">
        <IconLink width="20" />
      </div>
      <div className="link-options">
        <div title="Chat Follow-up" onClick={e => copyToClipBoard(e, linkUrl(user, 'chat'))}>
          <IconChat width="12" />
        </div>
        <div title="Phone Response" onClick={e => copyToClipBoard(e, linkUrl(user, 'phone'))}>
          <IconPhone width="12" />
        </div>
        <div title="Email Response" onClick={e => copyToClipBoard(e, linkUrl(user, 'email'))}>
          <IconMail width="12" />
        </div>
        <div title="Watch List" onClick={e => copyToClipBoard(e, linkUrl(user, 'watchlist'))}>
          <IconEye width="12" />
        </div>
        <div title="Lapsed Lead (Fullstory)" onClick={e => copyToClipBoard(e, linkUrl(user, 'fullstory'))}>
          <IconClock width="12" />
        </div>
      </div>
    </Styled.AgentLinkWidget>
  );
};

AgentLinkWidget.defaultProps = {
  user: Immutable.Map(),
};

AgentLinkWidget.propTypes = {
  user: PropTypes.instanceOf(Immutable.Map),
};

export default AgentLinkWidget;
