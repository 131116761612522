import styled from 'styled-components';
import { variables, colors, toRgba } from 'styles';

export const ScrollFooter = styled.div`
  transition: ${props => (props.isOpen ? 'all 200ms ease-in' : 'all 200ms ease-out')};
  position: fixed;
  bottom: ${props => (props.isOpen ? 0 : `-${props.height}px`)};
  left: 0;
  right: 0;
  height: ${props => `${props.height}px`};
  background: ${colors.warmGray};
  border-top: 1px solid ${colors.coolGray};
  z-index: 2;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 2rem;
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  & > span {
    display: block;
    transform: rotate(45deg);
    text-align: center;
  }
`;
