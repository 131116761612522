import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fetchConfig } from 'modules/clientConfig';
import { useAuth, useProfile, useSessionInfo, useAnalytics } from 'hooks';

const useClientConfig = () => {
  const dispatch = useDispatch();
  const { userId, anonymousId, isLoggedIn } = useAuth();
  const { isLoaded: isUserLoaded } = useProfile();
  const { analyticsUser } = useAnalytics();
  const sessionInfo = useSessionInfo();

  const [isReady, setIsReady] = useState(false);

  const segmentAnonymousId = analyticsUser?.anonymousId;
  const clientConfig = useSelector(state => state.clientConfig);

  useEffect(() => {
    const isComplete = (isLoggedIn && isUserLoaded) || !isLoggedIn;
    setIsReady(isComplete);
  }, [isUserLoaded, isLoggedIn]);

  // This is needed to ensure trigger on user sign in
  // It will fire a call to the API anytime:
  // 1) A new client session (including refresh) begins
  // 2) A current, Anonymous client session logs in (UserId change)
  // It will always, wait until the Analytics library (segment) is loaded (analyticsUser is present)
  useEffect(() => {
    const configLoading = clientConfig?.get('loading');
    const hasError = clientConfig?.get('error');
    if (isReady && !configLoading && !hasError) {
      // console.log('useClientConfig > should do your thing', {
      //   isReady,
      //   userId,
      //   isLoggedIn,
      //   configLoading,
      //   segmentAnonymousId,
      //   sessionInfo,
      // });
      dispatch(fetchConfig(anonymousId, segmentAnonymousId, userId, sessionInfo));
    }
  }, [isReady, userId, isLoggedIn]); // TODO: should it check for the presence of segmentAnonymousId?

  // console.log('clientConfig', clientConfig && clientConfig.toJS());

  return clientConfig;
};

export default useClientConfig;
