import React from 'react';

export const Svg = props => (
  <svg {...props} width="30px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="Symbols" fill={props.color || '#000000'}>
      <path d="M4.5,0 L0.25,4.25 L0.25,4.25 C0.111928813,4.38807119 0.111928813,4.61192881 0.25,4.75 L0.25,4.75 L0.25,4.75 C0.388071187,4.88807119 0.611928813,4.88807119 0.75,4.75 L4.5,1 L8.25,4.75 L8.25,4.75 C8.38807119,4.88807119 8.61192881,4.88807119 8.75,4.75 L8.75,4.75 L8.75,4.75 C8.88807119,4.61192881 8.88807119,4.38807119 8.75,4.25 L4.5,0 Z" transform="translate(4.500000, 2.500000) rotate(180.000000) translate(-4.500000, -2.500000) "></path>
    </g>
  </svg>
);

export default Svg;
