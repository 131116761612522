import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SiteContainer.styled';

const SiteContainer = ({ children, ...props }) => <Styled.SiteContainer {...props}>{children}</Styled.SiteContainer>;

SiteContainer.propTypes = {
  children: PropTypes.node,
};

export default SiteContainer;
