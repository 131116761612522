import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

const Styled = styled.div`
  &.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid ${colors.coolGray};
    padding: 1.25rem;

    .alert-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid red;
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      font-size: 2rem;
      font-weight: 400;
      background: transparent;
      & > span {
        display: block;
        transform: rotate(45deg);
        text-align: center;
      }
    }

    &-error {
      border-color: ${colors.negRed};
      color: ${colors.negRed};
      background: ${lighten(colors.negRed, 0.35)};
      .alert-cancel {
        border: none;
        span {
          color: ${colors.negRed};
        }
        &:hover {
          background: ${lighten(colors.negRed, 0.3)};
        }
      }
    }
    &-warning {
      border-color: ${colors.warningOrange};
      color: ${colors.warningOrange};
      background: ${lighten(colors.warningOrange, 0.35)};
      .alert-cancel {
        border: none;
        span {
          color: ${colors.warningOrange};
        }
        &:hover {
          background: ${lighten(colors.warningOrange, 0.3)};
        }
      }
    }
    &-success {
      border-color: ${colors.posGreen};
      color: ${colors.posGreen};
      background: ${lighten(colors.posGreen, 0.25)};
      .alert-cancel {
        border: none;
        span {
          color: ${colors.posGreen};
        }
        &:hover {
          background: ${lighten(colors.posGreen, 0.5)};
        }
      }
    }
    &-info {
      border-color: ${colors.infoBlue};
      color: ${colors.infoBlue};
      background: ${lighten(colors.infoBlue, 0.45)};
      .alert-cancel {
        border: none;
        span {
          color: ${colors.infoBlue};
        }
        &:hover {
          background: ${lighten(colors.infoBlue, 0.4)};
        }
      }
    }
  }
`;

export default Styled;
