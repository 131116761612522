import styled from 'styled-components';
import { colors } from 'styles';

const Styled = styled.span`
  width: 100px;
  margin-right: 0.5rem;
  .currency-select-container {
  }

  .currency-select {
    &__control {
      min-height: 36px;
      border-width: 0;
      border-radius: 0;
      background: transparent;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 8px 12px 8px 0;
    }

    &__dropdown-indicator {
    }
  }

  .currency-select__single-value {
    color: inherit;
  }

  .option-content-wrapper {
    width: 95px;
    color: inherit;
  }
  .option-content {
    color: inherit;
    margin-right: 5px;
    display: flex;
    align-items: center;
    &-control {
      background: transparent;
      height: auto;
      border: none;
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }

    & > * {
      color: inherit;
      margin-right: 5px;
    }
  }

  .flag-icon {
    height: 1em;
    width: (4 / 3) * 1em;
  }
`;

export default Styled;
