import Immutable from 'immutable';

export const cabinOptions = [
  { value: undefined, label: 'Hotel (No Flights)' },
  { value: 'Y', label: 'Economy' },
  { value: 'C', label: 'Business' },
];

export const roomCountOptions = [
  { value: 1, label: '1 Room' },
  { value: 2, label: '2 Rooms' },
  { value: 3, label: '3 Rooms' },
  { value: 4, label: '4 Rooms' },
];

export const travelerCountOptions = [
  { value: 1, label: '1 Person' },
  { value: 2, label: '2 People' },
  { value: 3, label: '3 People' },
  { value: 4, label: '4 People' },
  { value: 5, label: '5 People' },
  { value: 6, label: '6 People' },
  { value: 7, label: '7 People' },
  { value: 8, label: '8 People' },
];

const SEARCH_CONTROL_DATA = Immutable.Map({
  cabinOptions,
  roomCountOptions,
  travelerCountOptions,
});

export default SEARCH_CONTROL_DATA;
