import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './ScrollArrow.styled';

const ScrollArrow = ({ children, ...props }) => (
  <Styled.ScrollArrow {...props}>
    <div>
      <span className="arrow-down" />
      {children && <title>{children}</title>}
    </div>
  </Styled.ScrollArrow>
);

ScrollArrow.propTypes = {
  children: PropTypes.node,
};

export default ScrollArrow;
