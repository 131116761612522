import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fetchReferrer } from 'modules/referrer';

const useReferrer = referralCode => {
  const dispatch = useDispatch();
  const referrer = useSelector(state => state.referrer);

  console.log('referrer', referrer && referrer.toJS());

  // const isCodeChanged = referralCodereferrer.get('referral_code') && referrer.get('referral_code') !== referralCode;
  const isLoading = referrer.get('loading');

  useEffect(() => {
    if (!isLoading && referralCode) {
      dispatch(fetchReferrer(referralCode));
    }
  }, [referralCode]);

  return referrer;
};

export default useReferrer;
