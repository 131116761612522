import Immutable from 'immutable';
import { urlDate } from 'components/Helpers';

const isHotelOnly = cabinClass => !cabinClass;

const getQueryString = params => {
  const departDate = urlDate(params.departDate);
  const returnDate = urlDate(params.returnDate);
  const { travelerCount, roomCount, cabinClass, origin } = params;
  if (departDate && returnDate && travelerCount && roomCount) {
    let query = `?check_in=${departDate}&check_out=${returnDate}&travelers=${travelerCount}&rooms=${roomCount}`;
    if (cabinClass && origin) {
      query += `&origin=${origin}&cabin=${cabinClass}`;
    }
    return query;
  }
  return '';
};

const getTargetUrl = (params, path) =>
  // console.log('getTargetUrl', 'params', params);
  // console.log('getTargetUrl', 'path', path);
  `/${path}${getQueryString(params)}`;
export const validateSearch = (searchParams, target) => {
  // console.log('validateSearch > searchParams', searchParams);
  // console.log('validateSearch > target', target && target.toJS());
  const { departDate, returnDate, origin, selectedOrigin, cabinClass, roomCount, travelerCount } = searchParams;
  const hasTarget = target && (target.get('id') || target.get('code') || target.get('iata_code') || target.get('path'));

  let searchErrors = Immutable.Map({ valid: true });

  if ((!departDate || !returnDate) && !isHotelOnly(cabinClass)) {
    searchErrors = searchErrors.setIn(['date', 'message'], 'Required').set('valid', false);
  }
  if (!hasTarget) {
    searchErrors = searchErrors.setIn(['destination', 'message'], 'Required').set('valid', false);
  }

  const originCode = origin || selectedOrigin;

  // console.log(`(!${originCode} && !!${cabin})`, !originCode && !!cabin);

  if (cabinClass && !originCode) {
    searchErrors = searchErrors.setIn(['origin', 'message'], 'Required').set('valid', false);
  }

  if (roomCount > travelerCount) {
    searchErrors = searchErrors
      .setIn(['travelerCount', 'message'], 'at least 1 person')
      .setIn(['roomCount', 'message'], 'for each room')
      .set('valid', false);
  }
  return searchErrors;
};

const sharedParams = params => {
  const { origin, selectedOrigin, travelerCount, roomCount, cabinClass, departDate, returnDate } = params;
  const hotelOnly = isHotelOnly(cabinClass);
  const checkIn = urlDate(departDate);
  const checkOut = urlDate(returnDate);
  let resp = {};

  if (checkIn && checkOut) {
    resp = Object.assign(resp, {
      checkIn,
      checkOut,
      passengers: travelerCount,
      rooms: roomCount,
    });
  }

  if (!hotelOnly) {
    resp = Object.assign(resp, {
      originCode: origin || selectedOrigin,
      cabinClass,
    });
  }
  return resp;
};

export const getTargetRoute = (selection, searchParams) => {
  const params = Immutable.fromJS(searchParams);
  const searchErrors = validateSearch(params.toJS(), selection);
  const isValid = searchErrors.get('valid');

  // console.log('SEARCH ERRORS', searchErrors && searchErrors.toJS());

  if (!isValid || !selection) {
    return false;
  }

  const searchType = selection.get('type');
  const collectionId = selection.get('id');
  const cityCode = selection.get('city_code') || selection.get('access_code') || selection.get('code');
  const hotelCode = selection.get('code');
  const iataCode = selection.get('iata_code');
  // console.log('SELECTION', selection.toJS());
  const path = selection.get('full_path') || selection.get('path');

  const destinationCode = cityCode || iataCode;

  const pathOptions = {
    collection: `/collections/[id]`,
    hotel: '/hotelPage',
    destination: '/destinations',
  };

  const targetUrl = getTargetUrl(params.toJS(), path);

  const baseQuery = sharedParams(params.toJS());
  const queryOptions = {
    collection: Object.assign({}, baseQuery, { id: collectionId }),
    hotel: Object.assign({}, baseQuery, { hotelCode }),
    destination: Object.assign({}, baseQuery, { destinationCode, destinationPath: selection.get('path') }),
  };

  return {
    pathname: pathOptions[searchType] || pathOptions.destination,
    query: queryOptions[searchType] || queryOptions.destination,
    url: targetUrl,
  };
};
