import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 801 150" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="skylark-wordmark">
      <path d="M764.7829,70.7525 L797.4589,1.1565 L777.3439,1.1565 L745.2759,70.5205 L744.7419,70.5205 L744.7419,1.1565 L723.7019,1.1565 L723.7019,148.2095 L744.7419,148.2095 L744.7419,77.6885 L745.2869,77.6885 L773.6439,137.8035 C777.5749,146.1285 780.1179,148.2095 789.1359,148.2095 L800.9279,148.2095 L800.9279,147.7465 L764.7829,70.7525 Z" id="Fill-8" />
      <path d="M626.8229,68.44 L621.2729,68.44 L621.2729,18.96 L626.1289,18.96 C641.8509,18.96 649.0189,27.515 649.0189,43.468 C649.0189,58.267 642.7759,68.44 626.8229,68.44 L626.8229,68.44 Z M661.5049,100.81 C657.8639,89.225 654.2059,83.337 647.5969,79.479 C661.5049,72.146 669.1349,57.001 669.1349,39.769 C669.1349,15.492 654.3379,1.156 624.7419,1.156 L600.2329,1.156 L600.2329,148.209 L621.2729,148.209 L621.2729,85.55 C632.6029,85.55 636.5339,89.018 640.2329,101.273 L650.4059,135.492 C653.6429,146.128 657.5739,148.209 666.5919,148.209 L678.3839,148.209 L678.3839,147.746 C676.5339,144.972 673.7599,139.885 670.7539,130.405 L661.5049,100.81 Z" id="Fill-11" />
      <path d="M492.8932,98.4982 L506.9192,24.5092 L507.3662,24.5092 L521.9772,98.4982 L492.8932,98.4982 Z M493.6432,1.1562 L461.7352,148.2092 L483.4692,148.2092 L489.5182,116.3012 L525.4932,116.3012 L531.7932,148.2092 L553.2962,148.2092 L521.3882,1.1562 L493.6432,1.1562 Z" id="Fill-6" />
      <path d="M383.3537,1.1564 L362.3127,1.1564 L362.3127,148.2094 L409.7117,148.2094 C421.0417,148.2094 422.6597,146.1284 424.5097,137.5734 L426.1287,130.4054 L383.3537,130.4054 L383.3537,1.1564 Z" id="Fill-13" />
      <polygon id="Fill-1" points="297.8044 1.1564 275.5274 65.2024 275.0584 65.2024 253.1804 1.1564 231.4464 1.1564 264.2784 91.0134 264.2784 148.2094 285.3184 148.2094 285.3184 91.0134 318.1514 1.1564" />
      <path d="M195.1452,1.1564 L175.0292,1.1564 L142.9622,70.5204 L142.4282,70.5204 L142.4282,1.1564 L121.3882,1.1564 L121.3882,148.2094 L142.4282,148.2094 L142.4282,77.6884 L142.9732,77.6884 L171.3302,137.8034 C175.2602,146.1284 177.8042,148.2094 186.8212,148.2094 L198.6132,148.2094 L198.6132,147.7464 L162.4692,70.7524 L195.1452,1.1564 Z" id="Fill-4" />
      <path d="M21.0402,31.6769 C21.0402,21.5029 26.3582,15.9539 34.6822,15.9539 C45.7802,15.9539 50.4042,25.6649 47.3992,45.5499 L51.3302,45.5499 C61.7342,45.5499 67.5142,40.9259 67.5142,27.9769 C67.5142,13.4109 57.8032,0.0009 35.8382,0.0009 C12.9482,0.0009 0.4622,14.3359 0.4622,36.7629 C0.4622,80.2319 50.1732,81.8499 50.1732,116.0709 C50.1732,128.3239 43.6992,133.4109 35.8382,133.4109 C23.3532,133.4109 18.2662,122.0809 21.0402,99.6539 L18.2662,99.6539 C6.0112,99.6539 0.0002,105.4339 0.0002,117.9199 C0.0002,135.7229 12.2542,149.3649 34.2202,149.3649 C56.6472,149.3649 70.2892,136.1859 70.2892,111.6769 C70.2892,65.2029 21.0402,64.9709 21.0402,31.6769" id="Fill-2" />
    </g>
  </svg>
);

export default Svg;
