import React from 'react';
import PropTypes from 'prop-types';

const formatPhone = (phonenum) => {
  const regexObj = /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (regexObj.test(phonenum)) {
    const parts = phonenum.match(regexObj);
    let phone = parts[1] ? `+1 (${parts[1]}) ` : '';
    phone += `${parts[2]}-${parts[3]}`;
    return phone;
  }
  // invalid phone number
  return phonenum;
};

export const PhoneNumber = ({ children, ...props }) => (
  <span {...props}>{ formatPhone(children) }</span>
);

PhoneNumber.defaultProps = {
  children: null
};

PhoneNumber.propTypes = {
  children: PropTypes.string
};

export default PhoneNumber;
