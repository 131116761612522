import React from 'react';
import { PropTypes } from 'prop-types';
import { useRouter } from 'next/router';

import { useAuth } from 'hooks';

const onSignOut = (params, payload, callback) => {
  if (callback) {
    callback();
  }
};

const SignOut = ({ children, context, ...props }) => {
  const router = useRouter();
  const { signOut } = useAuth();

  return (
    <a onClick={() => onSignOut(router.query, { context }, signOut)} {...props} role="link" tabIndex={0}>
      {children}
    </a>
  );
};

SignOut.defaultProps = {
  children: 'Sign Out',
};

SignOut.propTypes = {
  context: PropTypes.string,
  children: PropTypes.node,
};

export default SignOut;
