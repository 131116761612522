import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-pets">
        <path d="M25.766,7.922 L22.613,10.02 C22.391,10.181 22.036,10.169 21.824,9.995 L21.145,9.432 C20.934,9.256 20.602,9.272 20.407,9.466 L19.337,10.536 C19.143,10.731 18.824,10.731 18.63,10.536 L14.84,6.431 C14.646,6.238 14.646,5.919 14.84,5.724 L20.079,0.482 C20.548,-0.017 21,-0.085 21,0.497 L21,2 C21.008,2.275 21.013,2.543 21.21,2.735 L25.859,7.312 C26.056,7.504 25.987,7.761 25.766,7.922 Z M17.595,11.621 L13.752,7.516 C13.558,7.323 13.239,7.323 13.045,7.516 C13.045,7.516 12.734,8 11.812,8 L4.412,8 C4.412,8 3.67,8.023 3.062,7.637 C2.454,7.25 0.816,6.053 0.816,6.053 C0.566,5.94 0.36,5.828 0.141,6.047 C-0.078,6.266 -0.016,6.586 0.188,6.77 L2.132,9.253 C2.336,9.438 2.372,9.776 2.212,10 L0.297,12.654 C0.137,12.878 0,13.287 0,13.562 L0,24 C0,24.55 0.45,25 1,25 L2,25 C2.55,25 3,24.55 3,24 L3,18.971 L5.103,15.281 C5.307,15.097 5.726,14.973 6,15.006 L14.578,16.806 C14.852,16.838 15,17.09 15,17.365 L15,24 C15,24.55 15.45,25 16,25 L17,25 C17.55,25 18,24.55 18,24 L18,12.477 C18,12.201 17.789,11.816 17.595,11.621 Z" id="dog_4_"></path>
    </g>
  </svg>

export default Svg;
