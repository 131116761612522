import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { BLOG_ENDPOINT } from 'constants/endpoints';

const ArticleLink = ({ article, className, children, rel, onClick }) => {
  const targetUrl = `${BLOG_ENDPOINT}/articles/${article.get('slug')}`;

  return (
    article.get('id') && (
      <Link href={targetUrl} as={targetUrl} prefetch={false}>
        <a onClick={onClick} className={className} rel={rel}>
          {children}
        </a>
      </Link>
    )
  );
};

ArticleLink.propTypes = {
  article: PropTypes.instanceOf(Immutable.Map),
  className: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ArticleLink;
