import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = ({ stops, ...props }) => (
  <svg {...props} viewBox="0 0 186 186" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="air_stops">
      <polygon id="Polygon" strokeWidth="10" stroke="#FF0000" fill="#FFFFFF" transform="translate(93.000000, 93.000000) rotate(22.500000) translate(-93.000000, -93.000000) " points="93 -2 160.175144 25.8248558 188 93 160.175144 160.175144 93 188 25.8248558 160.175144 -2 93 25.8248558 25.8248558" />
      <text id="air_stops_text" fontFamily="" fontSize="124" style={{ textAlign: 'center'}} fontWeight="600" fill="#000000">
        <tspan x="60" y="132">{stops}</tspan>
      </text>
    </g>
  </svg>
);

export default Svg;
