import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Content.styled';

const ContentBlock = ({ children, alternate, contained, linked, ...props }) =>
  contained ? (
    <Styled.ContainedContentBlock alternate={alternate} linked={linked} {...props}>
      {children}
    </Styled.ContainedContentBlock>
  ) : (
    <Styled.ContentBlock alternate={alternate} {...props}>
      {children}
    </Styled.ContentBlock>
  );

ContentBlock.propTypes = {
  alternate: PropTypes.bool,
  contained: PropTypes.bool,
  linked: PropTypes.bool,
  children: PropTypes.node,
};

export default ContentBlock;
