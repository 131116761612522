import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const Styled = styled.div`
  position: fixed;
  z-index: 11;
  right: -100vw;
  top: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  border-left: 1px solid ${colors.darkGray};
  padding-top: 60px;
  background: ${toRgba(colors.black, 0.75)};
  transition: all 0.3s ease;
  &.open {
    right: 0px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid ${toRgba(colors.white, 0.5)};
    li {
      border-bottom: 1px solid ${toRgba(colors.white, 0.5)};
      padding: 10px;
      a,
      button.link {
        display: block;
        color: white !important;
      }
    }
  }

  @media (max-width: 768px) {
    left: 100vw;
    width: 100vh;
    ul {
      margin-top: 0px;
      li a,
      li button.link {
        font-size: 36px;
        text-decoration: none;
        padding: 0 10px;
        margin: 0;
      }
    }
    &.open {
      left: 0vw;
    }
  }

  @media (max-width: 415px) {
    left: 100vw;
    width: 100vh;
    &.open {
      left: 0vw;
    }
  }
`;

export default Styled;
