import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import { multiSearchPromise } from 'modules/multiSearch';
import AsyncSearch from '../AsyncSearch';

const DestinationSearch = ({ selection, className, placeholder, onSelectionChange, ...props }) => {
  const selectionDisplay = selection
    ? selection
        .set('type', selection.get('type') || 'destination')
        .set('name', selection.get('name') || placeholder || '...')
        .toJS()
    : { type: 'destination', name: placeholder || '...' };

  return (
    <AsyncSearch
      placeholder={placeholder}
      className={className}
      optionLoader={multiSearchPromise}
      selection={selectionDisplay}
      onSelectionChange={(v) => onSelectionChange(v)}
      {...props}
    />
  );
};

DestinationSearch.defaultProps = {
  onSelectionChange: () => {},
  placeholder: 'Enter Something to Search for',
};

DestinationSearch.propTypes = {
  selection: PropTypes.instanceOf(Immutable.Map),
  onSelectionChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default DestinationSearch;
