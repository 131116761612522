import Color from 'color';

const lighten = (color, amount) => {
  const lightness = Color(color).lightness();
  const percentStep = Math.abs(100 - lightness) / 100;
  const adjustor = 100 - amount;

  return Color(color)
    .lightness(lightness + percentStep * adjustor)
    .string();
};

const darken = (color, amount) => {
  const lightness = Color(color).lightness();
  const percentStep = Math.abs(lightness) / 100;
  const adjustor = amount;

  return Color(color)
    .lightness(lightness - percentStep * adjustor)
    .string();
};

const colorScale = opts => {
  const { color } = opts;
  const tints = opts.tints || [10, 20, 25, 30, 40, 50, 60, 70, 80, 90];
  const prefix = opts.prefix || 'tint';
  const showDefaults = opts.showDefaults !== false;

  const range = {};
  if (showDefaults) {
    range.shade = darken(color, 50);
    range.base = color;
  }
  tints.reverse().forEach(tint => {
    range[`${prefix}${tint}`] = lighten(color, tint);
  });
  if (showDefaults) {
    range.faint = lighten(color, 5);
  }
  return range;
};

const colorPallete = {
  basePurple: '#8d26e1',
  baseGreen: '#57a55c',
  baseRed: '#e66756',
  baseBlue: '#2196f3',
  black: '#000000',
  white: '#FFFFFF',
  errorRed: '#e3545b',
  warningYellow: '#f9d054',
  successGreen: '#57a55c',
  linkBlue: '#2f65c6',
  moonGold: '#f9d054',
};

const purples = colorScale({ color: colorPallete.basePurple, tints: [10, 25, 50, 75, 90] });
const red = colorScale({ color: colorPallete.baseRed, tints: [10, 25, 50, 75, 90] });
const blue = colorScale({ color: colorPallete.baseBlue, tints: [10, 25, 50, 75, 90] });
const green = colorScale({ color: colorPallete.baseGreen, tints: [10, 25, 50, 75, 90] });

const info = {
  text: blue.shade,
  background: blue.faint,
  foreground: blue.base,
};

const alert = {
  text: red.shade,
  background: red.tint10,
  foreground: red.base,
};

const success = {
  text: green.shade,
  background: green.faint,
  foreground: green.base,
};

const neutrals = colorScale({
  color: 'hsl(0, 0%, 0%)',
  tints: [2, 5, 10, 20, 30, 50, 70, 90],
  prefix: 'neutral',
  showDefaults: false,
});

const datepicker = {
  base: red.base,
  tint90: red.tint90,
  tint70: red.tint75,
  tint50: red.tint50,
  tint30: red.tint25,
  tint10: red.tint10,
  alternate: '#ffce71',
};

const colors = Object.assign(neutrals, {
  primary: blue,
  brand: purples,
  red,
  blue: colorPallete.blue,
  green,
  datepicker,

  black: colorPallete.black,
  softBlack: '#4a4a4a',
  border: neutrals.neutral20,
  white: colorPallete.white,

  danger: colorPallete.errorRed,
  caution: colorPallete.warningYellow,
  success: colorPallete.successGreen,
  link: colorPallete.linkBlue,
  gold: colorPallete.moonGold,

  flash: { info, alert, success },
});

export default colors;
