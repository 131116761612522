import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { useProfile } from 'hooks';

import { requestAirport } from 'modules/airports';
import { originSearchPromise } from 'modules/originSearch';
import AsyncSearch from '../AsyncSearch';

import './index.scss';

const OriginSearch = ({ className, placeholder, selectedAirportCode, onSelectionChange, ...props }) => {
  const dispatch = useDispatch();
  const userProfile = useProfile();
  const [airport, setAirport] = useState();
  const [extendOriginSearch, setExtendOriginSearch] = useState(false);

  const selectedAirport = useSelector((state) => state.airports.get(selectedAirportCode));

  const user = userProfile?.user?.toJS();

  // TODO: Extend Org aop profile to indicate this as boolean
  const internationalSearchSupported = [4, 5].includes(user.organization_app_id);

  useEffect(() => {
    const isAgent = user.debug_enabled;
    // const isRog = user.organization_app_id === 4;
    // console.log('OriginSearch > user', { isAgent, user });
    // if (isAgent) {
    setExtendOriginSearch(isAgent || internationalSearchSupported);
    // }
  }, [user]);

  // Initialize the request if a default selection if provided
  useEffect(() => {
    dispatch(requestAirport(selectedAirportCode));
  }, [selectedAirportCode]);

  // Populate the component if a default selection returns an airport
  useEffect(() => {
    setAirport(selectedAirport);
  }, [selectedAirport]);

  const onChange = (airportValue) => {
    if (airportValue !== airport) {
      onSelectionChange(airportValue);
      setAirport(Immutable.fromJS(airportValue));
    }
  };

  const componentClass = classNames(className, 'component-origin-search');

  return (
    <AsyncSearch
      placeholder={placeholder}
      className={componentClass}
      optionLoader={(q) => originSearchPromise(q, extendOriginSearch)}
      isLoading={airport && airport.get('isLoading')}
      selection={airport && airport.toJS()}
      onSelectionChange={(v) => onChange(v)}
      {...props}
    />
  );
};

OriginSearch.defaultProps = {
  selectedAirportCode: null,
  onSelectionChange: () => {},
  className: '',
  placeholder: 'Leaving from (US Airport)',
};

OriginSearch.propTypes = {
  selectedAirportCode: PropTypes.string,
  onSelectionChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default OriginSearch;
