import Cookies from 'js-cookie';

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic2t5bGFyay10cmF2ZWwiLCJhIjoiY2owaW5qYTZlMDE3aDMycHl0Z2J5ZXN3dSJ9.2tZXLEMDxyTn-jsaBR0wPg';
export const MAPBOX_STYLE_REF = 'mapbox://styles/skylark-travel/cj3m4nc0v00072sqh5k1x7741';

export const DEFAULT_DESTINATION_HEADER_IMAGE_URL =
  'https://d1m2xmyf58uf17.cloudfront.net/destination_images/mga/404/full/shutterstock_317547605.jpg?1471448834';

export const SEGMENT_API_KEY_PRODUCTION = 'idmC0Hs9i6q23H9DYCmhYwPHHZd2FPNy';
export const SEGMENT_API_KEY_DEVELOPMENT = 'UYRQsFKAWmYvVKxzCfjNeosr1uWCEdKJ';

export const SESSION_TIMEOUT = 30; // 30 days
export const MINIMUM_SEARCH_CHARS = 3;

export const REFUND_PENALTIES = {
  C: 900,
  Y: 500,
};

export const SENTRY_DSN = 'https://3bc1c9989f9a46ee8b1184c0f1e0a350@sentry.io/1513876';

export function getAuthToken() {
  // console.log('getAuthToken', Cookies.get('skylarkJWT'));
  return Cookies.get('skylarkJWT');
}

export function setAuthToken(token) {
  if (token) {
    // console.log('*** setAuthToken', token);
    Cookies.set('skylarkJWT', token, { expires: SESSION_TIMEOUT });
  }
}

export function clearAuthToken() {
  Cookies.remove('skylarkJWT');
}
