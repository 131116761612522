import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = ({height = 60, fill, savingsPct, visible, ...props}) => {
  return visible ? (
    <svg {...props} width={height} height={height} viewBox="0 0 75 75" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs />
        <g id="savings-flag">
            <path d="M75,74.6838396 L75,0 L0,0 L0,0.316160448 L0.316160448,1.42108547e-14 L75,74.6838396 Z" id="Combined-Shape" fill={props.fill || '#E66756'} />
            <text id="SAVE" transform="translate(59.329469, 15.564763) rotate(45.000000) translate(-59.329469, -15.564763) " fontFamily="ProximaNova-Semibold, Proxima Nova" fontSize="8.88000011" fontWeight="500" fill="#FFFFFF">
                <tspan x="48.4203884" y="18.0647626">SAVE</tspan>
            </text>
            <text id="21%" transform="translate(49.264706, 28.941176) rotate(45.000000) translate(-49.264706, -28.941176) " fontFamily="ProximaNova-Semibold, Proxima Nova" fontSize="22.2000003" fontWeight="500" fill="#FFFFFF">
                <tspan x="30.1616056" y="35.4411765">{parseInt(savingsPct, 10)}%</tspan>
            </text>
        </g>
    </svg>
  ) : null;
};

export default Svg;
