import moment from 'moment';

export const getSearchInfo = query => {
  const checkIn = moment.parseZone(query.check_in || query.checkIn);
  const checkOut = moment.parseZone(query.check_out || query.checkOut);
  const cabin = query.cabin || query.cabin_class || query.cabinClass;
  const origin = query.origin || query.origin_code || query.originCode;
  const checkInYear = checkIn.format('YYYY');
  const checkInMonth = checkIn.format('M');
  const checkInDay = checkIn.format('D');
  const lengthOfStay = checkOut.diff(checkIn, 'days');
  const daysOut = checkIn.diff(moment.parseZone(), 'days');
  const passengers = query.passengers || query.travelers;
  const { rooms } = query;

  if (lengthOfStay < 1) {
    return {};
  }
  return {
    year: checkInYear,
    month: checkInMonth,
    day: checkInDay,
    length_of_stay: lengthOfStay,
    days_out: daysOut,
    cabin,
    origin,
    passengers,
    rooms,
  };
};

export const getPageViewType = query => {
  const isSearch = !!query.check_in || !!query.checkIn;
  const hasAir = !!query.cabin || !!query.cabinClass;
  const isBundle = isSearch && hasAir;
  if (isBundle) return 'bundle';
  if (isSearch) return 'search';
  return 'display';
};

export const getCardEntityInfo = (entity, entityType) => ({
  card_entity: entity.type || entityType,
  card_entity_id: entity.id || 0,
  card_entity_name: entity.name,
  card_entity_variant: entity.object_type,
});
