import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { clearAlert } from 'modules/alerts';
import smoothScrolling from 'utilities/smoothScrolling';

import Alert from './Alert';

const AlertList = ({ scrollTo }) => {
  const dispatch = useDispatch();
  const alerts = useSelector(state => state.alerts.get('alerts'));

  useEffect(() => {
    if (scrollTo && alerts.count()) {
      const startY = window.pageYOffset;
      smoothScrolling(500, startY, 0, window);
    }
  }, [alerts, scrollTo]);

  const onCloseAlert = alert => dispatch(clearAlert(alert));

  return (
    <div className="alert-list-component">
      {alerts.map(alert => (
        <Alert
          key={alert.get('message')}
          message={alert.get('message')}
          type={alert.get('type')}
          onClose={() => onCloseAlert(alert)}
        />
      ))}
    </div>
  );
};

AlertList.propTypes = {
  scrollTo: PropTypes.bool,
};

AlertList.defaultProps = {
  scrollTo: false,
};

export default AlertList;
