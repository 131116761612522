import styled from 'styled-components';
import { colors, toRgba } from 'styles';

export const LoadingOverlay = styled.div`
  position: relative;
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 10%;
    z-index: 1;
    text-shadow: 0 0 10px ${colors.white};
    cursor: wait;
  }
`;

export const LoadingContent = styled.div`
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
`;
