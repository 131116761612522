import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { useAuth } from 'hooks';
import { SignUpForm } from './SignUpForm';

const SignUp = ({ referrerId, referralCode, invitationCode, headerText, buttonText, className }) => {
  const { signUp, clearAuthErrors, authErrors, isLoading, passwordRequested } = useAuth();
  const [formValues, setFormValues] = useState({});

  const baseClass = classNames('sign-up-component', className);

  useEffect(() => {
    clearAuthErrors();
  }, [formValues]);

  const onSubmit = formData => {
    formData.referrer_id = referrerId || undefined;
    formData.referral_code = referralCode || undefined;
    formData.invitation_code = invitationCode || undefined;
    setFormValues(formData);
    signUp(formData);
  };

  return (
    <SignUpForm
      className={baseClass}
      loading={isLoading}
      serverErrors={authErrors}
      passwordRequested={passwordRequested}
      headerText={headerText}
      buttonText={buttonText}
      onSubmit={values => onSubmit(values)}
    />
  );
};

SignUp.defaultProps = {
  buttonText: "Join now, It's Free",
};

SignUp.propTypes = {
  className: PropTypes.string,
  referrerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  referralCode: PropTypes.string,
  invitationCode: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
};

export default SignUp;
