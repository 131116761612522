import styled from 'styled-components';
import { colors, variables } from 'styles';

const { whiteSpace } = variables;

const checkboxBase = 1.5;
const checkboxSize = `${checkboxBase}em`;
const checkboxStep = `${checkboxBase / 25}em`;

export const PasswordVisibility = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  & > button {
    text-transform: uppercase !important;
  }
`;

export const PasswordInputWrapper = styled.div`
  /* border: 1px solid orange; */
  position: relative;
`;

export const PasswordVisibilityIcon = styled.div`
  /* border: 1px solid lime; */
  position: absolute;
  cursor: pointer;
  top: calc(50% - 8px);
  right: ${whiteSpace.normal};
  fill: ${props => (props.visible ? colors.coolGray : colors.darkGray)};
  &:after {
    display: ${props => (props.visible ? 'none' : 'block')};
    font-size: 1.4rem;
    font-weight: bold;
    position: absolute;
    content: '/';
    top: -1px;
    left: calc(50% - 3.5px);
    color: ${colors.darkGray};
    text-shadow: 0px 0px 5px ${colors.white};
  }
`;

export const CheckboxInput = styled.label`
  width: ${checkboxSize};
  height: ${checkboxSize};
  position: relative;
  & > span {
    cursor: pointer;
    position: absolute;
    width: ${checkboxSize};
    height: ${checkboxSize};
    top: 0;
    left: 0;
    background: ${colors.white};
    border: 1px solid ${colors.coolGray};
    border-radius: 3px;
    &:after {
      opacity: 0.2;
      content: '';
      position: absolute;
      width: calc(${checkboxStep} * 9);
      height: calc(${checkboxStep} * 5);
      background: transparent;
      top: calc(${checkboxStep} * 6);
      left: calc(${checkboxStep} * 6);
      border: 3px solid ${colors.darkestGray};
      border-top: none;
      border-right: none;

      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.5;
    }
  }
  input[type='checkbox'] {
    min-height: auto;
  }
  input[type='checkbox']:checked + span:after {
    opacity: 1;
  }
`;
