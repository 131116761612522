import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import Styled from './styled';

const FormRow = ({ className, children, flex }) => {
  const baseClass = classNames('form-row', className);
  return <Styled className={baseClass}>{children}</Styled>;
};

FormRow.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
};

export default FormRow;
