import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { useTracking } from 'react-tracking';
import { useAuth, useProfile } from 'hooks';
import Events from 'constants/events';

import { Loader } from 'components/UI';
import SignIn from 'components/Authentication/SignIn';
import { PasswordForm } from './PasswordForm';

const UserForm = ({ isNew, user, loggedIn, hideLabel, onSubmit }) => {
  // console.log('ChangePassword > UserForm', { isNew, loggedIn, onSubmit, user: user && user.toJS() });
  const headerText = loggedIn ? 'Set a new password' : 'Set a password to activate your account';
  return loggedIn || isNew ? (
    <PasswordForm
      headerText={hideLabel ? undefined : headerText}
      buttonText={isNew ? 'Continue' : undefined}
      isNew={isNew}
      onSubmit={onSubmit}
    />
  ) : (
    <SignIn email={user.get('email')} headerText="Enter your password to continue" buttonText="Continue" hiddenEmail />
  );
};

UserForm.propTypes = {
  user: PropTypes.instanceOf(Immutable.Map),
  isNew: PropTypes.bool,
  loggedIn: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const ChangePassword = ({ isNew, resetPasswordToken, hideLabel, onSubmit }) => {
  const tracking = useTracking();
  const { auth, authenticateFromResetToken, isLoggedIn } = useAuth();

  const { user, changePassword } = useProfile();
  const userId = user && (user.get('marketing_uuid') || user.get('id'));
  const authErrors = auth.getIn(['authErrors', 0]);
  const isLoading = !user || (!user.get('loaded') && !authErrors);

  useEffect(() => {
    if (resetPasswordToken) {
      authenticateFromResetToken(resetPasswordToken);
    }
  }, [resetPasswordToken]);

  const submitForm = values => {
    changePassword(values.newPassword || values.password);
    tracking.trackEvent({
      event: isNew ? Events.USER_ACTIVATE_ACCOUNT : Events.USER_CHANGE_PASSWORD,
      properties: { user_id: userId, email: user.get('email') },
    });
    onSubmit(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  return authErrors ? (
    <p className="error-message">No user matching this activation key can be found.</p>
  ) : (
    <UserForm isNew={isNew} user={user} loggedIn={isLoggedIn} onSubmit={v => submitForm(v)} hideLabel={hideLabel} />
  );
};

ChangePassword.defaultProps = {
  onSubmit: () => {},
};

ChangePassword.propTypes = {
  resetPasswordToken: PropTypes.string,
  isNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  hideLabel: PropTypes.bool,
};

export default ChangePassword;
