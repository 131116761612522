import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvitations } from 'modules/invitations';

const useInvitations = () => {
  const dispatch = useDispatch();
  const invitations = useSelector(state => state.invitations);

  useEffect(() => {
    dispatch(fetchInvitations());
  }, []);

  return invitations;
};

export default useInvitations;
