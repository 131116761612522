import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

export const BaseCard = styled.span`
  display: flex;
  flex-direction: column;
  /* min-height: 280px; */

  .hero-image {
    /* flex: 2; */
    ${variables.aspectRatio.theatre};
    transition: all 100ms ease-in-out;
  }

  &.hovered {
    .hero-image {
      transform: scale(1.05);
      box-shadow: 3px 3px 10px rgba($black, 0.25);
    }
  }

  &.card-hover:hover .hero-image {
    transform: scale(1.01);
    box-shadow: 3px 3px 10px ${toRgba(colors.black, 0.25)};
    transition: all 200ms ease-in-out;
  }

  .card-body {
    padding: 10px 0;
  }

  h2 {
    margin: 0 0 5px;
    font-family: ${variables.fontFamily.block};
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin: 0;
    font-family: ${variables.fontFamily.serif};
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  button {
    margin: 5px;
    border: 1px solid ${colors.white};
    color: ${colors.white};
    border-radius: 0;
    background: ${toRgba(colors.black, 0.5)};
    text-transform: uppercase;
    font-size: 14px;
  }
`;
