import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

const getPlural = (count, zeroVal, singular) => {
  const singularString = `${singular}`;
  const pluralized = `${count} ${count === 1 ? singularString : pluralize(singularString, count)}`;
  return zeroVal && count === 0 ? zeroVal : pluralized;
};

const Pluralization = ({ count, zeroValue, className, children }) => {
  if (!children) {
    return null;
  }
  return <span className={className}>{getPlural(parseInt(count, 10), zeroValue, children)}</span>;
};

Pluralization.defaultProps = {
  count: null,
  zeroValue: null,
  className: null,
  children: null,
};

Pluralization.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zeroValue: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Pluralization;
