import Immutable from 'immutable';

const SET_ALERTS = 'skylark/alerts/SET_ALERTS';
const CLOSE_ALERT = 'skylark/alerts/CLOSE_ALERT';

// Alerts subscribed to these events
// const LOCATION_CHANGED = '@@router/LOCATION_CHANGE';
const PROFILE_UPDATE_SUCCESS = 'skylark/user/UPDATE_SUCCESS';
const GET_CLIENT_TOKEN_REQUEST = 'skylark/creditCard/GET_CLIENT_TOKEN_REQUEST';
const CREATE_PAYMENT_METHOD_FAILURE = 'skylark/creditCard/CREATE_PAYMENT_METHOD_FAILURE';
const CREATE_PAYMENT_METHOD_SUCCESS = 'skylark/creditCard/CREATE_PAYMENT_METHOD_SUCCESS';
const CREATE_PAYMENT_FAILURE = 'skylark/payments/CREATE_PAYMENT_FAILURE';
const SIGNUP_SUCCESS = 'skylark/authentication/SIGNUP_SUCCESS';

const initialState = Immutable.fromJS({
  alerts: [],
});

export const setAlerts = alerts => ({ type: SET_ALERTS, alerts });

export const clearAlert = alert => ({ type: CLOSE_ALERT, alert });

const onClearAlert = (state, alert) => {
  const alerts = state.get('alerts');
  return state.set(
    'alerts',
    alerts.filter(a => a.get('message') !== alert.get('message'))
  );
};

const onProfileUpdateSuccess = (state, action) => {
  const meta = action.meta.source;
  let message;
  if (meta === 'CHANGE_PASSWORD') {
    message = 'Your password was successfully set.';
  } else {
    message = 'Your profile was updated successfully.';
  }
  return state.setIn(
    ['alerts', 0],
    Immutable.fromJS({
      type: 'success',
      message,
    })
  );
};

const onGetClientTokenRequest = (state, action) => {
  if (action.error) {
    return state.setIn(
      ['alerts', 0],
      Immutable.fromJS({
        type: 'error',
        message: 'Sorry, something went wrong adding this payment method. Please try again later.',
      })
    );
  }
  return state;
};

const onCreatePaymentMethodFailure = state =>
  state.setIn(
    ['alerts', 0],
    Immutable.fromJS({
      type: 'error',
      message: 'Sorry, something went wrong adding this payment method. Please try again later.',
    })
  );

const onCreatePaymentMethodSuccess = state =>
  state.setIn(
    ['alerts', 0],
    Immutable.fromJS({
      type: 'success',
      message: 'Credit card added successfully.',
    })
  );

const onCreatePaymentFailure = (state, action) =>
  state.setIn(
    ['alerts', 0],
    Immutable.fromJS({
      type: 'error',
      message: action.payload.response.errors,
    })
  );

const onSignupSuccess = state =>
  state.setIn(
    ['alerts', 0],
    Immutable.fromJS({
      type: 'success',
      message: 'Congratulations! Your Skylark account has been successfully created.',
    })
  );

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERTS:
      return state.set('alerts', Immutable.fromJS(action.alerts));
    case CLOSE_ALERT:
      return onClearAlert(state, action.alert);
    // case LOCATION_CHANGED:
    //   return initialState;
    case PROFILE_UPDATE_SUCCESS:
      return onProfileUpdateSuccess(state, action);
    case GET_CLIENT_TOKEN_REQUEST:
      return onGetClientTokenRequest(state, action);
    case CREATE_PAYMENT_METHOD_FAILURE:
      return onCreatePaymentMethodFailure(state, action);
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return onCreatePaymentMethodSuccess(state, action);
    case CREATE_PAYMENT_FAILURE:
      return onCreatePaymentFailure(state, action);
    case SIGNUP_SUCCESS:
      return onSignupSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
