import Immutable from 'immutable';
import moment from 'moment';
import axios from 'axios';

import { AuthHeaders } from 'modules/authentication/authentication';
import { BOOKING_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const initialState = Immutable.fromJS({});

// ACTION TYPES
const REQUEST = 'skylark/vaultSession/REQUEST';
const SUCCESS = 'skylark/vaultSession/SUCCESS';
const FAILURE = 'skylark/vaultSession/FAILURE';

export const fetchAuricVaultSession = () => async (dispatch) => {
  // console.log('requestHeaders', requestHeaders);
  const resourceUrl = `${BOOKING_API_ENDPOINT}/vault_session`;

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.get(resourceUrl, { headers: AuthHeaders() });
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      payload: respStatus,
    });
  }
};

// REDUCER HELPER METHODS
const requested = (state, action) => state.set('loading', true).set('loaded', false);

const received = (state, action) =>
  state
    .set('sessionId', Immutable.fromJS(action.payload.session_id))
    .set(
      'expires',
      moment()
        .add(10, 'm')
        .toDate()
    )
    .set('loading', false)
    .set('loaded', true);

const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    default:
      return state;
  }
}
