import { useEffect } from 'react';
import Immutable from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionInfo } from 'hooks';
import {
  update,
  empty,
  setUser,
  setBrowserSessionKey,
  setCurrencyCode,
  addItem,
  incrementItem,
  decrementItem,
  removeItem,
  build,
  persistToServer,
  processBooking,
} from 'modules/bookingCart';

const useBookingCart = () => {
  const dispatch = useDispatch();
  const sessionInfo = useSessionInfo();

  const user = useSelector(state => (state.user && state.user.get('loaded') ? state.user : Immutable.Map()));
  const bookingCart = useSelector(state => state.bookingCart);

  const settings = useSelector(state => Immutable.fromJS(state.settings));
  const currencyCode = settings.get('currencyCode');

  const browserSessionKey = sessionInfo?.key;

  // const transformCart = cart =>
  //   cart
  //     .set('hotelItems', getHotelItems())
  //     .set('hotelRoomCount', getHotelRoomCount())
  //     .set('totalPrice', getTotalPrice())
  //     .set('totalTaxes', getTotalTaxes())
  //     .set('totalFees', getTotalFees());

  const getItem = (category, id) => {
    const items = bookingCart.get('items').filter(i => i.get('id') === id && i.get('category') === category);
    return items && items.first();
  };

  const getHotelItems = () => {
    const category = 'hotelStay';
    const items = bookingCart.get('items').filter(i => i.get('category') === category);
    return items.map(i =>
      Immutable.fromJS({
        id: i.get('id'),
        quantity: i.get('quantity'),
        checkIn: i.get('checkIn'),
        checkOut: i.get('checkIn'),
        lengthOfStay: i.get('lengthOfStay'),
        hotel: {
          code: i.get('hotelCode'),
          name: i.get('hotemName'),
        },
        room: {
          code: i.get('roomCode'),
          name: i.get('roomName'),
        },
        amenities: i.get('amenities'),
        amenitiesValue: i.get('amenitiesValue'),
        rate: {
          productCode: i.get('productCode'),
          pricing: i.get('pricing'),
          rateKey: i.get('rateKey'),
          nightlyRateBreakdown: i.get('nightlyRateBreakdown'),
          guaranteeType: i.get('guaranteeType'),
          cancelBy: i.get('cancelBy'),
          prepaid: i.get('prepaidIndicator'),
        },
      })
    );
  };

  const getHotelRoomCount = () => {
    const hotelItems = getHotelItems();
    return hotelItems.map(i => i.get('quantity')).reduce((prev, current) => prev + current);
  };

  const getTotalPrice = () => {
    const hotelItems = getHotelItems();
    return hotelItems
      .map(i => i.getIn(['rate', 'pricing', 'totalPrice']) * i.get('quantity'))
      .reduce((prev, current) => prev + current);
  };

  const getHotelItemTaxes = item => item.get('quantity') * item.getIn(['rate', 'pricing', 'taxes']);

  const getHotelItemFees = item => item.get('quantity') * item.getIn(['rate', 'pricing', 'fees']);

  const getTotalTaxes = () => {
    const hotelItems = getHotelItems();
    return hotelItems.map(i => getHotelItemTaxes(i)).reduce((prev, current) => prev + current);
  };

  const getTotalFees = () => {
    const hotelItems = getHotelItems();
    return hotelItems.map(i => getHotelItemFees(i)).reduce((prev, current) => prev + current);
  };

  const getTotalTaxesFees = () => getTotalTaxes() + getTotalFees();

  const updateBooking = ({ bookingUser, primaryTraveler, passengers, paymentMethod, step }) => {
    // console.log('useBookingCart > updateBooking');
    dispatch(update({ bookingUser, primaryTraveler, passengers, paymentMethod, step }));
  };

  const addItemToBooking = ({ id, quantity, item, category }) => {
    dispatch(addItem({ id, quantity, item, category }));
  };

  const increment = ({ id, item, category }) => {
    dispatch(incrementItem({ id, item, category }));
  };

  const decrement = ({ id, category }) => {
    dispatch(decrementItem({ id, category }));
  };

  const removeItemFromBooking = item => {
    dispatch(removeItem({ id: item.get('id') }));
  };

  const clearBooking = () => {
    dispatch(empty());
  };

  const buildCheckout = requestQuery => {
    dispatch(build(requestQuery));
  };

  const persistCart = () => {
    // console.log('useBookingCart > persistCart', bookingCart);
    persistToServer(bookingCart.set('user', user).set('currencyCode', currencyCode));
  };

  const processCartBooking = () => {
    dispatch(processBooking(bookingCart));
  };

  useEffect(() => {
    if (browserSessionKey) {
      // console.log('useBookingCart > browserSessionKey', browserSessionKey);
      dispatch(setBrowserSessionKey(browserSessionKey));
    }
  }, [browserSessionKey]);

  useEffect(() => {
    if ((user && user.get('id')) !== bookingCart.getIn(['user', 'id'])) {
      // console.log('useBookingCart > userId, bookingUserId', user && user.get('id'), bookingCart.getIn(['user', 'id']));
      dispatch(setUser({ user }));
    }
  }, [user]);

  useEffect(() => {
    if (currencyCode !== bookingCart.get('currencyCode')) {
      dispatch(setCurrencyCode({ currencyCode }));
    }
  }, [currencyCode]);

  return {
    bookingCart,
    updateBooking,
    addItemToBooking,
    removeItemFromBooking,
    increment,
    decrement,
    getItem,
    getHotelItems,
    getHotelRoomCount,
    getTotalPrice,
    getTotalTaxesFees,
    clearBooking,
    buildCheckout,
    persistCart,
    processBooking: processCartBooking,
  };
};

export default useBookingCart;
