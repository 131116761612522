import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { duration } from 'components/Helpers';

import { requestMergedAvailability as requestHotelAvailability } from 'modules/hotelAvailability';
import { requestMergedAvailability as requestTripAvailability } from 'modules/availability';
import {
  getHotelAvailabilityResponse,
  getRoomPriceSummary,
  getHotelRateByProductCode,
} from 'selectors/hotelAvailabilitySelector';
//
import {
  getTripRequestStatus as tripRequestStatus,
  getRequestKey,
  getRoomAvailabilitiesByCheckInDate,
  getAvailabilityForItinerary,
  getLeadItineraries,
  getSelectedItinerary,
} from 'selectors/hotelTripSelector';

import {
  getBundleRequestDefinition as tripRequestDefinition,
  getBundleTripLeadResult as getLeadTrip,
} from 'selectors/bundleSelectors';

import { getHotelRequestDefinition as hotelRequestDefinition } from 'selectors/hotelRequestSelector';

const RATE_EXPIRATION_IN_SECONDS = 60 * 15;
const RATE_EXPIRATION_CHECK_INTERVAL = 60 * 3 * 1000; // milliseconds

const getTripResponse = (state, request) => {
  const requestKey = getRequestKey(request);
  return state.availability.get(requestKey);
};

const preloadResponse = (status, hotel, onLoadComplete) => {
  if (onLoadComplete && status && status !== 'LOADING') {
    onLoadComplete(hotel.get('code'), undefined, undefined);
  }
};

const getRequest = (hotel, query) => {
  const { check_in, check_out, cabin, origin, travelers, rooms } = query;
  const hotelOnly = !cabin;
  const destination = hotel.get('destination_code');
  const hotelRequest = hotelRequestDefinition(hotel, check_in, check_out, travelers, rooms);
  const tripRequest = tripRequestDefinition(hotel, check_in, check_out, origin, destination, travelers, rooms, cabin);
  return hotelOnly ? hotelRequest : tripRequest;
};

const getRequestStatus = (hotelOnly, request, response) =>
  hotelOnly ? response.get('requestStatus') : tripRequestStatus(request, response);

const getResponse = (hotelOnly, state, request, isMember = false) =>
  hotelOnly ? getHotelAvailabilityResponse(state, request, isMember) : getTripResponse(state, request);

const requestAvailability = (hotelOnly, request, refresh = false) =>
  hotelOnly ? requestHotelAvailability(request, refresh) : requestTripAvailability(request, refresh);

const getLeadOption = (hotelOnly, request, response) =>
  hotelOnly ? response.get('leadTrip') : getLeadTrip(request, response);

const getAvailableRoomsForItinerary = (request, response, itinerary) => {
  const roomsByDate = getRoomAvailabilitiesByCheckInDate(request, response);
  const availabilityForItinerary = getAvailabilityForItinerary(roomsByDate, itinerary);
  return availabilityForItinerary && availabilityForItinerary.get('rooms');
};

const getAvailableRooms = (hotelOnly, request, response, selectedAir) => {
  if (hotelOnly) {
    return response.get('availableRooms');
  }
  return getAvailableRoomsForItinerary(request, response, selectedAir);
};

const useCountdown = (startTime, ticker, timeout) => {
  const [ageInSeconds, setAgeInSeconds] = useState((new Date() - startTime) / 1000);
  const isTimedOut = () => ageInSeconds > timeout;
  const [expired, setExpired] = useState(isTimedOut());
  useEffect(() => {
    let interval = null;
    if (startTime) {
      // console.log('START TIME', startTime);
      interval = setInterval(() => {
        setAgeInSeconds((new Date() - startTime) / 1000);
      }, ticker);
    }
    if (isTimedOut()) {
      setExpired(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [startTime]);
  return { ageInSeconds, expired };
};

const useTripSearch = (hotel, query, isMember, onLoadComplete) => {
  // console.log('USE TRIP SEARCH', hotel && hotel.toJS(), query);
  const dispatch = useDispatch();
  const { cabin, check_in, check_out } = query;
  const lengthOfStay = duration(check_in, check_out);
  const hotelOnly = !cabin;
  const isLeadOnly = !(check_in && check_out);

  const request = useMemo(() => getRequest(hotel, query));
  const requestKey = request.get('key');
  const response = useSelector((state) => getResponse(hotelOnly, state, request, isMember));
  const leadTrip = getLeadOption(hotelOnly, request, response);
  const status = isLeadOnly ? 'LEAD' : getRequestStatus(hotelOnly, request, response);
  const isLoaded = status !== 'LOADING';

  useEffect(() => {
    if (isLoaded) {
      preloadResponse(status, hotel, onLoadComplete);
    } else {
      dispatch(requestAvailability(hotelOnly, request));
    }
  }, []);

  useEffect(() => {
    dispatch(requestAvailability(hotelOnly, request));
  }, [requestKey]);

  const airOptions = useSelector((state) => !hotelOnly && getLeadItineraries(state, response));
  const selectedAir = useSelector((state) => !hotelOnly && getSelectedItinerary(state, response));
  const roomsByDate = getRoomAvailabilitiesByCheckInDate(request, response);
  const availableRooms = getAvailableRooms(hotelOnly, request, response, selectedAir);

  const availableRates = availableRooms && availableRooms.map((room) => room.get('rates')).flatten(true);

  const leadRoom = leadTrip && leadTrip.get('room');
  const roomPricing = leadRoom && getRoomPriceSummary(leadRoom, hotelOnly);
  const leadRate = roomPricing?.get('leadRate');
  const rateSummary = response?.get('rateSummary');
  const responseTime = response?.get('responseTime');
  const compareRate = roomPricing && roomPricing.get('compareRate');

  const receivedAt = response?.get('received') && new Date(response.get('received'));

  const { ageInSeconds, expired } = useCountdown(
    receivedAt,
    RATE_EXPIRATION_CHECK_INTERVAL,
    RATE_EXPIRATION_IN_SECONDS
  );

  const getRateByCode = (productCode) => getHotelRateByProductCode(availableRates, productCode);

  return {
    request,
    lengthOfStay,
    hotelOnly,
    response,
    airOptions,
    selectedAir,
    roomsByDate,
    availableRooms,
    availableRates,
    getRateByCode,
    leadTrip,
    leadRate,
    rateSummary,
    responseTime,
    receivedAt,
    ageInSeconds,
    rateExpired: expired,
    compareRate,
    status,
  };
};

export default useTripSearch;
