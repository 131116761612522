import Immutable from 'immutable';

const initialState = Immutable.Map({
  cabinClass: null,
  departDate: null,
  destination: null,
  hotel: null,
  origin: null,
  travelerCount: 2,
  returnDate: null,
  roomCount: 1,
  selection: undefined,
});

const DO_SEARCH = 'skylark/tripSearch/DO_SEARCH';

export const setSearch = values => ({ type: DO_SEARCH, values });

const saveSearch = (state, values) => {
  const trip = Immutable.fromJS(values);
  const selection = trip.getIn(['destination', 'selection']) || trip.get('selection') || state.get('selection');
  const cabinClass = trip.get('cabinClass');
  return state
    .set('cabinClass', cabinClass)
    .set('departDate', trip.get('departDate'))
    .set('destination', trip.getIn(['destination', 'code']) || trip.get('destination'))
    .set('origin', trip.get('origin'))
    .set('hotel', trip.get('hotel'))
    .set('travelerCount', trip.get('travelerCount'))
    .set('returnDate', trip.get('returnDate'))
    .set('roomCount', trip.get('roomCount'))
    .set('selection', selection);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DO_SEARCH:
      return saveSearch(state, action.values);
    default:
      return state;
  }
};

export default reducer;
