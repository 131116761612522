import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const arrowColor = colors.darkGray;
const arrowWeight = '2px';
const height = 60;

export const ScrollArrow = styled.div`
  & > div {
    display: block;
    position: relative;
    padding-top: ${height - 11}px;
    text-align: center;
    z-index: 0;
    &:before {
      animation: elasticus 3s cubic-bezier(1, 0, 0, 1) infinite;
      position: absolute;
      top: 0px;
      left: 50%;
      margin-left: -1px;
      width: ${arrowWeight};
      height: ${height}px;
      background: ${arrowColor};
      content: ' ';
    }
    title {
      position: relative;
      display: block;
      z-index: 1;
      text-transform: uppercase;
      color: ${arrowColor};
      box-shadow: 1px 1px 5px ${colors.paleGray}, 1px 1px 10px ${colors.paleGray}, 1px 1px 15px ${colors.paleGray};
      background: ${colors.paleGray};
      margin-top: -${height / 2 + 10}px;
      font-family: ${variables.fontFamily.display};
      font-size: 15px;
      letter-spacing: 0.1em;
    }
  }

  .arrow-down {
    display: block;
    margin: 0 auto;
    width: 10px;
    &:after {
      content: '';
      display: block;
      margin: 0;
      padding: 0;
      width: 8px;
      height: 8px;
      border-top: ${arrowWeight} solid ${arrowColor};
      border-right: ${arrowWeight} solid ${arrowColor};
      behavior: url(-ms-transform.htc);
      transform: rotate(135deg);
    }
  }

  @keyframes elasticus {
    0% {
      -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
      -moz-transform-origin: 0% 0%;
      -o-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      -webkit-transform: scale(1, 0);
      -ms-transform: scale(1, 0);
      -moz-transform: scale(1, 0);
      -o-transform: scale(1, 0);
      transform: scale(1, 0);
    }
    50% {
      -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
      -moz-transform-origin: 0% 0%;
      -o-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    50.1% {
      -webkit-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
      -moz-transform-origin: 0% 100%;
      -o-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    100% {
      -webkit-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
      -moz-transform-origin: 0% 100%;
      -o-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      -webkit-transform: scale(1, 0);
      -ms-transform: scale(1, 0);
      -moz-transform: scale(1, 0);
      -o-transform: scale(1, 0);
      transform: scale(1, 0);
    }
  }
`;
