import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import Link from 'next/link';

import { useIsMounted, useAuth } from 'hooks';
import ActiveLink from 'components/UI/ActiveLink';
import { SignOut, SignInLink, SignUpLink } from 'components/Authentication';
import { CurrencyPicker } from 'components/Helpers';

import Styled from './styled';

const TripInquiryLink = () => (
  <Link href="/contact/inquire">
    <a className="start-planning-button">Can&apos;t find your trip?</a>
  </Link>
);

const Seperator = ({ children }) => <span className="seperator">{children}</span>;

Seperator.defaultProps = {
  children: ' | ',
};
Seperator.propTypes = {
  children: PropTypes.node,
};

const IdentifiedUserActions = ({ isActivatedUser, isLoggedIn, user }) =>
  isActivatedUser ? (
    <div className="right identity-column">
      <TripInquiryLink />
      <CurrencyPicker />
      <ActiveLink activeClassName="active" href="/account">
        <a>My Account</a>
      </ActiveLink>
      <Seperator />
      <SignOut context="mobile navigation" />
      {user && user.get('active_membership') && (
        <span className="membership-badge" title="Active Member">
          M
        </span>
      )}
    </div>
  ) : (
    <div className="right identity-column">
      <TripInquiryLink />
      <CurrencyPicker />
      <SignOut context="mobile navigation" />
      <Seperator />
      <ActiveLink activeClassName="active activate-button" href="/account">
        <a>Activate my account</a>
      </ActiveLink>
    </div>
  );

IdentifiedUserActions.defaultProps = {
  isActivatedUser: null,
  user: null,
};

IdentifiedUserActions.propTypes = {
  isActivatedUser: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  user: PropTypes.instanceOf(Immutable.Map),
};

const SearchActions = ({ user, className, layoutMode, scrolled, ...props }) => {
  const { isIdentified, isActivated, isLoggedIn } = useAuth();
  const mounted = useIsMounted();
  const baseClass = classNames('action-list', className, {
    'is-home': layoutMode === 'home',
    'is-scrolled': scrolled,
  });

  return (
    mounted && (
      <Styled className={baseClass} {...props}>
        {(isIdentified && !isActivated) || isLoggedIn ? (
          <IdentifiedUserActions isActivatedUser={isActivated} isLoggedIn={isLoggedIn} user={user} />
        ) : (
          <div className="right identity-column">
            <TripInquiryLink />
            <CurrencyPicker />
            <SignInLink context="header-actions">Log in</SignInLink>
            <Seperator> </Seperator>
            <SignUpLink button className="button-cta join-now" context="header-actions">
              JOIN NOW, IT'S FREE
            </SignUpLink>
          </div>
        )}
      </Styled>
    )
  );
};

SearchActions.defaultProps = {
  className: null,
  isLoggedIn: null,
  isActivatedUser: null,
  user: null,
};

SearchActions.propTypes = {
  className: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  layoutMode: PropTypes.string,
  scrolled: PropTypes.bool,
  isActivatedUser: PropTypes.bool,
  user: PropTypes.instanceOf(Immutable.Map),
};

export default SearchActions;
