import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { requestCollectionSet } from 'modules/collections';

const useCollections = collectionIds => {
  const dispatch = useDispatch();
  useSelector
  const collections = useSelector(state => state.collections);

  // console.log('hotel', hotel && hotel.toJS());

  useEffect(() => {
    if (!collections || !collections.get('loading') == true) {
      dispatch(requestCollectionSet(collectionIds));
    }
  }, [collectionIds]);

  return collections;
};

export default useCollections;
