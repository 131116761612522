import React from 'react';
import { PropTypes } from 'prop-types';
import track, { useTracking } from 'react-tracking';

import { useModal } from 'hooks';
import { Button } from 'components/UI';
import Events from 'constants/events';

import SignIn from './index';

const SignInLink = ({ children, button }) => {
  const tracking = useTracking();
  const [openModal] = useModal();

  const onClick = () => {
    const SignInForm = <SignIn headerText="Welcome back" className="modal-sign-in" />;
    tracking.trackEvent({ event: Events.USER_CLICKED_SIGN_IN });
    openModal('COMPONENT', { component: SignInForm });
  };

  return button ? (
    <Button onClick={() => onClick()}>{children}</Button>
  ) : (
    <Button.Link onClick={() => onClick()}>{children}</Button.Link>
  );
};

SignInLink.defaultProps = {
  children: 'Log In',
  button: false,
};

SignInLink.propTypes = {
  children: PropTypes.node,
  button: PropTypes.bool,
};

export default track(() => ({ components: ['Modal SignIn Link'] }))(SignInLink);
