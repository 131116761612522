import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Flag from 'components/Helpers/Flag';

const OptionDisplay = ({ flag, code }) => (
  <div className="option-content-wrapper">
    <div className="option-content">
      <Flag countryCode={flag} />
      <span>{code}</span>
    </div>
  </div>
);

OptionDisplay.propTypes = {
  flag: PropTypes.string,
  code: PropTypes.string,
};

export const SingleValue = ({ data, ...otherProps }) => {
  const { flag, code } = data;
  return (
    <components.SingleValue {...otherProps}>
      <OptionDisplay flag={flag} code={code} />
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.instanceOf(Object),
};

const SearchOption = ({ data, ...otherProps }) => {
  const { flag, code } = data;
  return (
    <components.Option {...otherProps}>
      <OptionDisplay flag={flag} code={code} />
    </components.Option>
  );
};

SearchOption.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default SearchOption;
