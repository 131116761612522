import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import queryString from 'query-string';

const getQueryParams = (router) => {
  const isServer = typeof window === 'undefined';
  const browserQuery = isServer ? {} : queryString.parse(window.location.search);
  const mergedQuery = Object.assign(router.query, browserQuery);
  return mergedQuery;
};

const normalizeQuery = (query) => {
  const checkIn = query.check_in || query.checkIn || query.ci;
  const checkOut = query.check_out || query.checkOut || query.co;
  const passengers = query.travelers || query.p;
  const rooms = query.rooms || query.r;
  return Object.assign(query, {
    checkIn,
    checkOut,
    passengers: passengers && parseInt(passengers, 10),
    rooms: rooms && parseInt(rooms, 10),
    cabin: query.cabin,
    cabinClass: query.cabin,
    originCode: query.origin,
  });
};

const useTripQuery = () => {
  const router = useRouter()
  const fullQuery = getQueryParams(router);
  const query = normalizeQuery(fullQuery);

  const {
    checkIn,
    checkOut,
    passengers,
    rooms
  } = query

  return {
    checkIn,
    checkOut,
    passengers,
    rooms
  };
};

export default useTripQuery;
