import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Content.styled';

const ContentGrid = ({ children, columns = [3], ...props }) => (
  <Styled.ContentGrid columns={columns} {...props}>
    {children}
  </Styled.ContentGrid>
);

ContentGrid.propTypes = {
  columns: PropTypes.instanceOf(Array),
  children: PropTypes.node,
};

export default ContentGrid;
