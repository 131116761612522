import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Styled from './BaseCard.styled';

const BaseCard = ({ className, loading, onMouseEnter, onMouseLeave, children }) => {
  const baseClass = classNames({ waiting: loading }, className);
  return (
    <Styled.BaseCard
      onMouseUp={onMouseLeave}
      className={baseClass}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Styled.BaseCard>
  );
};

BaseCard.defaultProps = {
  loading: false,
};

BaseCard.propTypes = {
  loading: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default BaseCard;
