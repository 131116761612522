import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { toggleNavigation } from 'modules/navigation';
import Styled from './styled';

// import './index.scss';

const BurgerButton = ({ layoutMode, scrolled, ...props }) => {
  const dispatch = useDispatch();
  const navigation = useSelector(state => state.navigation);

  const baseClass = classNames('b-container', {
    'is-home': layoutMode === 'home',
    'is-scrolled': scrolled,
    open: navigation.get('expanded'),
  });

  return (
    <div {...props} onClick={() => dispatch(toggleNavigation())}>
      <Styled className={baseClass}>
        <div className="b-menu">
          <div className="b-bun b-bun--top" />
          <div className="b-bun b-bun--mid" />
          <div className="b-bun b-bun--bottom" />
        </div>
      </Styled>
    </div>
  );
};

BurgerButton.propTypes = {
  layoutMode: PropTypes.string,
  scrolled: PropTypes.bool,
};

export default BurgerButton;
