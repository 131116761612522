import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'modules/modals';

const useModal = () => {
  const dispatch = useDispatch();
  const open = (modalType, props = {}) => dispatch(openModal(modalType, props));
  const close = () => dispatch(closeModal());

  return [open, close];
};

export default useModal;
