import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  trips: [],
});

const GET_WATCH_LIST_REQUEST = 'skylark/watchList/GET_WATCH_LIST_REQUEST';
const GET_WATCH_LIST_SUCCESS = 'skylark/watchList/GET_WATCH_LIST_SUCCESS';
const GET_WATCH_LIST_FAILURE = 'skylark/watchList/GET_WATCH_LIST_FAILURE';

const CREATE_TRIP_WATCH_REQUEST = 'skylark/watchList/CREATE_TRIP_WATCH_REQUEST';
const CREATE_TRIP_WATCH_SUCCESS = 'skylark/watchList/CREATE_TRIP_WATCH_SUCCESS';
const CREATE_TRIP_WATCH_FAILURE = 'skylark/watchList/CREATE_TRIP_WATCH_FAILURE';

const REMOVE_TRIP_WATCH_REQUEST = 'skylark/watchList/REMOVE_TRIP_WATCH_REQUEST';
const REMOVE_TRIP_WATCH_SUCCESS = 'skylark/watchList/REMOVE_TRIP_WATCH_SUCCESS';
const REMOVE_TRIP_WATCH_FAILURE = 'skylark/watchList/REMOVE_TRIP_WATCH_FAILURE';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';

const shouldBailout = state => state.watchList.get('loading') === true || state.watchList.get('loaded') === true;

export const getWatchList = userId => ({
  [CALL_API]: {
    endpoint: `${CONTENT_API_ENDPOINT}/trip_watches?user_id=${userId}`,
    method: 'GET',
    types: [GET_WATCH_LIST_REQUEST, GET_WATCH_LIST_SUCCESS, GET_WATCH_LIST_FAILURE],
    bailout: state => shouldBailout(state),
  },
});

export const watchTrip = (
  userId,
  originCode,
  destinationCode,
  hotelCode,
  checkIn,
  checkOut,
  cabinClass,
  emailAddress = null,
  emailList = null
) => ({
  [CALL_API]: {
    endpoint: `${CONTENT_API_ENDPOINT}/trip_watches`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_id: userId,
      origin_code: originCode,
      destination_code: destinationCode,
      hotel_code: hotelCode,
      check_in: checkIn,
      check_out: checkOut,
      cabin_class: cabinClass,
      email: emailAddress,
      email_list: emailList,
    }),
    types: [
      {
        type: CREATE_TRIP_WATCH_REQUEST,
        meta: {
          trip: {
            user_id: userId,
            origin_code: originCode,
            destination_code: destinationCode,
            hotel_code: hotelCode,
            check_in: checkIn,
            check_out: checkOut,
            cabin_class: cabinClass,
            email: emailAddress,
          },
        },
      },
      {
        type: CREATE_TRIP_WATCH_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      CREATE_TRIP_WATCH_FAILURE,
    ],
    bailout: state => state.watchList.get('loading'),
  },
});

export const watchStay = (userId, hotelCode, checkIn, checkOut) =>
  watchTrip(userId, null, null, hotelCode, checkIn, checkOut, null);

export const unwatchTrip = (userId, originCode, destinationCode, hotelCode, checkIn, checkOut, cabinClass) => ({
  [CALL_API]: {
    endpoint: `${CONTENT_API_ENDPOINT}/trip_watches`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_id: userId,
      origin_code: originCode,
      destination_code: destinationCode,
      hotel_code: hotelCode,
      check_in: checkIn,
      check_out: checkOut,
      cabin_class: cabinClass,
    }),
    types: [REMOVE_TRIP_WATCH_REQUEST, REMOVE_TRIP_WATCH_SUCCESS, REMOVE_TRIP_WATCH_FAILURE],
    bailout: state => state.watchList.get('loading'),
  },
});

export const unwatchStay = (userId, hotelCode, checkIn, checkOut) =>
  unwatchTrip(userId, null, null, hotelCode, checkIn, checkOut, null);

const addTrip = (state, trip) => {
  const trips = state.trips || Immutable.List();
  return trips.push(trip);
};

const removeTrip = (state, trip) => {
  const trips = state.trips || Immutable.List();
  return trips.filterNot(
    t =>
      t.get('hotel_code') === trip.get('hotel_code') &&
      t.get('check_in') === trip.get('check_in') &&
      t.get('check_out') === trip.get('check_out')
  );
};

const reducer = (state = initialState, action) => {
  const trip = action.meta && action.meta.trip ? Immutable.fromJS(action.meta.trip) : null;

  switch (action.type) {
    case GET_WATCH_LIST_REQUEST:
      return state.set('loading', true).set('loaded', false);
    case CREATE_TRIP_WATCH_REQUEST:
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('trips', addTrip(state, trip));
    case REMOVE_TRIP_WATCH_REQUEST:
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('trips', removeTrip(state, trip));
    case GET_WATCH_LIST_SUCCESS:
    case REMOVE_TRIP_WATCH_SUCCESS:
    case CREATE_TRIP_WATCH_SUCCESS:
      return state
        .set('trips', Immutable.fromJS(action.payload))
        .set('loaded', true)
        .set('loading', false);
    case CREATE_TRIP_WATCH_FAILURE:
    case REMOVE_TRIP_WATCH_FAILURE:
      return state.set('loaded', false).set('loading', false);
    case AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
