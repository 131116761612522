import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { OriginSearch } from 'components/Controls';

import FormFieldWrapper from '../FormFieldWrapper';

export { default as Text } from './TextInput';
export { default as TextArea } from './TextArea';
export { default as Password } from './PasswordInput';
export { default as Select } from './SelectInput';
export { default as Checkbox } from './CheckboxInput';
export { default as Hidden } from './HiddenInput';
export { default as ExpirationDate } from './ExpirationDate';

export const OriginAirport = ({ control, label, flex, hint, error, ...rest }) => (
  // console.log('x');
  <FormFieldWrapper label={label} error={error} hint={hint} flex={flex}>
    {control && (
      <Controller
        control={control}
        name={rest.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <OriginSearch
            inputId={name}
            selectedAirportCode={value || 'NYC'}
            {...rest}
            onBlur={onBlur}
            onChange={v => onChange(v.code)}
          />
        )}
      />
    )}
  </FormFieldWrapper>
);

OriginAirport.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  flex: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  control: PropTypes.instanceOf(Object),
};

// export default TextInput;
