import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AsyncSelect } from 'components/FormElements';

import SearchOption, { SingleValue } from './SearchOption';

import './index.scss';

const AsyncSearch = ({ query, selection, placeholder, onInputChange, onSelectionChange, optionLoader, ...props }) => {
  const [_query, setQuery] = useState(query);
  const [_selection, setSelection] = useState(selection);

  useEffect(() => {
    setSelection(selection);
  }, [selection]);

  useEffect(() => {
    onInputChange(_query);
  }, [_query]);

  const selectionChanged = (option) => {
    setSelection(option);
    onSelectionChange(option);
  };

  return (
    <AsyncSelect
      value={_selection || {}}
      isClearable
      escapeClearsValue
      valueKey="code"
      labelKey="name"
      placeholder={placeholder}
      className="multisearch-select-container"
      classNamePrefix="multisearch-select"
      components={{ Option: SearchOption, SingleValue }}
      loadOptions={(q) => optionLoader(q)}
      defaultOptions={_selection && [_selection]}
      onChange={(v) => selectionChanged(v)}
      noOptionsMessage={() => (_query ? `No match for "${_query}" found` : 'Search for a hotel or destination')}
      onInputChange={(v) => setQuery(v)}
      {...props}
    />
  );
};

AsyncSearch.defaultProps = {
  placeholder: 'Enter Something to Search for',
  onInputChange: () => {},
};

AsyncSearch.propTypes = {
  query: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  optionLoader: PropTypes.func,
};

export default AsyncSearch;
