import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

export const BorderContainer = styled.div`
  border: 1px solid ${colors.coolGray};
  border-radius: 5px;
  padding: 0.75rem 1rem;
  max-width: 520px;
  margin: 0.5rem auto;

  h3 {
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }
`;
