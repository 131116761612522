import Immutable from 'immutable';

// Actions
const EXPAND = 'skylark/navigation/EXPAND';
const COLLAPSE = 'skylark/navigation/COLLAPSE';
const TOGGLE = 'skylark/navigation/TOGGLE';

const MODAL_OPEN = 'skylark/modals/OPEN';

export const initialState = Immutable.fromJS({
  expanded: false,
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXPAND:
      return state.set('expanded', true);
    case COLLAPSE:
      return state.set('expanded', false);
    case TOGGLE:
      return state.set('expanded', !state.get('expanded'));
    case MODAL_OPEN:
      return state.set('expanded', false);
    default:
      return state;
  }
}

// Action Creators
export function expandNavigation() {
  return { type: EXPAND };
}

export function collapseNavigation() {
  return { type: COLLAPSE };
}

export function toggleNavigation() {
  return { type: TOGGLE };
}
