import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from './Header';
import SiteFooter from './Footer';
import * as Styled from './PageLayout.styled';

const PageLayout = ({ className, pageMode, headerHeight, subhead, children, subheadHeight, layoutOptions }) => {
  const baseClass = classNames('site-wrapper', className);
  const showHeader = layoutOptions && layoutOptions.header;
  const showFooter = layoutOptions && layoutOptions.footer;

  const paddingTop = showHeader && headerHeight + subheadHeight;

  const bodyClass = classNames('site-body', {
    'full-page': !showHeader && !showFooter,
  });

  return (
    <Styled.SiteWrapper className={baseClass} style={{ paddingTop }}>
      {showHeader && (
        <Header className="layout-header" layoutMode={pageMode}>
          {subhead}
        </Header>
      )}
      <div className={bodyClass}>{children}</div>
      {showFooter && <SiteFooter />}
    </Styled.SiteWrapper>
  );
};

PageLayout.defaultProps = {
  headerHeight: 60,
  pageMode: 'page',
  className: null,
  subhead: null,
  children: null,
  subheadHeight: 0,
  layoutOptions: { header: true, footer: true },
};

PageLayout.propTypes = {
  headerHeight: PropTypes.number,
  pageMode: PropTypes.string,
  className: PropTypes.string,
  subhead: PropTypes.any,
  children: PropTypes.node,
  subheadHeight: PropTypes.number,
  layoutOptions: PropTypes.instanceOf(Object),
};

export default React.memo(PageLayout);
