import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import { Currency } from 'components/Helpers';
import StyledSliderWrapper from '../StyledSliderWrapper';

// TODO: Create generic CurrencyWithTextFallback Component
const ValueDisplay = ({ value, range }) =>
  value === null || value === range[1] ? 'Show All' : <Currency round>{value}</Currency>;

ValueDisplay.propTypes = {
  value: PropTypes.number,
  range: PropTypes.arrayOf(PropTypes.number),
};

const PriceSlider = ({ value, range, label, onChange }) => (
  <StyledSliderWrapper className="slider-component">
    <label htmlFor="range-slider">
      {label} - <ValueDisplay value={value} range={range} />
    </label>
    <Slider
      min={range[0]}
      max={range[1]}
      value={value ? Number(value) : range[1]}
      step={1000}
      onChange={v => {
        onChange(v);
      }}
    />
  </StyledSliderWrapper>
);

PriceSlider.defaultProps = {
  label: 'Max Price',
  value: 10000,
  range: [0, 10000],
  onChange: () => null,
};

PriceSlider.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  range: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export default PriceSlider;
