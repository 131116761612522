import styled from 'styled-components';
import { colors, toRgba } from 'styles';

const Styled = styled.div`
  height: 35px;

  .branding {
    width: 195px;
    position: relative;
    flex: 0;
    display: inline-block;
    margin-right: 10px;
    transition: all 0.2s ease;
    transition-delay: 0s;
  }

  .skylark-wordmark {
    fill: ${colors.black};
    transition: all 0.5s ease;
    transition-delay: 0.2s;
  }

  .skylark-logo {
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease;
    transition-delay: 0s;
  }

  &.is-scrolled {
    .branding {
      width: 50px;
      margin-right: 0px;
      transition: all 0.3s ease;
      transition-delay: 0.2s;
    }
    .skylark-wordmark {
      opacity: 0;
      width: 0;
      transition: opacity 0.5s ease;
      transition: width 0.1s 0.5s ease;
      transition-delay: 0s;
    }

    .skylark-logo {
      opacity: 1;
      transition: opacity 0.25s ease;
      transition-delay: 0.25s;
    }
  }
`;

export default Styled;
