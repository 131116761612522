import React from 'react';
import { PropTypes } from 'prop-types';
import { useTracking } from 'react-tracking';
import Link from 'next/link';
import { useModal } from 'hooks';
import { Button } from 'components/UI';
import { Events } from 'components/Analytics/AnalyticsService';

import SignUp from './index';

const SignUpLinkModal = ({ button, context, children, ...props }) => {
  const tracking = useTracking();
  const [openModal] = useModal();

  const signUp = () => {
    const SignUpForm = <SignUp headerText="New to Skylark?" className="modal-sign-up" />;
    tracking.trackEvent({ event: Events.USER_CLICKED_SIGN_UP, context });
    openModal('COMPONENT', { component: SignUpForm });
  };

  return button ? (
    <Button {...props} onClick={signUp}>
      {children}
    </Button>
  ) : (
    <Button.Link {...props} onClick={signUp}>
      {children}
    </Button.Link>
  );
};

SignUpLinkModal.propTypes = {
  children: PropTypes.node,
  context: PropTypes.string,
  button: PropTypes.bool,
};

const JoinLink = ({ button, context, children, ...props }) => {
  const tracking = useTracking();

  const signUp = () => {
    tracking.trackEvent({ event: Events.USER_CLICKED_SIGN_UP, context });
  };

  return (
    <Link href="/join">
      {button ? (
        <Button {...props} onClick={signUp}>
          {children}
        </Button>
      ) : (
        <Button.Link {...props} onClick={signUp}>
          {children}
        </Button.Link>
      )}
    </Link>
  );
};

JoinLink.propTypes = {
  children: PropTypes.node,
  context: PropTypes.string,
  button: PropTypes.bool,
};

const SignUpLink = ({ button, context, children, modal, ...props }) =>
  modal ? (
    <SignUpLinkModal button={button} context={context} {...props}>
      {children}
    </SignUpLinkModal>
  ) : (
    <JoinLink button={button} context={context} {...props}>
      {children}
    </JoinLink>
  );

SignUpLink.defaultProps = {
  children: "JOIN NOW, IT'S FREE",
  button: false,
  modal: true,
};

SignUpLink.propTypes = {
  children: PropTypes.node,
  context: PropTypes.string,
  button: PropTypes.bool,
  modal: PropTypes.bool,
};

export default SignUpLink;
