import styled from 'styled-components';
import { variables, colors, toRgba } from 'styles';

export const SiteWrapper = styled.div`
  font-family: ${variables.fontFamily.body};
  min-height: 250px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: ${colors.paleGray};
  & > * {
    flex: 1;
    width: 100%;
    header:first-of-type,
    footer:last-of-type {
      flex: 0;
    }
  }

  @media (max-width: ${variables.screen.xs}) {
    justify-content: flex-start;
  }

  .subhead {
    background: ${colors.warmGray};
    border-top: 1px solid ${colors.darkGray};
    border-bottom: 1px solid ${colors.darkGray};

    @media (max-width: ${variables.screen.xs}) {
      display: none;
    }
    & > div {
      display: flex;
      align-items: center;

      .index-sub-nav {
        border: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        & > li {
          border-left: 1px solid ${colors.darkGray};
          @media (max-width: ${variables.screen.xs}) {
            flex: 1;
            text-align: center;
          }
          a {
            display: inline-block;
            padding: 5px 15px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
              text-decoration: underline;
              background: transparent;
            }
            &.active {
              color: ${colors.skylarkPurple};
              background: transparent;
              outline: none;
              a {
                outline: none;
              }
            }
          }
          &:last-child {
            a {
              border-right: 1px solid ${colors.darkGray};
            }
          }
        }
      }
    }
  }
`;
