import Immutable from 'immutable';
import axios from 'axios';

import { AuthHeaders } from 'modules/authentication/authentication';

import { AUTH_API_ENDPOINT } from 'constants/endpoints';
// import { SESSION_TIMEOUT } from 'constants/config';

export const initialState = Immutable.fromJS({ items: [], existingUsers: [], loading: false, loaded: false });

// ACTION TYPES
const REQUEST = 'skylark/invitations/REQUEST';
const SUCCESS = 'skylark/invitations/SUCCESS';
const FAILURE = 'skylark/invitations/FAILURE';

const ADD_REQUEST = 'skylark/invitations/ADD_REQUEST';
const ADD_SUCCESS = 'skylark/invitations/ADD_SUCCESS';
const ADD_FAILURE = 'skylark/invitations/ADD_FAILURE';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';

export const fetchInvitations = () => async dispatch => {
  // console.log('requestHeaders', requestHeaders);
  const resourceUrl = `${AUTH_API_ENDPOINT}/invitations`;

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.get(resourceUrl, { headers: AuthHeaders() });
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      payload: respStatus,
    });
  }
};

export const addInvitations = emailList => async dispatch => {
  const resourceUrl = `${AUTH_API_ENDPOINT}/invitations`;

  dispatch({
    type: ADD_REQUEST,
  });

  try {
    const results = await axios.post(resourceUrl, { email: emailList }, { headers: AuthHeaders() });
    dispatch({
      type: ADD_SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: ADD_FAILURE,
      payload: respStatus,
    });
  }
};

// REDUCER HELPER METHODS
const requested = state => state.set('loading', true).set('loaded', false);

const received = (state, action) =>
  state
    .set('items', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', true);

const addReceived = (state, action) =>
  state
    .set('items', Immutable.fromJS(action.payload.invitations))
    .set('existingUsers', Immutable.fromJS(action.payload.existing_users))
    .set('loading', false)
    .set('loaded', true);

const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
    case ADD_REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case ADD_SUCCESS:
      return addReceived(state, action);
    case FAILURE:
    case ADD_FAILURE:
      return failed(state, action);
    case AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
