import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormFieldWrapper from '../FormFieldWrapper';
import * as Styled from './Input.styled';

const CheckboxInput = ({ name, label, hint, error, register, control, errorPosition, flex, ...props }) => (
  <FormFieldWrapper label={label} error={error} hint={hint} flex={flex} errorPosition={errorPosition}>
    <Styled.CheckboxInput>
      <input
        type="checkbox"
        name={name}
        className={classNames({ 'field-input-error': !!error })}
        control={control}
        {...register(name)}
        {...props}
      />
      <span />
    </Styled.CheckboxInput>
  </FormFieldWrapper>
);

CheckboxInput.defaulProps = {
  type: 'text',
};

CheckboxInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  errorPosition: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  flex: PropTypes.number,
};

export default CheckboxInput;
