import { useState, useEffect } from 'react';

const useAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [segmentUser, setSegmentUser] = useState();
  // console.log('useAnalytics');

  const trackPage = (url, properties) => {
    // console.log('tp', url, analytics); // HACK: sometimes wasn't getting called without this
    const pageName = properties.page_name;
    analytics.page(pageName, { source: 'next', env: process.env.NODE_ENV, url, ...properties });
  };

  const isClient = typeof window !== 'undefined';

  useEffect(() => {
    if (isClient) {
      // console.log('useAnalytics > setAnalytics');
      setAnalytics(window.analytics);
    }
  }, [isClient]);

  // useEffect(() => {
  //   console.log('segmentUser', segmentUser);
  // }, [segmentUser]);

  useEffect(() => {
    if (analytics) {
      // console.log('useAnalytics > present');
      analytics.ready(() => {
        // console.log('useAnalytics > ready');
        const analyticsUser = window.analytics.user();
        const segmentAnonymousId = analyticsUser.anonymousId();
        const segmentUserId = analyticsUser.id();
        setSegmentUser({
          id: segmentUserId,
          anonymousId: segmentAnonymousId,
        });
      });
    }
  }, [analytics]);

  return { analytics, analyticsUser: segmentUser, trackPage };
};

export default useAnalytics;
