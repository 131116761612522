import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Immutable from 'immutable';
import { getAuthToken, setAuthToken, clearAuthToken, SESSION_TIMEOUT } from 'constants/config';
import AnalyticsService, { Events } from 'components/Analytics/AnalyticsService';
import atob from 'atob';

const skylarkSessionId = () => {
  let sessionId = Cookies.get('sky_session_id');
  if (!sessionId) {
    sessionId = uuidv4();
  }
  Cookies.set('sky_session_id', sessionId);
  return sessionId;
};

const getTokenPayload = () => {
  const jwt = getAuthToken();
  const payloadSection = jwt && jwt.split('.')[1];
  const parsedPayload = payloadSection && JSON.parse(atob(payloadSection));
  // console.log('getTokenPayload', { jwt, payloadSection, parsedPayload });
  return parsedPayload;
};

const skylarkAnonymousId = () => {
  let anonymousId = Cookies.get('sky_anonymous_id');
  if (!anonymousId) {
    anonymousId = uuidv4();
  }
  Cookies.set('sky_anonymous_id', anonymousId, { expires: 365 });
  return anonymousId;
};

export const initialState = () => {
  const tokenPayload = getTokenPayload();
  // console.log('authentication > initialState', { tokenPayload });
  return Immutable.Map({
    loggedIn: tokenPayload && !!tokenPayload.logged_in,
    canSetPassword: false,
    loading: false,
    uuid: null,
    authErrors: Immutable.List(),
    skylarkAnonymousId: skylarkAnonymousId(),
    sessionId: skylarkSessionId(),
    passwordResetToken: null,
    passwordRequested: false,
    status: null,
  });
};

const analyticsUser = user => ({
  user_id: user.id,
  activated: user.activated,
  email: user.email,
  user_name: user.name,
});

const setUserAuthState = (state, user) => {
  const tokenPayload = getTokenPayload();
  // console.log('setUserAuthState', { user, payload });
  return state
    .set('authErrors', Immutable.List())
    .set('id', user.id)
    .set('uuid', user.marketing_uuid)
    .set('email', user.email)
    .set('loggedIn', !!user.logged_in || !!tokenPayload.logged_in)
    .set('canSetPassword', !!user.new_user)
    .set('passwordResetToken', user.password_reset_token)
    .set('status', user.status)
    .set('loading', false);
};

const setAuthState = (state, payload) => {
  const { skylarkJWT, user } = payload;
  // console.log('setAuthState', { payload });
  setAuthToken(skylarkJWT);
  Cookies.set('skylarkUserId', payload.user.id, { expires: SESSION_TIMEOUT }); // TODO: Only used in AnalyticsService - remove
  return setUserAuthState(state, user);
};

const clearAuthState = (state, isSignOut) => {
  if (isSignOut) {
    Cookies.remove('sky_anonymous_id');
  }
  Cookies.remove('skylarkAuthToken'); // Remove Legacy Token
  Cookies.remove('skylarkUserId');
  clearAuthToken();
  Cookies.remove('emailAddress');
  AnalyticsService.reset();
  return initialState();
};

const identifyRequestError = (state, action) => {
  const { payload } = action;
  console.log('identifyRequestError', payload);
  const { name } = payload;
  const newState = clearAuthState(state);
  if (name === 'RequestError') {
    return newState.set('authErrors', Immutable.List(['We are having trouble reaching the server. Please try again.']));
  }
  return newState.set('authErrors', Immutable.List(action.payload.response.errors));
};

export const onTripWatchCreated = (state, payload) => setAuthState(state, payload);

export const onSignInSuccess = (state, payload) => {
  const { user } = payload;
  const authState = setAuthState(state, payload);
  AnalyticsService.trackReducer(Events.USER_SIGNED_IN, analyticsUser(user));
  return authState;
};

export const onIdentifySuccess = (state, payload) => {
  const { user } = payload;
  const authState = setAuthState(state, payload);
  AnalyticsService.trackReducer(Events.USER_IDENTIFIED, analyticsUser(user));
  return authState;
};

export const onIdentifyRequest = (state, action) => {
  if (action && action.error) {
    return identifyRequestError(state, action);
  }
  return state.set('loading', true);
};

export const onAuthenticateSuccess = (state, payload) => {
  const { user } = payload;
  // console.log('authentication > onIdentifySuccess', user);
  AnalyticsService.identify(Immutable.fromJS(user));
  return setAuthState(state, payload);
};

export const onSignupSuccess = (state, payload) => {
  // console.log('authentication > onSignupSuccess', payload);
  const { user } = payload;

  AnalyticsService.trackReducer(Events.USER_REGISTERED, analyticsUser(user));
  return onAuthenticateSuccess(state, payload);
};

export const onUserProfileLoaded = (state, action) => {
  const user = action.payload;
  return setUserAuthState(state, user);
  // state.set('loggedIn', true).set('canSetPassword', action.payload.new_user);
};

export const onSignInFailure = (state, action) => {
  console.log('onSignInFailure > state', state);
  console.log('onSignInFailure > action', action);
  const errorMessage = action.payload.response.errors.join(', ');
  AnalyticsService.trackReducer(Events.USER_SIGN_IN_FAILED, {
    email: action.meta && action.meta.email,
    error: errorMessage,
  });
  const newState = clearAuthState(state);
  if (action.payload.status === 500) {
    return newState.set('authErrors', Immutable.List(action.payload.response.errors));
  }
  return newState.set('authErrors', Immutable.List(action.payload.response.errors));
};

export const onSignUpFailure = (state, action) => {
  const { errors } = action.payload.response;
  const errorsArray = Object.keys(errors).map(key => `${key[0].toUpperCase()}${key.slice(1)} ${errors[key]}`);
  const errorMessage = errorsArray.join(', ');
  AnalyticsService.trackReducer(Events.USER_REGISTRATION_FAILED, { email: action.meta.email, error: errorMessage });
  const newState = clearAuthState(state);
  if (action.payload.status === 500) {
    return newState.set('authErrors', Immutable.List(errorsArray));
  }
  return newState.set('authErrors', Immutable.List(errorsArray));
};

export const onSignOut = state => {
  AnalyticsService.trackReducer(Events.USER_SIGNED_OUT);
  return clearAuthState(state, true);
};

export const onProfileNotFound = state => {
  AnalyticsService.trackReducer(Events.PROFILE_NOT_FOUND);
  return clearAuthState(state);
};

export const onForgotPasswordRequest = (state, action) => {
  AnalyticsService.trackReducer(Events.REQUESTED_PASSWORD_RESET_SENT, { email: action.meta.email });
  return clearAuthState(state);
};

export const onForgotPasswordSuccess = state => state.set('passwordRequested', true);

export const onForgotPasswordFailure = (state, action) => {
  // console.log('onForgotPasswordFailure > action', action);
  const errorMessage = action.payload.response.errors.join(', ');
  AnalyticsService.trackReducer(Events.REQUESTED_PASSWORD_RESET_FAILED, {
    email: action.meta && action.meta.email,
    error: errorMessage,
  });
  const newState = clearAuthState(state);
  if (action.payload.status === 500) {
    return newState.set('authErrors', Immutable.List(action.payload.response.errors));
  }
  return newState.set('authErrors', Immutable.List(action.payload.response.errors));
};

export const onSignUpRequest = state => state.set('loading', true);
