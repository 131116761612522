import Immutable from 'immutable';
import axios from 'axios';
import { CALL_API } from 'redux-api-middleware';

import { CONTENT_API_ENDPOINT, HOTEL_API_ENDPOINT, requestHeaders } from 'constants/endpoints';

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  items: {},
});

// ACTION TYPES
const REQUEST = 'skylark/hotels/REQUEST';
const SUCCESS = 'skylark/hotels/SUCCESS';
const FAILURE = 'skylark/hotels/FAILURE';

const hotelLoaded = (state, code) => state.hotels.getIn(['items', code]);
const validCode = code => code.length === 7;
const invalidCode = code => !validCode(code);

// ACTION CREATORS
export function requestHotel(code = '') {
  const endpoint = `${HOTEL_API_ENDPOINT}/hotels/${code}`;
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      types: [
        { type: REQUEST, meta: { code } },
        { type: SUCCESS, meta: { code } },
        { type: FAILURE, meta: { code } },
      ],
      bailout: state => invalidCode(code) || hotelLoaded(state, code),
    },
  };
}

export const fetchHotel = async hotelCode => {
  const resourceUrl = `${HOTEL_API_ENDPOINT}/hotels/${hotelCode || 1}`;

  let hotel = { status: 'loading' };

  try {
    const results = await axios.get(resourceUrl, { headers: requestHeaders });
    const { data } = await results;
    // console.error('DATA!', data);
    hotel = { status: 'loaded', ...data };
    return hotel;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    hotel = { status: respStatus, error: e.response?.statusText };
    console.error('FAILURE!', { hotel });
    return hotel;
  }
};

// REDUCER HELPER METHODS
const hotelRequested = (state, code) =>
  state.setIn(['items', code, 'loading'], true).setIn(['items', code, 'loaded'], false);

const hotelReceived = (hotel, state, code) =>
  state.setIn(
    ['items', code],
    Immutable.fromJS(hotel)
      .set('loading', false)
      .set('loaded', true)
  );

const requestFailed = (state, code) =>
  state.setIn(['items', code, 'loading'], false).setIn(['items', code, 'loaded'], false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  const code = action.meta ? action.meta.code : null;
  switch (action.type) {
    case REQUEST:
      return hotelRequested(state, code);
    case SUCCESS:
      return hotelReceived(action.payload, state, code);
    case FAILURE:
      return requestFailed(state, code);
    default:
      return state;
  }
}
