import React, { useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import SkylarkPropTypes from 'lib/SkylarkPropTypes';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import Events from 'constants/events';

import { getSearchInfo, getPageViewType } from 'lib/trackingHelpers';

import { setSearch } from 'modules/tripSearch';
import tripSearchSelector from 'selectors/tripSearchSelector';
import { getTargetRoute, validateSearch } from './lib';

import TripSearchForm from './TripSearchForm';

import './index.scss';

const getSearchPath = search => {
  const destinationSelection = Immutable.fromJS(search.selection);
  const searchErrors = validateSearch(search, destinationSelection);
  const targetRoute = getTargetRoute(destinationSelection, search);
  const { pathname, query, url } = targetRoute;
  // console.log('destinationSelection', destinationSelection && destinationSelection.toJS());
  return {
    pathname,
    query,
    url,
    searchErrors,
    targetEntity: destinationSelection.toJS(),
  };
};

const removeFalsyKeys = obj => {
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

const getSearchActionInfo = (previousQuery, query, previousSelection, selection) => {
  const previousCabin = previousQuery.cabin || previousQuery.cabinClass;
  const cabin = query.cabin || query.cabinClass;

  const previousSearchVariant = getPageViewType(previousQuery);
  const searchVariant = getPageViewType(query);

  const isNewSearch = !previousSelection;
  const searchAdded = searchVariant !== 'display' && previousSearchVariant === 'display';
  const checkInChanged = query.checkIn !== previousQuery.checkIn;
  const checkOutChanged = query.checkOut !== previousQuery.checkOut;
  const passengersChanged = query.passengers !== previousQuery.passengers;
  const roomsChanged = query.rooms !== previousQuery.rooms;
  const airAdded = !previousCabin && !!cabin;
  const airRemoved = !!previousCabin && !cabin;
  const cabinChanged = !(airAdded || airRemoved) && previousCabin !== cabin;

  // console.log('previousSelection', previousSelection);
  const scopeChanged = previousSelection?.get('type') !== selection.type;
  const targetChanged = scopeChanged || previousSelection?.get('id') !== selection.id;

  const dirty = [
    scopeChanged,
    targetChanged,
    airAdded,
    airRemoved,
    cabinChanged,
    checkInChanged,
    checkOutChanged,
    passengersChanged,
    roomsChanged,
  ].some(option => option === true);

  if (!dirty) {
    return false;
  }

  if (isNewSearch) {
    return {
      new_search: isNewSearch,
    };
  }

  return removeFalsyKeys({
    changed_cabin: !searchAdded && cabinChanged,
    changed_dates: !searchAdded && (checkInChanged || checkOutChanged),
    changed_passengers: !searchAdded && passengersChanged,
    changed_rooms: !searchAdded && roomsChanged,
    changed_scope: scopeChanged,
    changed_target: targetChanged,
    added_dates: searchAdded,
    added_air: airAdded,
    removed_air: airRemoved,
  });
};

const getSearchEntityInfo = (entity, query) => {
  const searchVariant = getPageViewType(query);
  return {
    search_entity: entity.type,
    search_entity_id: entity.id || 0,
    search_entity_name: entity.name,
    search_variant: searchVariant,
  };
};

const trackSearch = (tracking, previousQuery, query, selection, targetEntity) => {
  const previousSelection = selection || null;
  const searchInfo = getSearchInfo(query);
  const searchEntity = getSearchEntityInfo(targetEntity, query);
  const searchActions = getSearchActionInfo(previousQuery, query, previousSelection, targetEntity);
  const searchChanged = !!searchActions;

  if (searchChanged) {
    tracking.trackEvent({
      event: Events.TRIP_SEARCH_CHANGED,
      properties: { ...searchEntity, actions: searchActions, search: searchInfo },
    });
  }
};

const TripSearch = ({ search, selection, className, onClose, submitText, onSubmit, orientation }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const tracking = useTracking();
  const [errors, setErrors] = useState(Immutable.Map());
  const tripSearch = useSelector(state => tripSearchSelector(state, search, router, selection));

  const submitSearch = params => {
    const { pathname, query, url, searchErrors, targetEntity } = getSearchPath(params);

    trackSearch(tracking, router.query, query, selection, targetEntity);
    dispatch(setSearch(params));
    setErrors(searchErrors);

    if (pathname) {
      onSubmit();
      if (onClose) {
        onClose();
      }
      router.push({ pathname, query }, url, { shallow: false });
    }
  };

  const onFormSubmit = (e, searchParams) => {
    e.preventDefault();
    submitSearch(searchParams);
  };

  return (
    <TripSearchForm
      className={className}
      onSubmit={(e, searchDef) => onFormSubmit(e, searchDef)}
      onClose={onClose}
      selectedOrigin={tripSearch.origin}
      selection={tripSearch.selection}
      selectionType={tripSearch.selectionType}
      selectionCode={tripSearch.selectionCode}
      departDate={tripSearch.departDate}
      returnDate={tripSearch.returnDate}
      travelerCount={tripSearch.travelerCount}
      roomCount={tripSearch.roomCount}
      cabinClass={tripSearch.cabinClass}
      errors={errors}
      submitText={submitText}
      orientation={orientation}
    />
  );
};

TripSearch.defaultProps = {
  search: {},
  submitText: 'SEARCH',
  onSubmit: () => {},
};

TripSearch.propTypes = {
  className: PropTypes.string,
  search: SkylarkPropTypes.search,
  selection: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.Map), PropTypes.bool]),
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  orientation: PropTypes.string,
};

export default TripSearch;
