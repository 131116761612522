import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Events from 'constants/events';
import * as Input from 'components/Forms/Input';
import FormRow from 'components/Forms/FormRow';
import { Button } from 'components/UI';

import Styled from './styled';

const RequestPasswordUpdate = ({ email, status, loading, serverErrors, buttonText, onSubmit, children }) => {
  const [submitError, setSubmitError] = useState();
  const [emailAddress, setEmailAddress] = useState(email);

  const requestPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Must be a valid email address'),
  });

  const submitForm = (values) => {
    setEmailAddress(values.email);
    onSubmit(values);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(requestPasswordSchema),
    defaultValues: { email },
  });

  const headerText = status === 'success' && `Instructions have been sent to ${emailAddress}`;

  return (
    <Styled className="flex-fill">
      {headerText && <h4>{headerText}</h4>}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <Input.Text
            label="Email Address"
            placeholder="me@mydomain.com"
            autoComplete="email"
            flex={2}
            type="text"
            error={errors?.email}
            name="email"
            register={register}
          />
        </FormRow>
        <div className="form-inset">
          {submitError && <div className="alert-error">There was an error submitting your information ({submitError})</div>}
          {serverErrors.size > 0 && (
            <ul className="form-errors">
              {serverErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
          {children}
        </div>
        <div className="button-group">
          <Button type="submit" className="button-cta" event={Events.REQUEST_PASSWORD_RESET} disabled={loading} context="sign in">
            {buttonText}
          </Button>
        </div>
      </form>
    </Styled>
  );
};

RequestPasswordUpdate.defaultProps = {
  serverErrors: Immutable.List([]),
  buttonText: 'Send me reset password instructions',
  onSubmit: (v) => console.log('RequestPasswordUpdate > onSubmit', v),
};

RequestPasswordUpdate.propTypes = {
  email: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  serverErrors: PropTypes.instanceOf(Immutable.List),
  children: PropTypes.node,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default RequestPasswordUpdate;
