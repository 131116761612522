import 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import Immutable from 'immutable';

import { SESSION_TIMEOUT } from 'constants/config';

export { default as Events } from 'constants/events';

const DEBUG_SEGMENT = true; // TODO: move to config file (in constants)

const ROBOT_USER_AGENTS = [
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.118 Safari/537.36 (compatible; Google-HotelAdsVerifier/2.0)',
  'Mozilla/5.0 (Linux; Android 8.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Focus/1.3 Chrome/61.0.3163.81 Mobile Safari/537.36 (compatible; Google-HotelAdsVerifier/2.0)',
  'Mozilla/5.0 (iPad; U; CPU OS 3_2 like Mac OS X; en-us) AppleWebKit/531.21.10 (KHTML, like Gecko) Version/4.0.4 Mobile/7B334b Safari/531.21.10 (compatible; Google-HotelAdsVerifier/2.0)',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.118 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
];

const removeEmptyAttributes = obj => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      ((val && typeof val === 'object' && removeEmptyAttributes(val)) || (val !== 0 && !val)) && delete obj[key]
  );
  return obj;
};

const requestPayload = (params, payload) => removeEmptyAttributes(payload || {});

const intercomUser = user => ({
  Intercom: {
    app_id: 'v7rfulc5',
    email: user.email,
    user_id: user.marketing_uuid || user.id,
    skylark_user_id: user.id,
    user_hash: user.intercom_hash,
  },
});

const isBlockedBot = () => ROBOT_USER_AGENTS.includes(window.navigator.userAgent);
const isActive = () => process.env.NODE_ENV === 'production' || DEBUG_SEGMENT;
const hasAnalytics = () => typeof analytics !== 'undefined'; // So Storybook won't throw warnings/errors
const isDisabled = () => !isActive() || isBlockedBot() || !hasAnalytics();

const trackingUser = user => ({
  activated: user.activated || undefined,
  id: user.marketing_uuid || user.id || undefined,
  email: user.email || undefined,
  intercomHash: user.intercom_hash || undefined,
  status: user.status || undefined,
});

const AnalyticsService = {
  identify: user => {
    if (!user || isDisabled()) {
      return false;
    }
    const objUser = user.toJS();

    const isSegmentReady = window.analytics && typeof window.analytics.user === 'function';
    const segmentUser = isSegmentReady && window.analytics.user();

    if (segmentUser && segmentUser?.id() === objUser.id) {
      return null;
    }

    const userId = objUser.marketing_uuid || objUser.id;

    window.setTimeout(() => {
      window.analytics.alias(userId);
      window.analytics.identify(userId, trackingUser(objUser), intercomUser(objUser));
    }, 50);
    return null;
  },

  page: () => {
    if (isDisabled()) {
      return false;
    }
    return null;
  },

  track: () => {
    if (isDisabled()) {
      return false;
    }
    return null;
  },

  reset: () => {
    if (isDisabled()) {
      return false;
    }
    window.analytics.reset();
  },

  trackReducer: (eventName, payload = {}, eventUser = Immutable.Map()) => {
    if (isDisabled()) {
      return false;
    }
    const properties = requestPayload({}, payload);
    const isClient = typeof window !== 'undefined';

    if (isClient) {
      // KLUDGE
      let userId = parseInt(Cookies.get('skylarkUserId'));
      if (!Cookies.get('skylarkUserId') && eventUser.get('id')) {
        userId = eventUser.get('marketing_uuid') || eventUser.get('id');
        Cookies.set('skylarkUserId', userId, { expires: SESSION_TIMEOUT });
        window.analytics.alias(userId);
        AnalyticsService.identify(eventUser);
      }
      window.setTimeout(() => {
        window.analytics.track(eventName || 'Undefined Event', properties, userId);
      }, 50);
    }
    return null;
  },
};

export default AnalyticsService;
