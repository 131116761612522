import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import track, { useTracking } from 'react-tracking';
import { CollectionLink } from 'components/Links';

import Events from 'constants/events';
import { getCardEntityInfo } from 'lib/trackingHelpers';

import HeroImage from 'components/UI/HeroImage';
import BaseCard from '../BaseCard';

import * as Styled from './CollectionCard.styled';

// const ViewLink = () => ;

const Header = () => (
  <Styled.Header>
    <button type="button">View</button>
  </Styled.Header>
);

export const CollectionCard = ({ collection, className, position }) => {
  const tracking = useTracking();
  const isWaiting = collection.get('loading');
  const baseClass = classNames({ waiting: isWaiting }, className);

  const onClick = () => {
    const cardEntity = getCardEntityInfo(collection.toJS(), 'collection');
    tracking.trackEvent({
      event: Events.CARD_CLICKED,
      ...cardEntity,
      position,
    });
  };

  return (
    <CollectionLink collection={collection}>
      <BaseCard onClick={onClick} className={baseClass} loading={isWaiting}>
        <HeroImage imageUrl={collection.getIn(['images', 0, 'large_url'])} header={<Header />} />
        <div className="card-body">
          <h2>{collection.get('display_name')}</h2>
          <p title={collection.get('description')}>{collection.get('description') || <br />}</p>
        </div>
      </BaseCard>
    </CollectionLink>
  );
};

CollectionCard.defaultProps = {
  collection: Immutable.Map(),
};

CollectionCard.propTypes = {
  collection: PropTypes.instanceOf(Immutable.Map),
  header: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.number,
};

export default track(() => ({ components: ['CollectionCard'] }))(CollectionCard);
