import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { requestHotel } from 'modules/hotels';

const useHotel = hotelCode => {
  const dispatch = useDispatch();
  const hotel = useSelector(state => state.hotels.getIn(['items', hotelCode]));

  // console.log('hotel', hotel && hotel.toJS());

  useEffect(() => {
    if (!hotel || (hotelCode && hotelCode !== hotel.get('code'))) {
      dispatch(requestHotel(hotelCode));
    }
  }, [hotelCode]);

  return hotel;
};

export default useHotel;
