import Immutable from 'immutable';
// import Cookies from 'js-cookie';
// import moment from 'moment';
import axios from 'axios';

import { AUTH_API_ENDPOINT } from 'constants/endpoints';
// import { SESSION_TIMEOUT } from 'constants/config';

export const initialState = Immutable.fromJS({ loading: false, loaded: false });

// ACTION TYPES
const REQUEST = 'skylark/referrer/REQUEST';
const SUCCESS = 'skylark/referrer/SUCCESS';
const FAILURE = 'skylark/referrer/FAILURE';

export const prefetchReferrer = async referrerCode => {
  const resourceUrl = `${AUTH_API_ENDPOINT}/referrer/${referrerCode}`;
  const res = await fetch(resourceUrl);
  const data = await res.json();
  return data;
};

export const fetchReferrer = referrerCode => async dispatch => {
  // console.log('requestHeaders', requestHeaders);
  const resourceUrl = `${AUTH_API_ENDPOINT}/referrer/${referrerCode}`;

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.get(resourceUrl);
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      payload: respStatus,
    });
  }
};

// REDUCER HELPER METHODS
const requested = state => state.set('loading', true).set('loaded', false);

const received = (state, action) => {
  const { payload } = action;
  return state
    .set('id', payload.id)
    .set('email', payload.email)
    .set('first_name', payload.first_name)
    .set('last_name', payload.last_name)
    .set('name', payload.name)
    .set('referral_code', payload.referral_code);
};
const failed = (state, action) =>
  state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false);

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    default:
      return state;
  }
}
