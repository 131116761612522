import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Select from 'react-select';

import { setCurrencyCode } from 'modules/settings';
import { currencyData } from 'constants/currency';

import SearchOption, { SingleValue } from './CurrencyOption';

import Styled from './styled';

const allCurrencyOptions = currencyData.map(option =>
  option.set('label', option.get('title')).set('value', option.get('code'))
);

export const CurrencyPicker = ({ selectedCurrencyCode, onSetCurrencyCode }) => {
  const [code, setCode] = useState(selectedCurrencyCode);

  useEffect(() => {
    setCode(selectedCurrencyCode);
  }, [selectedCurrencyCode]);

  const onChange = value => {
    setCode(value);
    onSetCurrencyCode(value);
  };

  const selectedCurrency = allCurrencyOptions.get(code).toJS();
  const currencyOptions = allCurrencyOptions
    .delete(code)
    .toList()
    .toJS();

  return (
    <Styled className="currency-picker-component">
      <Select
        instanceId="currency_picker_select"
        clearable={false}
        searchable={false}
        className="currency-select-container"
        classNamePrefix="currency-select"
        components={{ Option: SearchOption, SingleValue }}
        onChange={v => onChange(v.code)}
        options={currencyOptions}
        value={selectedCurrency}
      />
    </Styled>
  );
};

CurrencyPicker.defaultProps = {
  selectedCurrencyCode: 'USD',
};

CurrencyPicker.propTypes = {
  selectedCurrencyCode: PropTypes.string,
  onSetCurrencyCode: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  let rehydrated = false;
  if (state._persist) {
    rehydrated = state._persist.rehydrated;
  }
  const settings = Immutable.fromJS(state.settings);
  const currencyCode = rehydrated ? settings.get('currencyCode') : 'USD';
  return {
    selectedCurrencyCode: currencyCode,
  };
};

const mapDispatchToProps = {
  onSetCurrencyCode: setCurrencyCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyPicker);
