import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon_phone">
      <path d="M0.336,3.865 L3.588,0.612 C4.036,0.165 4.755,0.273 5.123,0.787 L7.904,4.662 C8.273,5.176 8.172,5.928 7.724,6.375 L5.354,8.746 C6.535,10.393 7.983,12.174 9.904,14.096 C11.826,16.017 13.605,17.465 15.254,18.646 L17.624,16.276 C18.072,15.829 18.859,15.762 19.373,16.131 L23.195,18.859 C23.709,19.227 23.835,19.964 23.387,20.412 L20.134,23.665 C20.134,23.665 14.504,24.159 7.172,16.828 C-0.158,9.495 0.336,3.865 0.336,3.865 Z" />
    </g>
  </svg>
);

export default Svg;
