import React from 'react';
import { Select } from 'components/FormElements';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { InputWrapper } from './InputWrapper';

import OriginSearch from '../OriginSearch';
import DestinationSearch from '../DestinationSearch';
import DatePicker from '../DatePicker';

import SEARCH_CONTROL_CONFIG from './config';

const DateInput = ({ range, label, onChange, errors }) => {
  const isHotelOnly = true;
  return (
    <InputWrapper label={label} id="departDate" className="search-datepicker" errorMessage={errors.getIn(['date', 'message'])}>
      <DatePicker
        startDate={range[0]}
        endDate={range[1]}
        onDatesChange={(start, end) => onChange(start, end)}
        startDatePlaceholderText={isHotelOnly ? 'Check in' : 'Leaving'}
        endDatePlaceholderText={isHotelOnly ? 'Check out' : 'Returning'}
      />
    </InputWrapper>
  );
};

DateInput.defaultProps = {
  label: 'Travel Dates',
};

DateInput.propTypes = {
  range: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

const FlightsInput = ({ cabin, label, onChange }) => (
  <InputWrapper label={label} id="cabinClass" className="search-cabinClass">
    <Select
      id="cabinClass"
      name="cabinClass"
      instanceId="cabinClass"
      searchable={false}
      clearable={false}
      value={SEARCH_CONTROL_CONFIG.get('cabinOptions').filter((opt) => opt.value === cabin)[0] || SEARCH_CONTROL_CONFIG.getIn(['cabinOptions', 0])}
      options={SEARCH_CONTROL_CONFIG.get('cabinOptions')}
      onChange={(option) => onChange(option.value)}
      matchPos="start"
    />
  </InputWrapper>
);

FlightsInput.defaultProps = {
  label: 'Flights',
};

FlightsInput.propTypes = {
  cabin: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

const OriginInput = ({ origin, label, onChange, errors, hidden }) => {
  if (hidden) {
    return null;
  }
  return (
    <InputWrapper label={label} className="search-origin" errorMessage={errors.getIn(['origin', 'message'])}>
      <OriginSearch selectedAirportCode={origin} onSelectionChange={(option) => onChange(option)} placeholder="Coming from..." />
    </InputWrapper>
  );
};

OriginInput.defaultProps = {
  label: 'Leaving From',
};

OriginInput.propTypes = {
  errors: PropTypes.instanceOf(Object),
  hidden: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  origin: PropTypes.string,
};

const DestinationInput = ({ destination, label, onChange, errors }) => (
  <InputWrapper label={label} id="destination" className="search-destination" errorMessage={errors.getIn(['destination', 'message'])}>
    <DestinationSearch
      id="destination"
      instanceId="destination"
      selection={Immutable.fromJS(destination)}
      onSelectionChange={(option) => onChange(option)}
      placeholder="Going to..."
    />
  </InputWrapper>
);

DestinationInput.defaultProps = {
  label: 'Going To',
};

DestinationInput.propTypes = {
  errors: PropTypes.instanceOf(Object),
  destination: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

const TravelersInput = ({ travelers, label, onChange, errors }) => (
  <InputWrapper label={label} id="travelerCount" className="search-passengers" errorMessage={errors.getIn(['travelerCount', 'message'])}>
    <Select
      name="travelerCount"
      id="travelerCount"
      instanceId="travelerCount"
      clearable={false}
      searchable={false}
      value={SEARCH_CONTROL_CONFIG.get('travelerCountOptions').filter((opt) => opt.value === travelers)[0]}
      options={SEARCH_CONTROL_CONFIG.get('travelerCountOptions')}
      onChange={(option) => onChange(option.value)}
    />
  </InputWrapper>
);

TravelersInput.defaultProps = {
  label: 'Travelers',
};

TravelersInput.propTypes = {
  errors: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  onChange: PropTypes.func,
  travelers: PropTypes.number,
};

const RoomsInput = ({ rooms, label, onChange, errors }) => (
  <InputWrapper label={label} id="roomCount" className="search-rooms" errorMessage={errors.getIn(['roomCount', 'message'])}>
    <Select
      name="roomCount"
      instanceId="roomCount"
      clearable={false}
      searchable={false}
      value={SEARCH_CONTROL_CONFIG.get('roomCountOptions').filter((opt) => opt.value === rooms)[0]}
      options={SEARCH_CONTROL_CONFIG.get('roomCountOptions')}
      onChange={(option) => onChange(option.value)}
    />
  </InputWrapper>
);

RoomsInput.defaultProps = {
  label: 'Rooms',
};

RoomsInput.propTypes = {
  errors: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  onChange: PropTypes.func,
  rooms: PropTypes.number,
};

export default {
  DateRange: DateInput,
  Flights: FlightsInput,
  Origin: OriginInput,
  Destination: DestinationInput,
  Travelers: TravelersInput,
  Rooms: RoomsInput,
};
