import styled from 'styled-components';
// import {BaseCard} from '../BaseCard/BaseCard.styled';

import { colors, variables, toRgba, lighten } from 'styles';

export const Header = styled.div`
  text-align: right;
`;


// export const CollectionCard = styled(BaseCard)`
//     /* border: 2px solid red; */
//   .card-body {
//     padding: 10px 0 20px;
//   }
// `;