import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks';
import RequestPasswordUpdate from 'components/Authentication/RequestPasswordUpdate';
import ChangePassword from 'components/User/ChangePassword';

const ActivateByEmail = ({ email, passwordStatus, requestPasswordReset }) => (
  <>
    <div className="access-denied-message">
      <h3>Your account requires confirmation.</h3>
      <p>
        In order protect your personal information, we'll send you an email with a confirmation link to set a password
        and activate your account.
      </p>
    </div>
    <div className="activation-form">
      <RequestPasswordUpdate
        email={email}
        status={passwordStatus}
        onSubmit={requestPasswordReset}
        buttonText="Send my Confirmation Link"
      />
    </div>
  </>
);

ActivateByEmail.propTypes = {
  email: PropTypes.string,
  passwordStatus: PropTypes.bool,
  requestPasswordReset: PropTypes.bool,
};

const ActivateBySetPassword = () => (
  <>
    <div className="access-denied-message">
      <h3>Your account requires confirmation.</h3>
      <p>In order protect your personal information, please set a password to activate your account.</p>
    </div>
    <div className="activation-form">
      <ChangePassword isNew onSubmit={() => console.log('password set submitted')} hideLabel />
    </div>
  </>
);

const RequestActivation = ({ email }) => {
  const { requestPassword, passwordRequested, canSetPassword } = useAuth();
  const passwordStatus = passwordRequested ? 'success' : null;

  const requestPasswordReset = formData => {
    requestPassword(formData);
  };

  return canSetPassword ? (
    <ActivateBySetPassword />
  ) : (
    <ActivateByEmail email={email} passwordStatus={passwordStatus} requestPasswordReset={requestPasswordReset} />
  );
};

RequestActivation.propTypes = {
  email: PropTypes.string,
};

export default RequestActivation;
