import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormFieldWrapper from '../FormFieldWrapper';

const TextArea = ({ name, label, hint, error, register, control, errorPosition, flex, ...props }) => (
  <FormFieldWrapper label={label} error={error} hint={hint} flex={flex} errorPosition={errorPosition}>
    <textarea
      name={name}
      className={classNames({ 'field-input-error': !!error })}
      control={control}
      {...register(name)}
      {...props}
    />
  </FormFieldWrapper>
);

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  errorPosition: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  flex: PropTypes.number,
};

export default TextArea;
