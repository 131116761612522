import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon-air-shuttle">
      <path d="M22.8007157,8.61451335 L17.17155,8.2479287 C17.1682696,8.2479287 15.8708715,8.2479287 13.2793558,8.2479287 L13.2793558,7.3991253 C13.247372,7.16047622 13.1341982,6.32151402 13.0948334,5.88112038 C13.0554686,5.42350463 12.6839633,5.19469676 12.4231715,5.11350687 L12.2042049,4.42954355 C12.1771416,4.34343306 12.0959517,4.28438586 12.0024603,4.28438586 C11.9114292,4.28438586 11.8302393,4.34343306 11.8007157,4.42954355 L11.5842093,5.11350687 C11.3234176,5.19223646 10.9469917,5.42104433 10.9076269,5.87866008 C10.8707224,6.31905372 10.7550883,7.15555562 10.7280251,7.3548399 C10.7263849,7.3646811 10.7247447,7.66237736 10.7231045,8.2479287 L6.84075151,8.2479287 C6.83747111,8.2479287 4.95698203,8.37012358 1.19928428,8.61451335 C1.08611049,8.62189425 1,8.71292534 1,8.82609913 L1,11.0182262 C1,11.1240191 1.07872959,11.2125898 1.18206218,11.2273516 L6.81122791,12.0048064 C6.81778871,12.0048064 8.12994856,12.0056265 10.7477074,12.0072667 L11.3283382,17.1441264 L8.53097741,17.6831781 C8.43256542,17.7028605 8.35875643,17.788971 8.35875643,17.8898433 L8.35875643,19.3660231 C8.35875643,19.4693557 8.43748602,19.5579265 8.53835831,19.5753946 L11.4882577,20.0253834 C11.5719079,20.0280897 11.6211138,20.0084073 11.6604786,19.9737171 L12.0024603,19.6735605 L12.3395214,19.9737171 C12.3862671,20.0157882 12.4502348,20.0352246 12.5117423,20.0253834 L15.4591814,19.5751486 C15.56276,19.5579265 15.6387833,19.4691096 15.6387833,19.3657771 L15.6387833,17.8895972 C15.6387833,17.7887249 15.5674346,17.7026144 15.4690226,17.6829321 L12.6741221,17.1468327 L13.2572132,12.0072667 L17.1592485,12.0072667 C17.1658093,12.0072667 19.0520391,11.747295 22.8179378,11.2273516 C22.9212704,11.2125898 23,11.1240191 23,11.0182262 L23,8.82363883 C23,8.71292534 22.9138895,8.62189425 22.8007157,8.61451335 Z" />
    </g>
  </svg>
);

export default Svg;