import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReferrals } from 'modules/referrals';

const useReferrals = () => {
  const dispatch = useDispatch();
  const referrals = useSelector(state => state.referrals);

  useEffect(() => {
    dispatch(fetchReferrals());
  }, []);

  // console.log('useReferrals > referrals', referrals);
  // const signUp = (firstName, lastName, email) => dispatch(submitProspect(firstName, lastName, email));
  return referrals;
};

export default useReferrals;
