import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { IconLogo, Wordmark } from 'components/svg';

import Styled from './styled';

const Branding = ({ className, scrolled }) => {
  const baseClass = classNames('branding-wrapper', className, {
    'is-scrolled': scrolled,
  });

  return (
    <Styled className={baseClass}>
      <div className="branding">
        <Link href="/">
          <a>
            <Wordmark height={36} className="skylark-wordmark" />
            <IconLogo height={36} className="skylark-logo" />
          </a>
        </Link>
      </div>
    </Styled>
  );
};

Branding.propTypes = {
  className: PropTypes.string,
  scrolled: PropTypes.bool,
};

export default Branding;
