import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import useIsMobile from 'lib/UserAgent';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { useAuth, useProfile, useModal } from 'hooks';

import { TripSearch } from 'components/Controls';

import Branding from './Branding';
import SearchWidget from './SearchWidget';
import ActionList from './ActionList';
import NavigationTray from './NavigationTray';
import NavigationList from './NavigationList';
import AgentLinkWidget from './AgentLinkWidget';
import BurgerButton from '../BurgerButton';

import Styled from './styled';

const HeaderDisplay = ({ onSearch, isActivatedUser, isLoggedIn, user, children, className, layoutMode }) => {
  const isMobile = useIsMobile();
  const isServer = typeof window === 'undefined';
  const scrollOffset = 90;

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setScrolled(!isServer ? window.scrollY >= 90 : false);
  }, []);

  useScrollPosition(({ currPos }) => {
    const yPos = Math.abs(currPos.y);
    if ((!scrolled && yPos >= scrollOffset) || (scrolled && yPos <= scrollOffset)) {
      setScrolled(yPos >= scrollOffset);
    }
  });

  const baseClass = classNames(className, {
    'is-home': layoutMode === 'home',
    'is-scrolled': scrolled,
  });

  // console.log('HEADER > layoutMode', layoutMode);

  return (
    <header className={baseClass}>
      <div className="site-container">
        <div className="header-content">
          <Branding scrolled={scrolled} />
          <SearchWidget scrolled={scrolled} onClick={onSearch} />
          <NavigationList onSearch={onSearch} layoutMode={layoutMode} scrolled={scrolled} />
          <ActionList isActivatedUser={isActivatedUser} layoutMode={layoutMode} scrolled={scrolled} isLoggedIn={isLoggedIn} user={user} />
          {user && user.get('debug_enabled') && !isMobile.phone ? <AgentLinkWidget user={user} /> : null}
          <NavigationTray className="navigation-tray" isLoggedIn={isLoggedIn} />
          <BurgerButton layoutMode={layoutMode} scrolled={scrolled} className="right burger-button" />
        </div>
      </div>
      {children}
    </header>
  );
};

HeaderDisplay.propTypes = {
  className: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isActivatedUser: PropTypes.bool,
  user: PropTypes.instanceOf(Immutable.Map),
  children: PropTypes.node,
  onSearch: PropTypes.func.isRequired,
  layoutMode: PropTypes.string,
};

const Header = ({ className, children, layoutMode }) => {
  const { user } = useProfile();
  const { auth } = useAuth();
  const [openModal, closeModal] = useModal();

  const isLoggedIn = auth?.get('loggedIn');
  const isActivatedUser = user && user.get('activated');

  const openSearchModal = () => {
    openModal('COMPONENT', {
      component: <TripSearch context="top navigation" onClose={closeModal} orientation="vertical" clean />,
    });
  };

  // console.log('layoutMode', layoutMode);
  // console.log('Header', { auth: auth && auth.toJS() });

  const baseClass = classNames('header-component', className, {
    'home-page': layoutMode === 'home',
  });

  return (
    <Styled className={baseClass}>
      <HeaderDisplay
        onSearch={() => openSearchModal()}
        isActivatedUser={isActivatedUser}
        isLoggedIn={isLoggedIn}
        user={user}
        className={className}
        layoutMode={layoutMode}
      >
        {children}
      </HeaderDisplay>
    </Styled>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  layoutMode: PropTypes.string,
};

export default Header;
