import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import SearchInputs from '../SearchInputs';

const TripSearchForm = ({
  className,
  onSubmit,
  onClose,
  selectedOrigin,
  selection,
  departDate,
  returnDate,
  travelerCount,
  roomCount,
  cabinClass,
  errors,
  submitText,
  orientation,
}) => {
  const [cabin, setCabin] = useState(cabinClass);
  const [origin, setOrigin] = useState(selectedOrigin);
  const [destination, setDestination] = useState(selection);
  const [startDate, setStartDate] = useState(departDate);
  const [endDate, setEndDate] = useState(returnDate);
  const [travelers, setTravelers] = useState(travelerCount);
  const [rooms, setRooms] = useState(roomCount);

  useEffect(() => {
    setCabin(cabinClass);
  }, [cabinClass]);

  useEffect(() => {
    setOrigin(selectedOrigin);
  }, [selectedOrigin]);

  useEffect(() => {
    setDestination(selection);
  }, [selection]);

  useEffect(() => {
    setStartDate(departDate);
  }, [departDate]);

  useEffect(() => {
    setEndDate(returnDate);
  }, [returnDate]);

  useEffect(() => {
    setTravelers(travelerCount);
  }, [travelerCount]);

  useEffect(() => {
    setRooms(roomCount);
  }, [roomCount]);

  const baseClass = classNames('search-control', className);
  const hotelOnly = !cabin;

  const search = {
    departDate: startDate,
    returnDate: endDate,
    cabinClass: cabin,
    travelerCount: parseInt(travelers, 10) || 2,
    roomCount: parseInt(rooms, 10) || 1,
    origin,
    destination: {},
    selection: destination,
  };

  const setOriginOption = (option) => {
    const optionCode = option && option.code;
    if (optionCode !== origin) {
      setOrigin(optionCode);
    }
  };

  const setDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <form className={baseClass} onSubmit={(e) => onSubmit(e, search)}>
      {orientation === 'horizontal' ? (
        <div className="search-body">
          <SearchInputs.DateRange range={[startDate, endDate]} onChange={setDateRange} errors={errors} />
          {/* <SearchInputs.Flights cabin={cabin} onChange={setCabin} /> */}
          <SearchInputs.Origin origin={selectedOrigin} onChange={setOriginOption} errors={errors} hidden={hotelOnly} />
          <SearchInputs.Destination destination={destination} onChange={setDestination} errors={errors} />
          <SearchInputs.Travelers travelers={travelers} onChange={setTravelers} errors={errors} />
          <SearchInputs.Rooms rooms={rooms} onChange={setRooms} errors={errors} />

          <div className="form-control search-submit">
            <button className="button button-search" type="submit">
              <span>{submitText}</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="search-body">
          {/* <SearchInputs.Flights cabin={cabin} onChange={setCabin} /> */}
          <SearchInputs.Origin origin={selectedOrigin} onChange={setOriginOption} errors={errors} hidden={hotelOnly} />
          <SearchInputs.Destination destination={destination} onChange={setDestination} errors={errors} />
          <SearchInputs.DateRange range={[startDate, endDate]} onChange={setDateRange} errors={errors} />
          <SearchInputs.Travelers travelers={travelers} onChange={setTravelers} errors={errors} />
          <SearchInputs.Rooms rooms={rooms} onChange={setRooms} errors={errors} />

          <div className="form-control search-submit">
            <button className="button button-search" type="submit">
              <span>{submitText}</span>
            </button>
            {onClose && (
              <button className="button" type="button" onClick={onClose}>
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
};

TripSearchForm.defaultProps = {
  orientation: 'horizontal',
};

TripSearchForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  selectedOrigin: PropTypes.string,
  selection: ImmutablePropTypes.map,
  departDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  returnDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  travelerCount: PropTypes.number,
  roomCount: PropTypes.number,
  cabinClass: PropTypes.string,
  errors: ImmutablePropTypes.map,
  submitText: PropTypes.string,
  orientation: PropTypes.string,
};

TripSearchForm.defaultProps = {
  travelerCount: 2,
  roomCount: 1,
};

export default TripSearchForm;
