import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styled from './styled';

const Flag = ({ countryCode, className }) => {
  const baseClass = classNames('flag-icon', className);
  return <Styled className={baseClass} countryCode={countryCode.toLowerCase()} />;
};

Flag.defaultProps = {
  countryCode: 'US',
};

Flag.propTypes = {
  countryCode: PropTypes.string,
  className: PropTypes.string,
};

export default Flag;
