import React from 'react';
import PropTypes from 'prop-types';

import Styled from './styled';

const Alert = ({ message, type, onClose }) => (
  <Styled key={message} className={`alert alert-${type}`}>
    <div>{message}</div>
    <button type="button" onClick={onClose} className="alert-cancel">
      <span>+</span>
    </button>
  </Styled>
);

Alert.defaultProps = {
  message: 'Nothing to see here, move along',
  type: 'info',
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  onClose: PropTypes.func,
};

export default Alert;
