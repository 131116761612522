import React from 'react';
import PropTypes from 'prop-types';

const wrapSearchedValue = (text = '', searchVal, className) => {
  const query = searchVal || '';
  const loweredText = text.toLowerCase();
  const strIndex = loweredText.indexOf(query.toLowerCase());
  if (strIndex > -1) {
    return `${text.substr(0, strIndex)}<span class='${className}'>${text.substr(strIndex, query.length)}</span>${text.substr(strIndex + query.length, text.length)}`;
  }
  return text;
};

export const Highlight = ({ searchVal, children, highlightClassName, ...props }) => {
  const resultString = children;
  const formattedSearchString = wrapSearchedValue(resultString, searchVal, highlightClassName);
  return (
    <span
      {...props}
      dangerouslySetInnerHTML={{ __html: formattedSearchString }} // eslint-disable-line react/no-danger
    />
  );
};

Highlight.defaultProps = {
  searchVal: '',
  children: '',
  highlightClassName: 'underline'
};

Highlight.propTypes = {
  searchVal: PropTypes.string,
  highlightClassName: PropTypes.string,
  children: PropTypes.string
};

export default Highlight;
