import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import * as Input from 'components/Forms/Input';
import { Button } from 'components/UI';

import Events from 'constants/events';

import Styled from '../styled';

// NOTE: must explicitly define all values or React will assume it to be uncontrolled
const setInitialValues = email => ({
  email: email || '',
  password: '',
});

const signInSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be a valid email address'),
  password: Yup.string()
    .required('Required')
    .min(6, 'Must be at least 6 characters'),
});

export const SignInForm = ({
  headerText,
  buttonText,
  email,
  serverErrors,
  className,
  loading,
  disabled,
  onSubmit,
  children,
}) => {
  const baseClass = classNames('sign-in-form-component', className, { loading });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: setInitialValues(email),
  });

  useEffect(() => {
    setValue('email', email);
  }, [email]);

  return (
    <Styled className={baseClass}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex-form">
        {headerText && <h4>{headerText}</h4>}
        <div>
          <Input.Text
            name="email"
            label="Name"
            placeholder="you@domain.com"
            autoComplete="email"
            error={errors?.email}
            disabled={disabled}
            register={register}
          />
          <Input.Password
            name="password"
            label="Password"
            autoComplete="password"
            error={errors?.password}
            disabled={disabled}
            register={register}
          />
        </div>

        <div>
          {serverErrors.size > 0 && (
            <ul className="form-errors">
              {serverErrors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
          {children}
        </div>

        <Button
          event={Events.USER_SIGNED_IN}
          context="sign in form"
          type="submit"
          disabled={loading || disabled}
          className="button-cta"
        >
          {buttonText}
        </Button>
      </form>
    </Styled>
  );
};

SignInForm.defaultProps = {
  buttonText: 'Log In',
  serverErrors: Immutable.List(),
  onSubmit: v => console.log('SignInForm > onSubmit', v),
};

SignInForm.propTypes = {
  className: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  email: PropTypes.string,
  serverErrors: PropTypes.instanceOf(Immutable.List),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default SignInForm;
