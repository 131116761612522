import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import FormRow from 'components/Forms/FormRow';
import * as Input from 'components/Forms/Input';
import { Button, Loader } from 'components/UI';
import RequestActivation from 'components/Authentication/RequestActivation/RequestActivation';

import Events from 'constants/events';

import Styled from '../styled';

// NOTE: must explicitly define all values or React will assume it to be uncontrolled
const defaultValues = () => ({
  email: '',
  password: '',
  first_name: '',
  last_name: '',
});

const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be a valid email address'),
  password: Yup.string()
    .required('Required')
    .min(6, 'Must be at least 6 characters'),
  first_name: Yup.string(),
  last_name: Yup.string(),
});

export const SignUpForm = ({
  serverErrors,
  passwordRequested,
  className,
  loading,
  disabled,
  headerText,
  buttonText,
  children,
  onSubmit,
}) => {
  const baseClass = classNames('sign-up-form-component', { loading }, className);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(signUpSchema),
    defaultValues: defaultValues(),
  });

  const requiresActivation =
    passwordRequested || (serverErrors && serverErrors.get(0) && serverErrors.get(0).includes('requires confirmation'));

  return (
    <Styled className={baseClass}>
      {requiresActivation ? (
        <RequestActivation email={getValues('email')} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="flex-form">
          {headerText && <h4>{headerText}</h4>}

          <FormRow>
            <Input.Text
              name="first_name"
              label="Name"
              placeholder="First"
              autoComplete="given-name"
              error={errors?.first_name}
              disabled={disabled}
              register={register}
            />
            <Input.Text
              name="last_name"
              // label="Name"
              placeholder="Last"
              autoComplete="family-name"
              error={errors?.last_name}
              disabled={disabled}
              register={register}
            />
          </FormRow>

          <div>
            <Input.Text
              name="email"
              label="Email Address"
              placeholder="you@domain.com"
              autoComplete="email"
              error={errors?.email}
              disabled={disabled}
              register={register}
            />
            <Input.Password
              name="password"
              label="Password"
              autoComplete="password"
              error={errors?.password}
              disabled={disabled}
              register={register}
            />
          </div>

          <div>
            {serverErrors.size > 0 && (
              <div className="form-errors">
                {serverErrors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            )}
            {children}
          </div>

          <Button
            event={Events.USER_REGISTERED}
            context="sign up form"
            type="submit"
            disabled={loading || disabled}
            className="button-cta"
          >
            {buttonText}
          </Button>
        </form>
      )}
    </Styled>
  );
};

SignUpForm.defaultProps = {
  serverErrors: Immutable.List(),
  buttonText: "Join now, It's Free",
  onSubmit: v => console.log('SignUpForm > onSubmit', v),
};

SignUpForm.propTypes = {
  className: PropTypes.string,
  serverErrors: PropTypes.instanceOf(Immutable.List),
  passwordRequested: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default SignUpForm;
