import React from 'react';
import PropTypes from 'prop-types';

import { months, years } from 'config/formOptions';

import FormFieldWrapper from '../FormFieldWrapper';
import SelectWrapper from './SelectWrapper';

const ExpirationDate = ({
  control,
  monthFieldName,
  yearFieldName,
  label,
  hint,
  monthError,
  yearError,
  flex,
  ...rest
}) => (
  // console.log('ExpirationDate', { control });
  // console.log('monthError', monthError);
  // console.log('yearError', yearError);
  <FormFieldWrapper label={label} hint={hint} errors={[monthError, yearError]} flex={flex} htmlFor="no">
    <SelectWrapper
      options={months}
      placeholder="Month"
      autoComplete="off"
      control={control}
      name={monthFieldName}
      error={monthError}
      {...rest}
      // filterOption={createFilter({ matchFrom: 'start' })}
    />
    <SelectWrapper
      options={years}
      placeholder="Year"
      autoComplete="off"
      // autoComplete="cc-exp-year"
      control={control}
      name={yearFieldName}
      error={yearError}
      // validateOnChange={[monthFieldName]}
      {...rest}
      // filterOption={createFilter({ matchFrom: 'start' })}
    />
  </FormFieldWrapper>
);
ExpirationDate.propTypes = {
  monthFieldName: PropTypes.string,
  yearFieldName: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  hint: PropTypes.string,
  flex: PropTypes.number,
  validateOnChange: PropTypes.instanceOf(Array),
  monthError: PropTypes.instanceOf(Object),
  yearError: PropTypes.instanceOf(Object),
  control: PropTypes.instanceOf(Object),
};

export default ExpirationDate;
