import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';

const isClient = typeof window === 'object';

const getSessionInfo = router => {
  // console.log('router', router);
  const key = uuidv4();
  const query = router?.query;
  const utm_source = query?.utm_source;
  const utm_medium = query?.utm_medium;
  const utm_campaign = query?.utm_campaign;
  const utm_term = query?.utm_term;
  const utm_content = query?.utm_content;
  const { host } = window.location;
  const { pathname } = window.location;
  const { referrer } = document;
  const appPathName = router.pathname;
  return JSON.stringify({
    key,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    host,
    pathname,
    referrer,
    appPathName,
    query,
  });
};

const useSessionInfo = () => {
  const router = useRouter();
  if (!isClient) {
    return null;
  }

  const sessionInfo = sessionStorage.getItem('sessionInfo');
  if (!sessionInfo) {
    // console.log('New Session');
    sessionStorage.setItem('sessionInfo', getSessionInfo(router));
  } else {
    // console.log('Existing Session', sessionInfo);
  }

  return JSON.parse(sessionStorage.getItem('sessionInfo'));
};

export default useSessionInfo;
