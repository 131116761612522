export const capitalize = str => (
  str.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : ''
);

export const titleCase = (str) => {
  if (!str) { return ''; }
  return str.split(/\s+/).map(capitalize).join(' ');
};

export const truncate = (str, length = 100, ending = '...') => {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};
