import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { PRICING_API_ENDPOINT } from 'constants/endpoints';
import { urlDate, flatDate } from 'components/Helpers';

export const requestKey = request => {
  const r = request.toJS();
  const strCheckIn = flatDate(r.departsOn);
  const strCheckOut = flatDate(r.returnsOn);
  const key = `${r.hotelCode}|${strCheckIn}|${strCheckOut}|${r.travelerCount}|${r.roomCount}`;
  // console.log('KEY', key);
  return key;
};

const isValid = request => {
  const r = request.toJS();
  return r.hotelCode && r.travelerCount && r.roomCount;
};

const requestPath = request => {
  const r = request.toJS();
  // console.log('REQUEST', r);
  const strCheckIn = urlDate(r.departsOn);
  const strCheckOut = urlDate(r.returnsOn);
  const path = `${r.hotelCode}?check_in=${strCheckIn}&check_out=${strCheckOut}&passengerCount=${r.travelerCount}&roomCount=${r.roomCount}`;
  // console.log('PATH', path);
  return path;
};

export const initialState = Immutable.fromJS({
  status: 'new',
  loading: false,
  loaded: false,
});

// Action Types
const MERGED_REQUEST = 'skylark/hotel_availability/MERGED_REQUEST';
const MERGED_SUCCESS = 'skylark/hotel_availability/MERGED_SUCCESS';
const FAILURE = 'skylark/hotel_availability/FAILURE';

// Action Creators
export function requestMergedAvailability(request = {}, refresh = false) {
  const key = requestKey(request);
  const invalidRequest = !isValid(request);
  const requestURL = `${PRICING_API_ENDPOINT}/csl_merged_hotel_stays/${requestPath(request)}`;
  return {
    [CALL_API]: {
      endpoint: requestURL,
      method: 'GET',
      types: [
        {
          type: MERGED_REQUEST,
          meta: { key },
        },
        {
          type: MERGED_SUCCESS,
          meta: { key },
        },
        {
          type: FAILURE,
          meta: { key },
        },
      ],
      bailout: state => (state.hotelAvailability.get(key) && !refresh) || invalidRequest,
    },
  };
}

// Reducer Methods
const mergedAvailabiltyRequested = (state, key) => {
  const timeStamp = Date.now();
  const newState = Immutable.fromJS({ loading: true, loaded: false, status: 'loading' }).set('requestTime', timeStamp);
  return state.set(key, newState);
};

const mergedAvailabiltyReceived = (response, state, key) => {
  // console.log('mergedAvailabiltyReceived > response', response);
  const requestTime = state.getIn([key, 'requestTime']);
  const responseTime = Date.now() - requestTime;
  const availability = Immutable.fromJS(response)
    .set('received', Date.now())
    .set('responseTime', responseTime)
    .set('status', 'success');
  // HACK: Add travelerCount to eliminate inconsistant naming in codeBase
  const fixedAvailability = availability.setIn(
    ['request', 'travelerCount'],
    availability.getIn(['request', 'passengerCount'])
  );
  return state.set(key, fixedAvailability);
};

const availabiltyFailed = (errorMessage, state, key) => {
  // console.log('FAILURE')
  const requestTime = state.getIn([key, 'requestTime']);
  const responseTime = Date.now() - requestTime;
  const errors = Immutable.fromJS(errorMessage);
  return state
    .setIn([key, 'errors'], errors)
    .setIn([key, 'status'], 'failure')
    .setIn([key, 'loading'], false)
    .setIn([key, 'loaded'], false)
    .setIn([key, 'responseTime'], responseTime);
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const key = action.meta ? action.meta.key : null;
  // console.log('HOTEL AVAILABILITY REDUCER', action);
  switch (action.type) {
    case MERGED_REQUEST:
      return mergedAvailabiltyRequested(state, key);
    case MERGED_SUCCESS:
      return mergedAvailabiltyReceived(action.payload, state, key);
    case FAILURE:
      return availabiltyFailed(action.payload, state, key);
    default:
      return state;
  }
}
