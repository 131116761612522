import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

const flagIconPath = country => {
  const path = `${variables.path.cloudfront}/flag_svg/${country}.svg`;
  // console.log('flagIconPath', path);
  return path;
};

const Styled = styled.div`
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  background-image: url(${props => flagIconPath(props.countryCode)});

  position: relative;
  border-radius: 0px;
  display: inline-block;
  width: calc((4 / 3) * 1em);
  line-height: 1em;
  border: 1px solid ${toRgba(colors.black, 0.5)};
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
`;

export default Styled;
