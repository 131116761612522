import styled from 'styled-components';
import { colors, variables, toRgba, lighten } from 'styles';

export const Loader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > .loader-spinnner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div {
    position: relative;
  }
  .loader-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.page-loader {
    min-height: 50vh;
  }

  /* SPINNERS */

  .button-spinner {
    float: left;
    left: -35px;
    position: absolute;
    height: 16px;
    display: none;

    &.visible {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 40px;
      display: inline-block;
      margin: auto;
      border: 3px solid ${toRgba(colors.white, 0.5)};
      border-left-color: ${toRgba(colors.white, 0.25)};
      border-right-color: ${toRgba(colors.white, 0.25)};
      border-radius: 50%;
      animation: cssload-rotate-ccw 1000ms linear infinite;
    }

    &.small {
      // top: -13px;
      // right: 17px;

      &:before {
        width: 24px;
        height: 24px;
        border: 4px solid ${toRgba(colors.white, 0.5)};
        border-left-color: ${toRgba(colors.white, 0.25)};
        border-right-color: ${toRgba(colors.white, 0.25)};
      }
    }

    &.simple {
      &:after {
        border: none;
      }
    }

    &.lark {
      &:before {
        border-bottom-color: ${toRgba(colors.skylarkPurple, 0.8)};
        border-left-color: ${toRgba(colors.skylarkPurple, 0.25)};
        border-right-color: ${toRgba(colors.skylarkPurple, 0.25)};
        border-top-color: ${toRgba(colors.skylarkPurple, 0.8)};
      }
    }
  }

  .price-tag.pair {
    position: relative;
  }

  .air-spinner {
    position: relative;
    height: 80px;
    margin-top: -20px;
    text-align: center;
  }

  .tag-spinner-wrapper {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // border: 1px dashed lime;
    margin: 10px 0;
    position: relative;
    &.large {
      height: 70px;
      min-height: 70px;
    }
    &.small {
    }
  }

  .tag-spinner {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    &:before {
      animation: cssload-rotate-ccw 1000ms linear infinite;
      border: 3px solid ${toRgba(colors.white, 0.5)};
      border-left-color: ${toRgba(colors.white, 0.25)};
      border-radius: 50%;
      border-right-color: ${toRgba(colors.white, 0.25)};
      bottom: 0;
      content: '';
      display: block;
      height: 40px;
      left: -5px;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
    }

    &:after {
      animation: cssload-rotate 1000ms linear infinite;
      border: 3px solid ${toRgba(colors.white, 0.4)};
      border-bottom-color: ${toRgba(colors.white, 0.2)};
      border-radius: 50%;
      border-top-color: ${toRgba(colors.white, 0.2)};
      bottom: 0;
      color: $caution-yellow;
      content: '';
      display: block;
      height: 30px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
    }

    &.small {
      top: -13px;
      right: 17px;

      &:before {
        border: 4px solid ${toRgba(colors.white, 0.5)};
        border-left-color: ${toRgba(colors.white, 0.25)};
        border-right-color: ${toRgba(colors.white, 0.25)};
        height: 24px;
        width: 24px;
        left: -6px;
      }

      &:after {
        width: 16px;
        height: 16px;
        border: 2px solid ${toRgba(colors.white, 0.4)};
        border-top-color: ${toRgba(colors.white, 0.2)};
        border-bottom-color: ${toRgba(colors.white, 0.2)};
      }
    }

    &.large {
      left: auto;
      margin-right: -30px;
      right: 50%;
      width: 70px;
      min-width: 70px;

      &:before {
        border-width: 5px;
        height: 60px;
        width: 60px;
        left: 0px;
      }

      &:after {
        border-width: 3px;
        height: 40px;
        width: 40px;
      }
    }

    &.tile-small {
      &:before {
        border: 2px solid ${toRgba(colors.white, 0.6)};
        border-left-color: ${toRgba(colors.white, 0.35)};
        border-right-color: ${toRgba(colors.white, 0.35)};
        height: 34px;
        width: 34px;
      }

      &:after {
        border: 2px solid ${toRgba(colors.white, 0.5)};
        border-bottom-color: ${toRgba(colors.white, 0.3)};
        border-top-color: ${toRgba(colors.white, 0.3)};
        height: 24px;
        width: 24px;
      }
    }

    &.simple {
      &:after {
        border: none;
      }
    }

    &.lark {
      &:before {
        border-bottom-color: ${toRgba(colors.skylarkPurple, 0.8)};
        border-left-color: ${toRgba(colors.skylarkPurple, 0.25)};
        border-right-color: ${toRgba(colors.skylarkPurple, 0.25)};
        border-top-color: ${toRgba(colors.skylarkPurple, 0.8)};
      }

      &:after {
        border-top-color: ${toRgba(colors.skylarkPurple, 0.4)};
        border-bottom-color: ${toRgba(colors.skylarkPurple, 0.4)};
        border-left-color: ${toRgba(colors.skylarkPurple, 0.1)};
        border-right-color: ${toRgba(colors.skylarkPurple, 0.1)};
      }
    }

    .air-tag-spinner {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 60px;
      top: -10px;

      &:before {
        @include animation(rotato 1s linear infinite);
        border: 3px solid ${toRgba(colors.black, 0.5)};
        border-left-color: ${toRgba(colors.black, 0.25)};
        border-radius: 50%;
        border-right-color: ${toRgba(colors.black, 0.25)};
        bottom: 0;
        content: '';
        display: block;
        height: 40px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
      }

      &:after {
        @include animation(rotator 1s linear infinite);
        border: 3px solid ${toRgba(colors.black, 0.4)};
        border-bottom-color: ${toRgba(colors.black, 0.2)};
        border-radius: 50%;
        border-top-color: ${toRgba(colors.black, 0.2)};
        bottom: 0;
        color: $caution-yellow;
        content: '';
        display: block;
        height: 30px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
      }

      &.small {
        right: 30px;
        top: -13px;

        &:before {
          border: 1px solid ${toRgba(colors.black, 0.5)};
          border-left-color: ${toRgba(colors.black, 0.25)};
          border-right-color: ${toRgba(colors.black, 0.25)};
          height: 20px;
          width: 20px;
        }

        &:after {
          border: 1px solid ${toRgba(colors.black, 0.4)};
          border-top-color: ${toRgba(colors.black, 0.2)};
          border-bottom-color: ${toRgba(colors.black, 0.2)};
          height: 14px;
          width: 14px;
        }
      }

      &.large {
        left: auto;
        margin-right: -30px;
        right: 50%;
        width: 60px;

        &:before {
          border: 5px solid ${toRgba(colors.black, 0.5)};
          border-left-color: ${toRgba(colors.black, 0.25)};
          border-right-color: ${toRgba(colors.black, 0.25)};
          height: 60px;
          width: 60px;
        }

        &:after {
          border: 3px solid ${toRgba(colors.black, 0.4)};
          border-bottom-color: ${toRgba(colors.black, 0.2)};
          border-top-color: ${toRgba(colors.black, 0.2)};
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .cssload-container {
    position: relative;
  }

  .cssload-whirlpool,
  .cssload-whirlpool::before,
  .cssload-whirlpool::after {
    border: 1px solid ${toRgba(colors.coolGray, 1)};
    border-left-color: ${toRgba(colors.skylarkPurple, 1)};
    border-radius: 1124px;
    -o-border-radius: 1124px;
    -ms-border-radius: 1124px;
    -webkit-border-radius: 1124px;
    -moz-border-radius: 1124px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .cssload-whirlpool {
    animation: cssload-rotate 2050ms linear infinite;
    -o-animation: cssload-rotate 2050ms linear infinite;
    -ms-animation: cssload-rotate 2050ms linear infinite;
    -webkit-animation: cssload-rotate 2050ms linear infinite;
    -moz-animation: cssload-rotate 2050ms linear infinite;
    height: 56px;
    margin: -28px 0 0 -28px;
    width: 56px;
  }

  .cssload-whirlpool::before {
    animation: cssload-rotate 2050ms linear infinite;
    -o-animation: cssload-rotate 2050ms linear infinite;
    -ms-animation: cssload-rotate 2050ms linear infinite;
    -webkit-animation: cssload-rotate 2050ms linear infinite;
    -moz-animation: cssload-rotate 2050ms linear infinite;
    content: '';
    height: 50px;
    margin: -26px 0 0 -26px;
    width: 50px;
  }

  .cssload-whirlpool::after {
    animation: cssload-rotate 4100ms linear infinite;
    -o-animation: cssload-rotate 4100ms linear infinite;
    -ms-animation: cssload-rotate 4100ms linear infinite;
    -webkit-animation: cssload-rotate 4100ms linear infinite;
    -moz-animation: cssload-rotate 4100ms linear infinite;
    content: '';
    height: 63px;
    margin: -33px 0 0 -33px;
    width: 63px;
  }

  @keyframes cssload-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes cssload-rotate-ccw {
    100% {
      transform: rotate(-180deg);
    }
  }

  @-o-keyframes cssload-rotate {
    100% {
      -o-transform: rotate(360deg);
    }
  }

  @-ms-keyframes cssload-rotate {
    100% {
      -ms-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes cssload-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes cssload-rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
`;
