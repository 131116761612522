import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = props => (
  <svg {...props} viewBox="0 0 25 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="icon-bed">
      <path d="M24,6 L23,6 C22.45,6 22,6.45 22,7 L22,8 L3,8 L3,1 C3,0.45 2.55,0 2,0 L1,0 C0.45,0 0,0.45 0,1 L0,17 L3,17 L3,14 L22,14 L22,17 L25,17 L25,7 C25,6.45 24.55,6 24,6 Z" id="Shape" />
      <circle id="Oval" cx="6" cy="5" r="1.917" />
      <path d="M10,7 L20,7 C20.55,7 21,6.55 21,6 C21,5.45 20.573,4.858 20.051,4.684 L12.948,2.316 C12.427,2.142 11.55,2 11,2 L10,2 C9.45,2 9,2.45 9,3 L9,6 C9,6.55 9.45,7 10,7 Z" id="Shape" />
    </g>
  </svg>
);

export default Svg;
