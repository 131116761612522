import styled from 'styled-components';
import { colors, toRgba, variables } from 'styles';

export const PageHeading = styled.div`
  h2,
  h3 {
    font-family: ${variables.fontFamily.display};
    text-transform: uppercase;
    margin-bottom: 0;
    @media (max-width: ${variables.screen.xs}) {
      br {
        display: none;
      }
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
    @media (max-width: ${variables.screen.xs}) {
      font-size: 1.25rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    @media (max-width: ${variables.screen.xs}) {
      font-size: 1rem;
    }
  }
`;
