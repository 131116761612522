import styled from 'styled-components';
import colors from 'styles/colors';

const buttonHeight = 12;

const StyledSliderWrapper = styled.div`
  .range-slider {
    padding: 0 ${buttonHeight / 2}px;
    .rc-slider {
      height: ${buttonHeight}px;
      .bar {
        top: ${buttonHeight / 2}px;
        height: 2px;
        &.bar-0,
        &.bar-2 {
          background: ${colors.warmGray};
        }
        &.bar-1 {
          background: ${colors.black};
        }
      }
      .handle {
        background-color: ${colors.blue};
        border-radius: ${buttonHeight / 2}px;
        height: ${buttonHeight}px;
        line-height: ${buttonHeight}px;
        width: ${buttonHeight}px;
      }
    }
  }
`;

export default StyledSliderWrapper;
