import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Styled from './HeroImage.styled';

const HeroImage = ({ header, footer, imageUrl, style, className, children, ...props }) => {
  const baseClass = classNames('hero-image', className);

  const imageStyle = {
    backgroundImage: imageUrl ? `url(${imageUrl})` : null,
  };

  return (
    <Styled.Container {...props} style={style} className={baseClass}>
      <div className="hero-image-background" style={imageStyle} />
      <div className="hero-image-header">{header}</div>
      <div className="hero-image-body">{children}</div>
      <div className="hero-image-footer">{footer}</div>
    </Styled.Container>
  );
};

HeroImage.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  imageUrl: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default HeroImage;
