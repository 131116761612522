import Immutable from 'immutable';

const tripSearchSelector = (state, search, router, selection) => {
  const { query } = router;
  const tripSearch = state.tripSearch || Immutable.Map();
  const homeAirport = state.authentication.getIn(['user', 'home_airport']) || 'NYC';

  const searchStartDate = search.departDate || search.checkIn || search.ci;
  const searchEndDate = search.returnDate || search.checkOut || search.co;
  const queryStartDate = query.departDate || query.checkIn || query.check_in;
  const queryEndDate = query.returnDate || query.checkOut || query.check_out;

  const departDate = searchStartDate || queryStartDate || tripSearch.get('departDate');
  const returnDate = searchEndDate || queryEndDate || tripSearch.get('returnDate');
  const origin = search.originCode || query.origin || tripSearch.get('origin') || homeAirport;
  const travelerCount = parseInt(search.passengers || query.travelers || tripSearch.get('travelerCount'), 10);
  const cabinClass = search.cabinClass || query.cabin || query.cabinClass || tripSearch.get('cabinClass');
  const roomCount = parseInt(search.rooms || query.rooms || tripSearch.get('roomCount'), 10);
  const destinationSelection = selection || tripSearch.get('selection');

  const { selectionType, selectionCode } = search;

  const derivedSearch = {
    departDate,
    returnDate,
    travelerCount,
    roomCount,
    origin,
    selectedOrigin: origin,
    selection: destinationSelection,
    selectionType,
    selectionCode,
    cabinClass,
  };

  return derivedSearch;
};

export default tripSearchSelector;
