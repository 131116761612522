import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { getWatchList, watchTrip, unwatchTrip } from 'modules/watchList';
import { useProfile, useAuth } from 'hooks';

const useWatchList = userId => {
  const dispatch = useDispatch();
  const watchList = useSelector(state => state.watchList);

  // console.log('useWatchList', { userId });

  useEffect(() => {
    if (userId) {
      dispatch(getWatchList(userId));
    }
  }, [userId]);

  return watchList;
};

export const useWatchedTrip = (hotelCode, checkIn, checkOut, originCode, destinationCode, cabinClass) => {
  const dispatch = useDispatch();
  const { userId } = useAuth();
  const watchedList = useWatchList(userId);
  const watchedTrips = watchedList.get('trips');
  const isLoading = watchedList && watchedList.get('loading');
  const isLoaded = watchedList && watchedList.get('loaded');
  // console.log('watchedTrips', watchedTrips && watchedTrips.toJS());
  // console.log('useWatchedTrip > auth', auth && auth.toJS());
  const isWatched =
    watchedTrips &&
    watchedTrips.filter(t => {
      const hotelMatch = t.get('hotel_code') === hotelCode;
      const checkInMatch = t.get('check_in') === checkIn;
      const checkOutMatch = t.get('check_out') === checkOut;
      const originMatch = t.get('origin_code') === (originCode || null);
      const destinationMatch = t.get('destination_code') ? t.get('destination_code') === destinationCode : true;
      const cabinMatch = t.get('cabin_class') ? t.get('cabin_class') === cabinClass : true;
      return hotelMatch && checkInMatch && checkOutMatch && originMatch && destinationMatch && cabinMatch;
    }).size > 0;

  // console.log('isWatched', isWatched);

  const setWatch = (emailAddress = undefined) =>
    dispatch(watchTrip(userId, originCode, destinationCode, hotelCode, checkIn, checkOut, cabinClass, emailAddress));

  const removeWatch = () =>
    dispatch(unwatchTrip(userId, originCode, destinationCode, hotelCode, checkIn, checkOut, cabinClass));

  return [!!isWatched, setWatch, removeWatch, isLoading, isLoaded];
};

export default useWatchList;
