import styled from 'styled-components';
import { variables } from 'styles';

const Styled = styled.div`
  display: flex;
  align-items: flex-end;
  & > div {
    flex: 1;
    margin-left: ${props => props.theme.dimensions.spacer2};
    &:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: ${variables.screen.xs}) {
    &.mobile-break {
      flex-direction: column;
      align-items: stretch;
      & > div {
        margin-left: 0;
      }
    }
  }
`;

export default Styled;
