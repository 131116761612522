import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import track, { useTracking } from 'react-tracking';
import { HotelLink } from 'components/Links';

import Events from 'constants/events';
import { getCardEntityInfo } from 'lib/trackingHelpers';

import HeroImage from 'components/UI/HeroImage';
import { Currency } from 'components/Helpers';
import BaseCard from '../BaseCard';

import * as Styled from './HotelCard.styled';

const HotelCardHeader = ({ hotel }) => (
  <Styled.Header>
    {hotel.get('lead_hotel_price') && (
      <div className="from-price">
        from <Currency round>{hotel.get('lead_hotel_price')}</Currency>
      </div>
    )}
    <button type="button">View</button>
  </Styled.Header>
);

HotelCardHeader.defaultProps = {
  hotel: Immutable.Map(),
};

HotelCardHeader.propTypes = {
  hotel: PropTypes.instanceOf(Immutable.Map),
};

export const HotelCard = ({ hotel, className, position, ...props }) => {
  const tracking = useTracking();

  const subTitle = hotel.getIn(['editorial', 'it_is']) || hotel.get('subtitle');
  const isLoading = !hotel.get('code') || hotel.get('loading');
  const baseClass = classNames({ waiting: isLoading }, className);

  const onClick = () => {
    const cardEntity = getCardEntityInfo(hotel.toJS(), 'hotel');
    tracking.trackEvent({
      event: Events.CARD_CLICKED,
      ...cardEntity,
      position,
    });
  };

  return (
    <HotelLink hotel={hotel} onClick={onClick}>
      <BaseCard {...props} className={baseClass} loading={isLoading}>
        <HeroImage imageUrl={hotel.get('hero_image')} header={<HotelCardHeader hotel={hotel} />} />
        <div className="card-body">
          <h2>{hotel.get('name')}</h2>
          <p>{subTitle}</p>
        </div>
      </BaseCard>
    </HotelLink>
  );
};

HotelCard.defaultProps = {
  hotel: Immutable.Map(),
};

HotelCard.propTypes = {
  hotel: PropTypes.instanceOf(Immutable.Map),
  className: PropTypes.string,
  position: PropTypes.number,
};

export default track(() => ({ components: ['HotelCard'] }))(HotelCard);
