import styled, { css } from 'styled-components';
import { variables, colors, toRgba } from 'styles';

const inputHeight = '37px';
const dateColor1 = colors.red;
const dateColor2 = toRgba(dateColor1, 0.75);
const dateColor3 = toRgba(dateColor1, 0.5);
// const dateColor4 = toRgba(dateColor1, 0.35);
// const dateColor5 = toRgba(dateColor1, 0.15);

const isOpenMixin = css`
  .DateRangePicker {
    box-shadow: 0 0 0 1px ${colors.skylarkPurple};
  }
  .DateRangePickerInput {
    border-color: ${colors.skylarkPurple};
  }
`;

export const DatePickerWrapper = styled.div`
  ${props => props.isOpen && isOpenMixin}
 
  .DateRangePicker {
    border-radius: 4px;
  }
  .DateRangePickerInput {
    border-radius: 4px;

    & > .DateInput {
      border-radius: 4px;
    }
  }

  .DateRangePicker {
    min-width: 225px;
    position: relative;
    display: block;
  }

  .DateRangePicker_picker {
    z-index: 20;
  }

  .DateRangePickerInput > .DateInput {
    flex: 1 1;
    text-align: center;
    .DateInput_input {
      text-align: center;
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
  }

  .DateRangePickerInput > .DateInput .DateInput_input {
    width: 99%;
    height: ${inputHeight};
    font-weight: 400;
  }

  .DateInput_input {
    border: none !important;
    line-height: inherit;
    margin: 0;
  }

  .CalendarDay__selected_span {
    background: ${dateColor2} !important;
    border: none;
    color: ${colors.white};
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${dateColor1};
    // border: 1px double ${dateColor2};
    color: ${colors.white};
  }
  .CalendarDay__last_in_range,
  .CalendarDay__last_in_range:hover {
    border-radius: 0 3px 3px 0;
    border: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${dateColor1};
    border: none;
    color: ${colors.white};
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${dateColor3} !important;
    border: none;
    color: ${colors.black};
  }
  .CalendarDay__hovered_span:active {
    background: ${dateColor2};
    border: none;
    color: ${colors.white};
  }
`;
