import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import * as Styled from './ScrollFooter.styled';

const ScrollFooter = ({ height, children, hidden, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [pageHeight, setPageHeight] = useState();
  const isServer = typeof window === 'undefined';

  const documentElement = !isServer ? document.documentElement : null;

  // useEffect(() => {
  //   setPageHeight(documentElement.scrollHeight - documentElement.offsetHeight);
  // }, [documentElement]);

  useEffect(() => {
    if (hidden && isOpen) {
      setIsOpen(false);
    }
  }, [hidden, isOpen]);

  useScrollPosition(
    ({ currPos }) => {
      const pageHeight = documentElement.scrollHeight - documentElement.offsetHeight;
      const distanceFromBottom = pageHeight + currPos.y;
      const isVisible = currPos.y < -height && distanceFromBottom > height;
      if (hidden || isVisible === isOpen) return;
      setIsOpen(isVisible);
    },
    [isOpen]
  );

  return (
    <Styled.ScrollFooter height={height} isOpen={isOpen}>
      <Styled.CloseButton type="button" onClick={onClose}>
        <span>+</span>
      </Styled.CloseButton>
      {children}
    </Styled.ScrollFooter>
  );
};

ScrollFooter.defaultProps = {
  height: 100,
};

ScrollFooter.propTypes = {
  height: PropTypes.number,
  hidden: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default ScrollFooter;
