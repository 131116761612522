import Immutable from 'immutable';

import { getRequestKey } from './hotelTripSelector';
import { decorateRooms } from './roomDecorator';

const getHotelResponse = (state, request) => {
  const requestKey = getRequestKey(request);
  return state.hotelAvailability.get(requestKey);
};

export const getResponseTime = (response) => response && response.get('responseTime');

const getResponseReceived = (response) => response && response.get('received');

const getRequestStatus = (response) => {
  const isLoading = !response || response.get('loading');
  const isError = response && response.get('errors');
  const availableRooms = response && response.get('rooms');
  const hotelUnavailable = !availableRooms || availableRooms.size < 1;

  if (isLoading) {
    return 'LOADING';
  }
  if (isError) {
    return 'ERROR';
  }
  if (hotelUnavailable) {
    return 'NO HOTEL';
  }
  return 'HOTEL GOOD';
};

const isPublicRate = (rate) => rate.getIn(['package', 'visibility']) === 'public';
const isMemberRate = (rate) => rate.getIn(['package', 'visibility']) === 'member';

const getAvailabileRooms = (response, isHotelOnly, isMember) => {
  const received = response && response.get('received');
  const rooms =
    response && decorateRooms(response.get('rooms'), response.get('request'), received, isHotelOnly, isMember);
  // Ensure that more expensive rooms with lower pair availability don't come before best non-pair room
  return rooms && rooms.sortBy((room) => room.get('visibleLeadPrice'));
};

const getAvailableRates = (rooms) => {
  const availableRates = rooms && rooms.map((room) => room.get('rates')).flatten(true);
  // TODO: Add logic for Logged in Member Rates
  return availableRates && availableRates.filter((rate) => isPublicRate(rate) || isMemberRate(rate));
};

const getTripPricing = (leadRoom, roomCount) => {
  const visibleRoomRates = leadRoom && leadRoom.get('rates').filter((rate) => rate.get('visible'));
  const visibleLeadRate = visibleRoomRates && visibleRoomRates.first();
  const leadRoomSalePrice = visibleRoomRates && visibleRoomRates.getIn([0, 'pricing', 'totalPrice']);
  const leadRoomComparePrice = leadRoom && leadRoom.getIn(['compareRate', 'pricing', 'totalPrice']);

  const leadSalePrice = leadRoomSalePrice * roomCount;
  const leadComparePrice = leadRoomComparePrice * roomCount;

  const leadTripSavings = leadComparePrice - leadSalePrice;
  const leadTripPercentSavings = (leadTripSavings / leadComparePrice) * 100;

  const freeNights =
    visibleLeadRate &&
    visibleLeadRate.getIn(['nightlyRateBreakdown', 'USD']).filter((night) => night.get('before_tax_rate') === 0).size;

  const amenities = visibleLeadRate && visibleLeadRate.get('amenities');
  const amenitiesValue = visibleLeadRate && visibleLeadRate.get('amenitiesValue');

  return Immutable.Map({
    salePrice: leadSalePrice,
    comparePrice: leadComparePrice,
    freeNights,
    percentSavings: Number.isNaN(leadTripPercentSavings) ? null : leadTripPercentSavings,
    amenities: { value: amenitiesValue, items: amenities },
    rate: visibleLeadRate,
  });
};

const getAvailabilityRateSummary = (rates, responseTime) => {
  if (!rates || rates.size < 1) {
    return null;
  }
  const minRate = rates.minBy((rate) => rate.getIn(['pricing', 'totalPrice']));
  const maxRate = rates.maxBy((rate) => rate.getIn(['pricing', 'totalPrice']));
  return Immutable.Map({
    results_available: rates.size,
    min_total: minRate.getIn(['pricing', 'totalPrice']),
    max_total: maxRate.getIn(['pricing', 'totalPrice']),
    response_time: responseTime,
  });
};

const getHotelContent = (state, hotelCode) => {
  const hotelDetails = state.hotels.getIn(['items', hotelCode]);
  const hotelSummary = state.hotelSummaries.getIn(['items', hotelCode]);
  if (hotelDetails && hotelDetails.get('loaded')) {
    return hotelDetails;
  }
  return hotelSummary || Immutable.Map();
};

// ONLY Called from useTripSearch
export const getHotelAvailabilityResponse = (state, request, isMember = false) => {
  const hotelCode = request.get('hotelCode');
  const requestKey = getRequestKey(request);
  const response = getHotelResponse(state, request);
  const requestStatus = getRequestStatus(response);
  const isHotelOnly = request.get('hotelOnly');
  const availableRooms = getAvailabileRooms(response, isHotelOnly, isMember);
  const availableRates = getAvailableRates(availableRooms);
  const leadRoom = availableRooms && availableRooms.first();
  const hotel = getHotelContent(state, hotelCode);
  const responseTime = getResponseTime(response);
  const received = getResponseReceived(response);
  const rateSummary = getAvailabilityRateSummary(availableRates, responseTime);

  const roomCount = request.get('roomCount');
  const leadPricing = getTripPricing(leadRoom, roomCount);

  const leadRate = availableRooms && availableRooms.getIn([0, 'rates', 0]);

  const leadTrip = Immutable.Map({
    itinerary: null,
    room: leadRoom,
  }).merge(leadPricing);

  return Immutable.fromJS({
    hotelCode,
    hotel,
    availableRooms,
    leadRate,
    leadTrip,
    // availableRates,
    request,
    requestKey,
    requestStatus,
    rateSummary,
    responseTime,
    received,
  });
};

// ONLY Called from useTripSearch
export const getHotelRateByProductCode = (rates, selectedProductCode) => {
  const leadProduct = rates && rates.sortBy((p) => p.getIn(['pricing', 'totalPrice'])).first();
  const currentProduct = rates && rates.find((rate) => rate.get('productCode') === selectedProductCode);
  return currentProduct || leadProduct || Immutable.Map();
};

export const getRoomPriceSummary = (room, hotelOnly) => {
  const allRates = room && room.get('rates');
  const filteredRates = allRates && allRates.filter((rate) => rate.getIn(['package', 'visibility']) !== 'pair');
  const rates = hotelOnly ? filteredRates : allRates;
  const leadRate = rates && rates.first();

  const compareRate = room && room.get('compareRate');
  const totalPrice = leadRate && leadRate.getIn(['pricing', 'totalPrice']);
  const comparePrice = compareRate ? compareRate.getIn(['pricing', 'totalPrice']) : totalPrice;

  const savings = comparePrice - totalPrice;
  const percentSavings = (savings / comparePrice) * 100;
  return Immutable.Map({
    leadRate,
    compareRate,
    leadPrice: totalPrice,
    comparePrice,
    savings,
    percentSavings,
  });
};
