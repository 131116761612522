import styled from 'styled-components';
import { colors } from 'styles';

const Styled = styled.span`
  display: inline-block;
  .currency {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.strike {
      margin-right: 0.5em;
    }
  }
  .loading {
  }
  .currency-code {
    margin-left: 0.2em;
    font-size: 60%;
    font-weight: 400;
    font-style: normal;
    opacity: 0.6;
  }
  .currency-code-prefix {
    margin-right: 0.25rem;
  }
  .negative {
    color: ${colors.negRed};
  }
`;

export default Styled;
