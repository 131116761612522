import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import useIsMobile from 'lib/UserAgent';

import { HotelLink, CollectionLink, ArticleLink } from 'components/Links';
import Slider from '../Slider';

import * as Styled from './ItemCarousel.styled';

const LinkContent = ({ item }) => (
  <React.Fragment>
    <div>
      <em>{item.get('category')}</em>
      {item.get('heading') ? `: ${item.get('heading')}` : null}
    </div>
    <h3>{item.get('name') || item.get('title')}</h3>
  </React.Fragment>
);

LinkContent.propTypes = {
  item: PropTypes.instanceOf(Immutable.Map),
};

const CarouselItemLink = ({ item }) => {
  const links = {
    blog_article: (
      <ArticleLink article={item}>
        <LinkContent item={item} />
      </ArticleLink>
    ),
    hotel: (
      <HotelLink hotel={item}>
        <LinkContent item={item} />
      </HotelLink>
    ),
    hotel_collection: (
      <CollectionLink collection={item}>
        <LinkContent item={item} />
      </CollectionLink>
    ),
  };
  return links[item.get('content_type')];
};

CarouselItemLink.propTypes = {
  item: PropTypes.instanceOf(Immutable.Map),
};

const Carousel = ({ items, className, children }) => {
  const isMobile = useIsMobile();
  const sliderSettings = {
    dots: true,
    infinite: true,
    fade: !isMobile.any,
    easing: 'linear',
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
  };

  const baseClass = classNames('carousel-component', className);

  return (
    <Styled.ItemCarousel className={baseClass}>
      <Styled.ContentOverlay>{children}</Styled.ContentOverlay>
      <Slider {...sliderSettings}>
        {items.entrySeq().map(([key, item]) => (
          <div key={key} className="slide-wrapper">
            <div
              className="slide"
              style={{ backgroundImage: `url(${item.getIn(['images', 'full']) || item.get('image_url')})` }}
            >
              <Styled.CarouselGradient />
              <Styled.CarouselInfo className="site-container">
                <CarouselItemLink item={item} />
              </Styled.CarouselInfo>
            </div>
          </div>
        ))}
      </Slider>
    </Styled.ItemCarousel>
  );
};

Carousel.defaultProps = {
  className: undefined,
  items: undefined,
  children: null,
};

Carousel.propTypes = {
  className: PropTypes.string,
  items: PropTypes.instanceOf(Immutable.List),
  children: PropTypes.node,
};

export default Carousel;
