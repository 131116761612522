import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import * as Styled from './LoadingOverlay.styled';

const LoadingOverlay = ({ isLoading, loadingMessage, children }) => (
  <Styled.LoadingOverlay>
    {isLoading && (
      <div className="overlay">
        <Loader>{loadingMessage}</Loader>
      </div>
    )}
    <Styled.LoadingContent isLoading={isLoading}>{children}</Styled.LoadingContent>
  </Styled.LoadingOverlay>
);

LoadingOverlay.defaultProps = {
  loadingMessage: 'Loading...',
};

LoadingOverlay.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
};

export default LoadingOverlay;
