import styled from 'styled-components';
import Slider from 'react-slick';
import { colors, variables, toRgba, lighten } from 'styles';

const SlickSlider = styled(Slider)`
  /* SLICK OVERRIDES BELOW */
  position: relative;
  height: 100%;
  // border: 3px double lime;
  width: 100%;

  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
    height: 100%;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:before,
    &:after {
      display: table;
      content: '';
    }
    &:after {
      clear: both;
    }
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    position: relative;
    /* display: none; */
    float: left;

    height: 100%;
    min-height: 1px;
    & img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-loading .slick-list {
    background: ${colors.white} url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/ajax-loader.gif')
      center center no-repeat;
  }

  /* SLICK THEME BELOW */

  /* Icons */
  @font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.eot');
    src: url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.eot?#iefix')
        format('embedded-opentype'),
      url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.woff') format('woff'),
      url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.ttf') format('truetype'),
      url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.svg#slick') format('svg');
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 0;
    line-height: 0;
    font-weight: 800;

    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.85;
    color: ${colors.white};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 15px;
  }
  [dir='rtl'] .slick-prev {
    right: 15px;
    left: auto;
  }
  .slick-prev:before {
    content: '〈';
  }
  [dir='rtl'] .slick-prev:before {
    content: '〈';
  }

  .slick-next {
    right: 15px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: 15px;
  }
  .slick-next:before {
    content: '〉';
  }
  [dir='rtl'] .slick-next:before {
    content: '〉';
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    // border: 1px dashed lime;
    position: absolute;
    bottom: 10px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: inline-block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 0.75;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 9px;
    line-height: 20px;

    position: absolute;
    top: 1px;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.5;
    color: ${colors.white};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: ${colors.white};
  }
`;

export default SlickSlider;
