import React from 'react';
// import PropTypes from 'prop-types';

export const Svg = (props) =>
  <svg {...props} viewBox="0 0 29 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g id="icon-dining">
        <path d="M15,3.367 C11.894,3.367 9.368,5.894 9.368,9 C9.368,12.106 11.895,14.633 15,14.633 C18.105,14.633 20.632,12.106 20.632,9 C20.632,5.894 18.106,3.367 15,3.367 Z" id="Shape"></path>
        <path d="M15,0.173 C10.125,0.173 6.173,4.125 6.173,9 C6.173,13.875 10.125,17.827 15,17.827 C19.875,17.827 23.827,13.875 23.827,9 C23.827,4.125 19.875,0.173 15,0.173 Z M15,16.198 C11.031,16.198 7.802,12.969 7.802,9 C7.802,5.031 11.031,1.802 15,1.802 C18.969,1.802 22.198,5.031 22.198,9 C22.198,12.969 18.969,16.198 15,16.198 Z" id="Shape"></path>
        <path d="M4,3 L4,6 L3,6 L3,3 L2.5,2 L2,3 L2,6 L1,6 L1,3 L0.5,2 L0,3 L0,6 L0,7 L1,9.034 L1,15.5 C1,15.775 1.225,16 1.5,16 L3.5,16 C3.775,16 4,15.775 4,15.5 L4,9.034 L5,7 L5,6 L5,3 L4.5,2 L4,3 Z" id="Shape"></path>
        <path d="M28.376,2.106 L25.624,3.274 C25.281,3.503 25,4.028 25,4.44 L25,9.034 C25,10.081 26,10.534 26,10.534 L26,15.5 C26,15.775 26.225,16 26.5,16 L28.5,16 C28.775,16 29,15.775 29,15.5 L29,2.44 C29,2.028 28.719,1.878 28.376,2.106 Z" id="Shape"></path>
    </g>
  </svg>

export default Svg;
