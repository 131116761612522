import Immutable from 'immutable';
import Cookies from 'js-cookie';
// import moment from 'moment';
import axios from 'axios';

import { AUTH_API_ENDPOINT } from 'constants/endpoints';
import AnalyticsService, { Events } from 'components/Analytics/AnalyticsService';
// import { SESSION_TIMEOUT } from 'constants/config';

export const initialState = Immutable.fromJS({
  loading: false,
  loaded: false,
  marketing_uuid: Cookies.get('prospect_muid'),
  form_closed: Cookies.get('prospect_form_closed') || false,
});

// ACTION TYPES
const REQUEST = 'skylark/prospect/REQUEST';
const SUCCESS = 'skylark/prospect/SUCCESS';
const FAILURE = 'skylark/prospect/FAILURE';
const CLOSE_FORM = 'skylark/prospect/CLOSE_FORM';

export const closeForm = () => async dispatch => {
  dispatch({
    type: CLOSE_FORM,
  });
};

export const submitProspect = (firstName, lastName, email, browser_session_id) => async dispatch => {
  // console.log('requestHeaders', requestHeaders);
  const resourceUrl = `${AUTH_API_ENDPOINT}/prospects`;
  const requestPayload = {
    first_name: firstName,
    last_name: lastName,
    email,
    browser_session_id,
  };

  dispatch({
    type: REQUEST,
  });

  try {
    const results = await axios.post(resourceUrl, requestPayload);
    dispatch({
      type: SUCCESS,
      payload: results.data,
    });
    console.log('submitProspect > SUCCESS', results.data);
    return results.data;
  } catch (e) {
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: FAILURE,
      payload: respStatus,
    });
    console.log('submitProspect > FAIL', respStatus);
    return respStatus;
  }
};

// REDUCER HELPER METHODS
const requested = state =>
  state
    .set('loading', true)
    .set('loaded', false)
    .set('errors', null);

const received = (state, action) => {
  if (action.payload.marketing_uuid) {
    Cookies.set('prospect_muid', action.payload.marketing_uuid);
  }
  AnalyticsService.trackReducer(Events.PROSPECT_SIGNUP_SUCCESS, action.payload);
  return state
    .set('email', action.payload.email)
    .set('marketing_uuid', action.payload.marketing_uuid)
    .set('first_name', action.payload.first_name)
    .set('last_name', action.payload.last_name)
    .set('loading', false)
    .set('loaded', true)
    .set('errors', action.payload.errors);
};

const failed = (state, action) => {
  AnalyticsService.trackReducer(Events.PROSPECT_SIGNUP_FAILED, action.payload);
  return state
    .set('error', Immutable.fromJS(action.payload))
    .set('loading', false)
    .set('loaded', false)
    .set('errors', action.payload);
};

const onCloseForm = state => {
  Cookies.set('prospect_form_closed', true);
  return state.set('form_closed', true);
};

// REDUCER
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return requested(state, action);
    case SUCCESS:
      return received(state, action);
    case FAILURE:
      return failed(state, action);
    case CLOSE_FORM:
      return onCloseForm(state);
    default:
      return state;
  }
}
