import Immutable from 'immutable';
import moment from 'moment';

import {decorateRooms, getHotelRequestKey} from './roomDecorator';
import { urlDate, flatDate } from 'components/Helpers';

const decorateLeg = leg =>
  leg
    .set('segments', leg.get('flight_segments'))
    .set('displayDepartureTime', moment.parseZone(leg.departs_at).format('hh:mma'))
    .set('displayArrivalTime', moment.parseZone(leg.arrives_at).format('hh:mma'));

const decorateLegsForItinerary = itinerary =>
  itinerary &&
  itinerary.set(
    'legs',
    itinerary.get('legs').map(leg => decorateLeg(leg))
  );

export const getTripRequestKey = request => {
  const departsOn = flatDate(request.get('departsOn'));
  const returnsOn = flatDate(request.get('returnsOn'));
  const hotelCode = request.get('hotelCode');
  const originCode = request.get('originCode');
  const destinationCode = request.get('destinationCode');
  const travelerCount = request.get('travelerCount');
  const roomCount = request.get('roomCount');
  const cabinClass = request.get('cabinClass');
  const key = `${originCode}|${destinationCode}|${hotelCode}|${departsOn}|${returnsOn}|${travelerCount}|${roomCount}|${cabinClass}`;
  return key;
};

export const getRequestKey = request => {
  const isTripRequest = request.get('cabinClass');
  return isTripRequest ? getTripRequestKey(request) : getHotelRequestKey(request);
};

export const getRoomAvailabilitiesByCheckInDate = (request, response) => {
  const availabilities = response && response.get('hotels');
  const isHotelOnly = request.get('hotelOnly');
  const received = response && response.get('received');
  const datedAvailabilities =
    availabilities &&
    Immutable.Map(
      availabilities.map(availability => {
        const rooms = decorateRooms(availability.get('rooms'), availability.get('request'), received, isHotelOnly);
        return [availability.getIn(['request', 'checkIn']), availability.set('rooms', rooms)];
      })
    );
  return datedAvailabilities;
};

export const getAvailabilityForItinerary = (roomsByDate, itinerary) => {
  const checkIn = itinerary && urlDate(itinerary.get('arrives_at'));
  const availability = roomsByDate && roomsByDate.get(checkIn);
  return availability;
};

// ONLY Called from useTripSearch
export const getTripRequestStatus = (request, response) => {
  const isLoading = !response || response.get('loading');
  const checkIns = getRoomAvailabilitiesByCheckInDate(request, response);
  const roomCount = checkIns && checkIns.reduce((sum, checkIn) => sum + (checkIn && checkIn.get('rooms').size), 0);
  const isError = response && response.get('errors');
  const availablePricing = response && response.get('pricing');
  const airUnavailable = availablePricing && availablePricing === 'No Air Itineraries available';
  const hotelUnavailable = !roomCount || roomCount < 1;

  if (isLoading) {
    return 'LOADING';
  }
  if (isError) {
    return 'ERROR';
  }
  if (hotelUnavailable) {
    return 'NO HOTEL';
  }
  if (airUnavailable) {
    return 'NO AIR';
  }
  return 'ALL GOOD';
};

// ONLY Called from useTripSearch
export const getLeadItineraries = (state, response) => {
  const selectedAir = state.tripSelection && state.tripSelection.get('itinerary');
  const airSummary = response && response.get('air_summary');
  const currentRequestKey = airSummary && airSummary.getIn(['best_itinerary', 'request_key']);

  const itineraries = Immutable.Map({
    bestItinerary: airSummary ? airSummary.get('best_itinerary') : null,
    cheapestItinerary: airSummary ? airSummary.get('cheapest_itinerary') : null,
    fastestItinerary: airSummary ? airSummary.get('fastest_itinerary') : null,
    skyteamItinerary: airSummary ? airSummary.get('cheapest_sky_team_alliance') : null,
    oneworldItinerary: airSummary ? airSummary.get('cheapest_one_world_alliance') : null,
    starItinerary: airSummary ? airSummary.get('cheapest_star_alliance') : null,
    selectedItinerary: selectedAir,
  }).filter(v => v && v.get('request_key') === currentRequestKey);

  const processedItineraries =
    itineraries &&
    itineraries
      .groupBy(itin => (itin ? itin.get('key') : null))
      .map(itin => itin.first())
      .filter(itin => itin && itin.get('adjusted_fare') > 0)
      .sort((a, b) => a.get('adjusted_fare') - b.get('adjusted_fare'));

  return processedItineraries;
};

export const getLeadItinerary = response => {
  const leadItineraries = response && response.get('air_summary');
  const itinerary = leadItineraries && decorateLegsForItinerary(leadItineraries.get('best_itinerary'));
  return itinerary;
};

// ONLY Called from useTripSearch
export const getSelectedItinerary = (state, response) => {
  const selectedItinerary = decorateLegsForItinerary(state.tripSelection && state.tripSelection.get('itinerary'));
  const leadItinerary = getLeadItinerary(response);
  const itinerary = selectedItinerary || leadItinerary;
  return itinerary;
};
