import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import track, { useTracking } from 'react-tracking';

import HeroImage from 'components/UI/HeroImage';
import Events from 'constants/events';
import * as Styled from './ContentTile.styled';

export const ContentTile = ({ name, summary, imageUrl, itemHeading, linkText, contentType, position, className }) => {
  const tracking = useTracking();

  const onClick = () => {
    tracking.trackEvent({
      event: Events.CONTENT_TILE_CLICKED,
      action: 'click',
      contentType,
      targetName: name,
      targetPosition: position,
    });
  };

  const baseClass = classNames('content-tile-component', className);

  return (
    <Styled.Container className={baseClass} onClick={onClick}>
      <div className="tile-heading">{itemHeading}</div>
      <div className="tile-body">
        <HeroImage imageUrl={imageUrl}>
          <h2>{name}</h2>
        </HeroImage>
        <div className="tile-footer">
          <p>
            {summary} <span className="more-link">{linkText}</span>
          </p>
        </div>
      </div>
    </Styled.Container>
  );
};

ContentTile.defaultProps = {
  linkText: 'more',
};

ContentTile.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string,
  imageUrl: PropTypes.string,
  itemHeading: PropTypes.node,
  linkText: PropTypes.string,
  contentType: PropTypes.string,
  position: PropTypes.number,
  className: PropTypes.string,
};

export default track(() => ({ components: ['ContentTile'] }))(ContentTile);
