import { useState, useEffect } from 'react';
import Immutable from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, updateUserProfile, changePassword } from 'modules/user';
import { getAuthToken } from 'constants/config';
// import { whoIsMyDaddy } from 'lib/devTools';
import { useAuth } from 'hooks';

const useProfile = () => {
  const dispatch = useDispatch();

  const [isReady, setIsReady] = useState(false);

  const { isLoggedIn, isConfirmed } = useAuth();

  const user = useSelector(state => state.user || Immutable.Map());
  const authToken = getAuthToken();
  const isLoading = !user || user.get('loading');
  const isLoaded = !!user?.get('loaded');
  const isActivated = !!user?.get('activated');

  // const daddy = whoIsMyDaddy();
  // console.log('useProfile', daddy, { user: user.toJS() });

  // console.log('useProfile', { isLoggedIn, isConfirmed });

  useEffect(() => {
    setIsReady((isLoggedIn || !isConfirmed) && !!authToken);
  }, [isLoggedIn, isConfirmed, authToken]);

  useEffect(() => {
    if (isReady) {
      dispatch(getUserProfile());
    }
  }, [isReady]);

  return {
    user,
    updateProfile: values => dispatch(updateUserProfile(values)),
    changePassword: password => dispatch(changePassword({ password })),
    isLoading,
    isLoaded,
    isActivated,
  };
};

export default useProfile;
