import Immutable from 'immutable';
import axios from 'axios';

import Events from 'constants/events';

import { SEARCH_API_ENDPOINT } from '../constants/endpoints';
import { MINIMUM_SEARCH_CHARS } from '../constants/config';

export const multiSearchPromise = query => {
  if (query.length < MINIMUM_SEARCH_CHARS) {
    return false;
  }
  return axios
    .get(`${SEARCH_API_ENDPOINT}/search?query=${query}`, {
      query,
    })
    .then(
      response => {
        if (response?.data.length === 0) {
          window.analytics.track(Events.DESTINATION_SEARCH_NO_RESULTS, { query });
        }
        const resultsMap = Immutable.fromJS(response.data).filter(
          r => !(r.get('type') === 'airport' && r.get('hotel_count') < 1)
        );
        return resultsMap.toJS().map(r => Object.assign(r, { label: r.name, value: r.name }));
      },
      error => {
        console.log(error);
      }
    );
};
